import axios from "axios";
import { api } from "./api";

export const fileUpload = async (file: File, folder: string) => {
  const uploadPayload = {
    file_name: file.name,
    folder,
    content_type: file.type,
  };
  const { uploadURL = "" } = await api.getImageUpload(uploadPayload);

  const response = await axios.put(uploadURL, file, {
    headers: { "Content-Type": file.type },
  });
  return { response, url: uploadURL.split("?")[0] };
};
