import React from "react";
import { ReportedContentModal } from "./ReportedContentModal";
import moment from "moment";

export function ReportedByView({ list, onReportedContent }) {
  return (
    <div className="flex flex-col gap-5 p-4 bg-white rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)] overflow-auto max-h-[250px]">
      {list.map((item, index) => (
        <div className="gap-2 flex" key={item.user_id}>
          <img
            className="w-9 h-9 rounded-full"
            src={item.user_image}
            alt="user"
          />
          <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="text-[#202020] text-sm">
                {item.first_name} {item.last_name}
              </div>
              <div className="text-[#9A9A9A] text-xs">
                {moment.utc(item.reported_on).local().fromNow()}
              </div>
            </div>
            <div>
              <span className="text-[#7A7A7A] text-xs">Reason:</span>
              <span className="text-[#202020] text-xs"> {item.reason}</span>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onReportedContent(item);
              }}
              className="text-[#00C88C] text-xs"
            >
              View reported content
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
