import { FileUploader } from "react-drag-drop-files";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";

const fileTypes = ["JPG", "PNG", "JPEG"];

export function UploadPicture({
  onChange,
  image,
}: {
  onChange: (file: any) => void;
  image?: string;
}) {
  return (
    <div>
      <FileUploader handleChange={onChange} name="file" types={fileTypes}>
        <div className="relative cursor-pointer flex items-center justify-center rounded-full border border-dashed border-[#00C88C] w-[100px] h-[100px] min-w-[100px] min-h-[100px]">
          {image ? (
            <>
              <img
                src={image}
                alt=""
                className="w-full h-full object-cover rounded-full"
              />
              <div className="absolute top-0 bottom-0 left-0 right-0 rounded-full bg-[rgba(0,0,0,0.12)] flex items-center justify-center">
                <UploadIcon className="[&_path]:stroke-[#00C88C] w-6 h-6" />
              </div>
            </>
          ) : (
            <UploadIcon className="[&_path]:stroke-[#00C88C] w-6 h-6" />
          )}
        </div>
      </FileUploader>
    </div>
  );
}
