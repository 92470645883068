import { useSearchParams } from "react-router-dom";
import UIPopover from "../../../components/Popover/Popover";
import moment from "moment";
import { Button } from "../../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as More } from "../../../assets/icons/More.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";
import { ReactComponent as Download } from "../../../assets/icons/download.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/Chevrons.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as BanIcon } from "../../../assets/icons/slash.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import useUsers from "./useUsers";
import { UserView } from "../View/View";
import { UserDeleteConfirm } from "../components/UserDeleteConfirm";
import { api } from "../../../services/api";
import { UserBlockConfirm } from "../components/UserBlockConfirm";
import { ReportedUserList } from "./ReportedUsers";
import { BlockedUserList } from "./BlockedUser";
import { LoadAnimatedContainer } from "../../../components/AnimatedContainer";
import { EmptyTableView } from "../../../components/EmptyTableView";
import { ShowToast } from "../../../components/Toast";
import { UserIdWithTooltip } from "../components/UserIdWithTooltip";
import { CrownTooltip, PromoTooltip } from "../components/CrownTooltip";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { UserListFilters } from "../components/UserListFilters";
import React from "react";
import { Loading } from "../../../components/Loading/Loading";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                  <div className="w-4 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
                <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="mrr">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-32 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function UserList() {
  const [params] = useSearchParams();
  const plusUser = params.get("plusUser");
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    users,
    total,
    loadMore,
  } = useUsers({ type: "normal", isPlusUser: !!plusUser });
  const [reportedUserModal, setReportedUserModal] = useState<boolean>(false);
  const [blockedUserModal, setBlockedUserModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const [deleting, setDeleting] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState<any>(null);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [user, setUser] = useState<any>(null);

  const [custom, setCustom] = React.useState({
    date_joined_start: "",
    date_joined_end: "",
  });
  const [customFilters, setCustomFilters] = React.useState<any>({
    ...filters.filter,
  });

  const handleCustomChange = (name, value) => {
    setCustom({
      ...custom,
      [name]: value,
    });
  };

  const handleClear = () => {
    setCustomFilters({
      reveal_sent_count: 0,
      reveal_sent_count_custom: false,
      people_sent_to: 0,
      people_sent_to_custom: false,
      people_received_from: 0,
      people_received_from_custom: false,
      plan_type: null,
      plan_type_custom: false,
    });
    setCustom({
      date_joined_start: "",
      date_joined_end: "",
    });
    // updateFilters({
    //   filter: {
    //     reveal_sent_count: null,
    //     people_sent_to: null,
    //     people_received_from: null,
    //     date_joined_start: null,
    //     date_joined_end: null,
    //     plan_type: null,
    //   },
    // });
    // setOpen(false);
  };

  const handleClearAll = () => {
    setCustomFilters({
      reveal_sent_count: 0,
      reveal_sent_count_custom: false,
      people_sent_to: 0,
      people_sent_to_custom: false,
      people_received_from: 0,
      people_received_from_custom: false,
      plan_type: null,
      plan_type_custom: false,
    });
    setCustom({
      date_joined_start: "",
      date_joined_end: "",
    });
    updateFilters({
      filter: {
        reveal_sent_count: null,
        people_sent_to: null,
        people_received_from: null,
        date_joined_start: null,
        date_joined_end: null,
        plan_type: null,
      },
    });
    setOpen(false);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    console.log("name", name, value);
    const isCustomField = name.includes("_custom");
    setCustomFilters({
      ...customFilters,
      [name]: value,
    });
    // const isCustomSelected = customFilters[name];
    // if (isCustomField) {
    //   setCustomFilters({
    //     ...customFilters,
    //     [name.split("_custom")[0]]: value,
    //   });
    // } else {
    //   setCustomFilters({
    //     ...customFilters,
    //     [name]: value,
    //     [`${name}_custom`]: ["custom-min", "custom-exact"].includes(value)
    //       ? value
    //       : false,
    //   });
    // }
  };

  const handleApply = () => {
    updateFilters({
      filter: {
        ...customFilters,
        date_joined_start: custom.date_joined_start
          ? moment(custom.date_joined_start).format("YYYY-MM-DD")
          : custom.date_joined_start,
        date_joined_end: custom.date_joined_end
          ? moment(custom.date_joined_end).format("YYYY-MM-DD")
          : custom.date_joined_end,
      },
    });
    setOpen(false);
  };

  useEffect(() => {
    setCustomFilters(filters.filter);
  }, [filters.filter]);

  const handleSort = (key) => {
    if (isLoading) return;
    if (key === filters.sort_by) {
      updateFilters({
        sort_by: filters.sort_order === "DESC" ? "" : key,
        sort_order:
          filters.sort_order === "ASC"
            ? "DESC"
            : filters.sort_order === "DESC"
            ? ""
            : "ASC",
      });
    } else {
      updateFilters({ sort_order: "ASC", sort_by: key });
    }
  };
  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleDeactivateUserConfirm = async () => {
    try {
      // api call
      const user = deactivateUser;
      setBlocking(true);
      const response = await api.blockUser({ user_id: user.user_id });
      setBlocking(false);
      if (response.status === 200) {
        if (response?.data?.status) {
          refetch(user.user_id, true);
          setDeactivateUser(null);
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      const response = await api.downloadUsers({
        ...filters,
      });
      setIsDownloading(false);

      if (response.status === 200) {
        // Try to find out the filename from the content disposition `filename` value
        var disposition = response.headers["content-disposition"];
        var matches = /"([^"]*)"/.exec(disposition);
        var filename =
          matches != null && matches[1]
            ? matches[1]
            : `user-${new Date().toISOString()}.txt`;

        // The actual download
        var blob = new Blob([response.data], { type: "text/plain" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      }
    } catch (e) {
      setIsDownloading(false);
    }
  };

  const handleRemoveUserConfirm = async () => {
    try {
      // api call
      const user = removeUserModal;
      setDeleting(true);
      const response = await api.deleteUser({ user_id: user.user_id });
      setDeleting(false);
      if (response.status === 200) {
        if (response.data?.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(user.user_id, true);
          setRemoveUserModal(null);
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  useEffect(() => {
    if (plusUser) {
      setCustomFilters({
        ...customFilters,
        plan_type: "plus",
      });
      updateFilters({
        filter: {
          ...customFilters,
          plan_type: "plus",
        },
      });
    }
  }, [plusUser]);

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          users &&
          users.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, users, total, isLoading]);

  useEffect(() => {
    if (user) {
      const newUser = users?.find(
        (category) => category.user_id === user.user_id
      );
      if (newUser) {
        setUser(newUser);
      }
    }
  }, [users, user]);

  const hasAdvancedFilters = Object.values(filters.filter).some((v) => v);

  return (
    <div className="p-7 pt-6 bg-background h-[100vh]">
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-black text-[32px] font-bold">Manage users</h1>
        <div className="flex items-center gap-3">
          <Button
            variant="link"
            scaleAnimated={false}
            className="!p-0 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black"
            onClick={() => setReportedUserModal(true)}
          >
            Reported users{" "}
            <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
          </Button>
          <div className="h-[13px] w-[1px] bg-[#ddd]" />
          <Button
            variant="link"
            scaleAnimated={false}
            className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black"
            onClick={() => setBlockedUserModal(true)}
          >
            Blocked users{" "}
            <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
          </Button>
        </div>
      </div>
      <LoadAnimatedContainer>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5 h-[calc(100vh-115px)]">
          <div className="flex items-center justify-between flex-wrap gap-6 pl-2">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
              </div>
              <button
                className="gap-1.5 relative flex items-center border rounded-lg !border-[#C7D8C4] py-[9px] text-[#33363F] px-[14px] text-lg"
                onClick={() => setOpen(true)}
              >
                {hasAdvancedFilters ? (
                  <div className="bg-[#00C88C] border-[2px] border-white rounded-full w-3 h-3 absolute -top-1 -right-1" />
                ) : null}
                <FilterIcon className="w-6 h-6" />
                Filter
              </button>
              {hasAdvancedFilters && (
                <div className="flex items-center gap-2">
                  {!isLoading && (
                    <span className="text-black text-sm leading-[16.8px]">
                      {total} result{total > 1 ? "s" : ""} found
                    </span>
                  )}
                  <Button
                    variant="link"
                    className="!text-[#05BA84] hover:text-[#05BA84]/80 !text-base !font-medium !pl-0"
                    onClick={handleClearAll}
                  >
                    Clear all filters
                  </Button>
                </div>
              )}
              {/* <div className="border border-[#D9D9E7] rounded-md py-3.5 px-4 text-sm flex gap-2 items-center">
                Plus users only
                <div className="inline-flex items-center">
                  <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                    <input
                      id="switch-component"
                      type="checkbox"
                      onChange={(e) => {
                        updateFilters({ is_plus_user: e.target.checked });
                      }}
                      className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-[#D9F2D7] checked:bg-[#00C88C]"
                      defaultChecked={filters.is_plus_user}
                    />
                    <label
                      htmlFor="switch-component"
                      className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity before:shadow-[10px_14px_40px_0px_rgba(0,71,28,0.11)]  hover:before:opacity-0 peer-checked:translate-x-full peer-checked:before:bg-gray-900"
                    >
                      <div
                        className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                        data-ripple-dark="true"
                      ></div>
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
            <div>
              <button
                className="gap-1.5 relative flex items-center  text-black hover:text-black/80 text-sm"
                onClick={handleDownload}
              >
                <Download className="w-6 h-6" />
                Download
                {isDownloading && <Loading />}
              </button>
            </div>
          </div>
          <div className="mt-4 text-[14px] max-h-[calc(100vh-210px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "28%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "14%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td
                    onClick={() => handleSort("name")}
                    className="rounded-l pl-5"
                  >
                    <div className="cursor-pointer flex items-center">
                      Name
                      {filters.sort_by === "name" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">Phone number</div>
                  </td>
                  <td onClick={() => handleSort("joined_on")}>
                    <div className="flex items-center cursor-pointer">
                      Joined on
                      {filters.sort_by === "joined_on" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td onClick={() => handleSort("reveals_sent")}>
                    <div className="flex items-center cursor-pointer">
                      Reveals sent
                      {filters.sort_by === "reveals_sent" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td onClick={() => handleSort("reveals_received")}>
                    <div className="flex items-center cursor-pointer">
                      Reveals received
                      {filters.sort_by === "reveals_received" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => handleSort("last_activity")}
                      className="flex items-center cursor-pointer"
                    >
                      Last activity
                      {filters.sort_by === "last_activity" ? (
                        <ArrowRight
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !users?.length && (
                  <EmptyTableView
                    cols={7}
                    message="No users found"
                    className="h-[calc(100vh-225px)]"
                  />
                )}
                {users?.map((aclient, i) => (
                  <tr
                    key={aclient.user_id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setUser(aclient)}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-3">
                        <img
                          src={aclient.user_image}
                          className="w-10 h-10 rounded-full object-cover"
                          alt="user"
                        />
                        <div className="flex flex-col">
                          <div className="flex items-center gap-1">
                            <h5>
                              {aclient.first_name} {aclient.last_name}
                            </h5>
                            {!!aclient.is_plus_user && (
                              <>
                                {aclient?.is_promo_code_used ? (
                                  <PromoTooltip />
                                ) : (
                                  <CrownTooltip />
                                )}
                              </>
                            )}
                          </div>
                          <UserIdWithTooltip userId={aclient.user_id} />
                        </div>
                      </div>
                    </td>
                    <td className="subscription">
                      {/* {!!isInactive && aclient.total_past_subscription} */}
                      {/* {!!isTrial && aclient.total_trial_subscription} */}
                      {/* {!!isActive && aclient.total_active_subscription} */}
                      {aclient.u_phone_number}
                    </td>
                    <td className="account-manager">
                      {moment
                        .utc(aclient.date_created)
                        .local()
                        .format("MMM DD,YYYY")}
                    </td>
                    <td className="account-executive">
                      <span>{aclient.sent_count}</span>
                    </td>
                    <td className="mrr">
                      <span>{aclient.received_count}</span>
                    </td>
                    <td>
                      {aclient?.last_activity_date
                        ? moment
                            .utc(aclient.last_activity_date)
                            .local()
                            .format("MMM DD,YYYY | hh:mm A")
                        : "-"}
                    </td>
                    <td onClick={stopPropagate}>
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                      >
                        {(close) => (
                          <Dropdown
                            clientStatus={aclient.status}
                            clientId={aclient.user_id}
                            removeUser={() => {
                              close();
                              setRemoveUserModal(aclient);
                            }}
                            onDeactiveUser={() => {
                              close();
                              setDeactivateUser(aclient);
                            }}
                            onActivateUser={() => console.log("mark as active")}
                            onView={() => {
                              close();
                              setUser(aclient);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {(isLoading || isFetching) && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      {open && (
        <UserListFilters
          filter={filters.filter}
          onClose={() => {
            setCustomFilters(filters.filter);
            setCustom({
              date_joined_start: filters?.filter?.date_joined_start || "",
              date_joined_end: filters?.filter?.date_joined_end || "",
            });
            setOpen(false);
          }}
          handleChange={handleChange}
          handleCustomChange={handleCustomChange}
          onApply={handleApply}
          onClear={handleClear}
          custom={custom}
          customFilters={customFilters}
        />
      )}
      <UserView
        open={!!user}
        onClose={() => setUser(null)}
        user={user}
        refetch={refetch}
      />
      <UserDeleteConfirm
        title={`Are you sure you want to remove ‘${removeUserModal?.first_name} ${removeUserModal?.last_name}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      <UserBlockConfirm
        title={`Are you sure you want to block ‘${deactivateUser?.first_name} ${deactivateUser?.last_name}’?`}
        open={!!deactivateUser}
        onClose={() => setDeactivateUser(null)}
        onConfirm={handleDeactivateUserConfirm}
        loading={blocking}
      />
      {reportedUserModal && (
        <ReportedUserList
          open={reportedUserModal}
          onClose={() => setReportedUserModal(false)}
        />
      )}
      {blockedUserModal && (
        <BlockedUserList
          open={blockedUserModal}
          onClose={() => setBlockedUserModal(false)}
          refresh={refetch}
        />
      )}
    </div>
  );
}

const Dropdown = ({
  clientId,
  onActivateUser,
  onDeactiveUser,
  removeUser,
  clientStatus,
  onView,
}) => {
  return (
    <div className="z-10 w-40 bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onView}
            scaleAnimated={false}
          >
            <Eye />
            View profile
          </Button>
        </li>
        {clientStatus !== "inactive" ? (
          <li>
            <Button
              variant="icon"
              className="block py-2 !px-4 hover:bg-gray-100 w-full text-left !justify-start"
              onClick={onDeactiveUser}
              scaleAnimated={false}
            >
              <BanIcon />
              Block user
            </Button>
          </li>
        ) : (
          <li>
            <Button
              variant="icon"
              className="block py-2 !px-4 hover:bg-gray-100 w-full text-left !justify-start"
              onClick={onActivateUser}
              scaleAnimated={false}
            >
              <BanIcon />
              Unblock user
            </Button>
          </li>
        )}
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-gray-100 text-[#F54343] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            <TrashIcon />
            Remove user
          </Button>
        </li>
      </ul>
    </div>
  );
};
