import { Routes, Route, Navigate } from "react-router-dom";
import { Main } from "./main";
import { Login } from "./auth";
import { Dashboard } from "./dashboard/Dashboard";
import { UserList } from "./users/List/List";
import { PlusPacks } from "./plus-packs/PlusPacks";
import { EmptyStates } from "./empty-states/EmptyStates";
import { Amplitude } from "./amplitude/Amplitude";
import SuspenseWrapper from "../components/SuspenseWrapper";
import { PaywallSettings } from "./paywall/PaywallSettings";
import { QuestionsAndCategories } from "./questions/QuestionAndCategories";
import { Reports } from "./reports/Reports";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Main />}>
        <Route
          path="/dashboard"
          element={
            <SuspenseWrapper>
              <Dashboard />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/users"
          element={
            <SuspenseWrapper>
              <UserList />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/questions"
          element={
            <SuspenseWrapper>
              <QuestionsAndCategories />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/empty-states"
          element={
            <SuspenseWrapper>
              <EmptyStates />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/amplitude"
          element={
            <SuspenseWrapper>
              <Amplitude />
            </SuspenseWrapper>
          }
        />
        <Route
          path="paywall-settings"
          element={
            <SuspenseWrapper>
              <PaywallSettings />
            </SuspenseWrapper>
          }
        />
        <Route
          path="reports"
          element={
            <SuspenseWrapper>
              <Reports />
            </SuspenseWrapper>
          }
        />
        <Route
          index
          element={
            // if loggged in redirect to dashboard
            <Navigate to="/login" />
          }
        />
      </Route>
    </Routes>
  );
};
export default RoutesList;
