import { Button } from "../../../components/Button";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import React, { useEffect } from "react";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import moment from "moment";
import { api } from "../../../services/api";
import { Modal } from "../../../components/Modals/Modal";

export function QuestionFilter({
  onChange,
  selectedFilters,
}: {
  onChange: any;
  selectedFilters: any;
}) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState();
  const [selectedCategory, setSelectedCategory] = React.useState<any[]>([]);
  const [custom, setCustom] = React.useState({
    start_date: "",
    end_date: "",
  });
  const [categories, setCategories] = React.useState<any[]>([]);

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const handleCustomChange = (name, value) => {
    setCustom({
      ...custom,
      [name]: value,
    });
  };

  const handleCategoryChange = (category) => {
    //selectedCategory is category object array
    //if category is not in selectedCategory then add it
    //else remove it
    const index = selectedCategory.findIndex(
      (c) => c.question_category_id === category.question_category_id
    );
    if (index === -1) {
      setSelectedCategory([...selectedCategory, category]);
    } else {
      setSelectedCategory(
        selectedCategory.filter(
          (c) => c.question_category_id !== category.question_category_id
        )
      );
    }
  };

  const handleApply = () => {
    // create dates with start_date & end_date
    // using selected 7, 15, 30 days
    // or custom
    // send to onChange
    const customDates = {
      start_date: custom.start_date,
      end_date: custom.end_date,
    };

    if (selected === "week") {
      // use moment to create dates
      const start_date = moment().subtract(7, "days").format("YYYY-MM-DD");
      const end_date = moment().format("YYYY-MM-DD");
      customDates.start_date = start_date;
      customDates.end_date = end_date;
    } else if (selected === "half-month") {
      const start_date = moment().subtract(15, "days").format("YYYY-MM-DD");
      const end_date = moment().format("YYYY-MM-DD");
      customDates.start_date = start_date;
      customDates.end_date = end_date;
    } else if (selected === "month") {
      const start_date = moment().subtract(30, "days").format("YYYY-MM-DD");
      const end_date = moment().format("YYYY-MM-DD");
      customDates.start_date = start_date;
      customDates.end_date = end_date;
    } else if (selected === "custom") {
      // use custom dates
      const start_date = moment(custom.start_date).format("YYYY-MM-DD");
      const end_date = moment(custom.end_date).format("YYYY-MM-DD");
      if (start_date === "Invalid date" || end_date === "Invalid date") return;
      customDates.start_date = start_date;
      customDates.end_date = end_date;
    }
    onChange(selected, customDates, selectedCategory);
    setOpen(false);
  };

  React.useEffect(() => {
    setSelected(selectedFilters.timeType);
    if (selectedFilters.timeType === "custom") {
      setCustom({
        start_date: selectedFilters.start_date,
        end_date: selectedFilters.end_date,
      });
    } else {
      setCustom({
        start_date: "",
        end_date: "",
      });
    }
    setSelectedCategory(selectedFilters.categories);
  }, [selectedFilters]);

  useEffect(() => {
    api.getCategories({ page: 1, limit: 10000 }).then((res) => {
      setCategories(res.data);
    });
  }, []);

  return (
    <div>
      <button
        className="gap-1.5 flex items-center border rounded-lg !border-[#C7D8C4] py-[9px] text-[#33363F] px-[14px] text-lg"
        onClick={() => setOpen(true)}
      >
        <FilterIcon className="w-6 h-6" />
        Filter
      </button>
      {open && (
        <Modal
          contentClassName="[&_h1]:!text-lg"
          open={open}
          onClose={() => {
            setCustom({
              start_date: selectedFilters.start_date || "",
              end_date: selectedFilters.end_date || "",
            });
            setSelected(selectedFilters.timeType);
            setOpen(false);
          }}
          title="Filters"
        >
          <Dropdown
            onChange={handleChange}
            onApply={handleApply}
            onCustomChange={handleCustomChange}
            selected={selected}
            custom={custom}
            position="bottom"
            onCategoryChange={handleCategoryChange}
            selectedCategory={selectedCategory}
            categories={categories}
            selectedFilters={selectedFilters}
          />
        </Modal>
      )}
    </div>
  );
}

const Dropdown = ({
  onChange,
  onApply,
  onCustomChange,
  selected,
  custom,
  position,
  onCategoryChange,
  selectedCategory,
  categories,
  selectedFilters,
}) => {
  return (
    <div className="h-[calc(100vh-200px)] overflow-auto">
      <div>
        <span className="text-sm">Filter by categories:</span>
        <div className="h-[250px] mt-3 border border-[#D9D9E7]/80 p-[18px] flex flex-col gap-[14px] rounded-md overflow-auto">
          {categories.map((category) => (
            <label
              key={category.id}
              className="flex items-center gap-2 cursor-pointer"
            >
              <input
                type="checkbox"
                name="categories"
                value={category.question_category_id}
                className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
                onChange={() => onCategoryChange(category)}
                checked={selectedCategory.some(
                  (c) =>
                    c.question_category_id === category.question_category_id
                )}
              />
              <span>{category.category_name}</span>
            </label>
          ))}
        </div>
      </div>
      {/**
       * radio group with 7, 15, 30 days & custom & aligned with selected state contorlled by setSelected onChange & checked by selected === "custom"
       */}
      <div className="mt-7 mb-3">
        <span className="text-sm">Filter by time:</span>
      </div>
      <div className="flex flex-col gap-5 text-base text-[#1D1E1B]">
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="time"
            value="week"
            className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
            onChange={onChange}
            checked={selected === "week"}
          />
          <span>7 days</span>
        </label>
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="time"
            value="half-month"
            className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
            onChange={onChange}
            checked={selected === "half-month"}
          />
          <span>15 days</span>
        </label>
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="time"
            value="month"
            className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
            onChange={onChange}
            checked={selected === "month"}
          />
          <span>30 days</span>
        </label>
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="time"
            value="custom"
            className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E] "
            onChange={onChange}
            checked={selected === "custom"}
          />
          <span>Custom</span>
        </label>
      </div>
      {/**
       * if custom is selected then show date picker*/}
      {selected === "custom" && (
        <div className="flex items-center gap-2 mt-5">
          <label className="flex items-center gap-2 flex-1 [&_.date-picker]:flex-1 [&_.react-datepicker-wrapper]:w-full">
            <DatePicker
              onChange={(value) => onCustomChange("start_date", value)}
              value={custom.start_date}
              maxDate={custom.end_date || undefined}
            />
          </label>
          <span className="text-[#8D8E92] text-base">to</span>
          <label className="flex items-center gap-2 flex-1 [&_.date-picker]:flex-1 [&_.react-datepicker-wrapper]:w-full">
            <DatePicker
              onChange={(value) => onCustomChange("end_date", value)}
              value={custom.end_date}
              minDate={custom.start_date || undefined}
            />
          </label>
        </div>
      )}
      {/**
       * apply button*/}
      <div className="flex justify-end mt-4">
        <Button
          variant="primary"
          className="!rounded-full"
          onClick={() => onApply()}
          disabled={
            (!selected ||
              (selected === "custom" &&
                (!custom.start_date || !custom.end_date))) &&
            (selectedFilters.categories.length === 0
              ? selectedCategory.length === 0
              : false)
          }
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
