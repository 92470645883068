import { Button } from "../../../components/Button";
import UIPopover from "../../../components/Popover/Popover";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import React from "react";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import moment from "moment";

const positionMap = {
  bottom: "mt-3",
  top: "mb-3",
  right: "ml-3",
  left: "mr-3",
};

export function QuestionFilter({
  onChange,
  selectedFilters,
}: {
  onChange: any;
  selectedFilters: any;
}) {
  const [selected, setSelected] = React.useState();
  const [custom, setCustom] = React.useState({
    start_date: "",
    end_date: "",
  });

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const handleCustomChange = (name, value) => {
    setCustom({
      ...custom,
      [name]: value,
    });
  };

  const handleApply = (close: any) => () => {
    // create dates with start_date & end_date
    // using selected 7, 15, 30 days
    // or custom
    // send to onChange
    const customDates = {
      start_date: custom.start_date,
      end_date: custom.end_date,
    };

    if (selected === "week") {
      // use moment to create dates
      const start_date = moment().subtract(7, "days").format("YYYY-MM-DD");
      const end_date = moment().format("YYYY-MM-DD");
      customDates.start_date = start_date;
      customDates.end_date = end_date;
    } else if (selected === "half-month") {
      const start_date = moment().subtract(15, "days").format("YYYY-MM-DD");
      const end_date = moment().format("YYYY-MM-DD");
      customDates.start_date = start_date;
      customDates.end_date = end_date;
    } else if (selected === "month") {
      const start_date = moment().subtract(30, "days").format("YYYY-MM-DD");
      const end_date = moment().format("YYYY-MM-DD");
      customDates.start_date = start_date;
      customDates.end_date = end_date;
    } else if (selected === "custom") {
      // use custom dates
      const start_date = moment(custom.start_date).format("YYYY-MM-DD");
      const end_date = moment(custom.end_date).format("YYYY-MM-DD");
      customDates.start_date = start_date;
      customDates.end_date = end_date;
    }

    onChange(selected, customDates);
    if (close) {
      close();
    }
  };

  React.useEffect(() => {
    setSelected(selectedFilters.timeType);
    if (selectedFilters.timeType === "custom") {
      setCustom({
        start_date: selectedFilters.start_date,
        end_date: selectedFilters.end_date,
      });
    }
  }, [selectedFilters]);

  return (
    <div>
      <UIPopover
        popoverButtonClassName="!bg-none hover:[&_div]:!text-[#00C88C]"
        // positions={["bottom", "top", "right", "left"]}
        trigger={
          <div className="gap-1.5 flex items-center border rounded-lg !border-[#C7D8C4] py-3.5 text-[#33363F] px-[14px] text-lg">
            <FilterIcon className="w-6 h-6" />
            Filters by time
          </div>
        }
      >
        {(close, position) => (
          <Dropdown
            onChange={handleChange}
            onApply={handleApply(close)}
            selected={selected}
            onCustomChange={handleCustomChange}
            custom={custom}
            position={position}
          />
        )}
      </UIPopover>
    </div>
  );
}

const Dropdown = ({
  onChange,
  onApply,
  onCustomChange,
  selected,
  custom,
  position,
}) => {
  return (
    <div
      className={`z-10 w-[475px] bg-white shadow-[0_4px_24px_rgba(0,0,0,0.15)] rounded-xl py-5 px-6 ${positionMap[position]}`}
    >
      {/**
       * radio group with 7, 15, 30 days & custom & aligned with selected state contorlled by setSelected onChange & checked by selected === "custom"
       */}
      <div className="flex flex-col gap-5 text-base text-[#1D1E1B]">
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="time"
            value="week"
            className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
            onChange={onChange}
            checked={selected === "week"}
          />
          <span>7 days</span>
        </label>
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="time"
            value="half-month"
            className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
            onChange={onChange}
            checked={selected === "half-month"}
          />
          <span>15 days</span>
        </label>
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="time"
            value="month"
            className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
            onChange={onChange}
            checked={selected === "month"}
          />
          <span>30 days</span>
        </label>
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="time"
            value="custom"
            className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E] "
            onChange={onChange}
            checked={selected === "custom"}
          />
          <span>Custom</span>
        </label>
      </div>
      {/**
       * if custom is selected then show date picker*/}
      {selected === "custom" && (
        <div className="flex items-center gap-2 mt-5">
          <label className="flex items-center gap-2 flex-1 [&_.date-picker]:flex-1">
            <DatePicker
              onChange={(value) => onCustomChange("start_date", value)}
              value={custom.start_date}
            />
          </label>
          <span className="text-[#8D8E92] text-base">to</span>
          <label className="flex items-center gap-2 flex-1 [&_.date-picker]:flex-1">
            <DatePicker
              onChange={(value) => onCustomChange("end_date", value)}
              value={custom.end_date}
            />
          </label>
        </div>
      )}
      {/**
       * apply button*/}
      <div className="flex mt-4">
        <Button
          variant="primary"
          className="flex-1 !rounded-full"
          onClick={onApply}
          disabled={
            !selected ||
            (selected === "custom" && !custom.start_date && !custom.end_date)
          }
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
