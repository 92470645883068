import React, { useEffect } from "react";
import { api } from "../../../../services/api";
import { ContactsAnalyticsTable } from "./ContactsAnalyticsTable";
import ChevronDown from "../../../../assets/icons/chevron-down.svg";
import { getDates } from "../utils";

export function ContactsAnalyticsTableWrapper({
  open,
  user,
}: {
  open: boolean;
  user: any;
}) {
  const [filter, setFilter] = React.useState<any>("");
  const [sentReveals, setSentReveals] = React.useState<any>([]);
  const [revealCounts, setRevealCounts] = React.useState<any>({});
  const [receivedReveals, setReceivedReveals] = React.useState<any>([]);
  const [revealLoading, setRevealLoading] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<"sent" | "received">(
    "sent"
  );

  const fetchSentReveals = async (userId: string) => {
    setRevealLoading(true);
    const { start_date, end_date } = getDates(filter);
    const response = await api.getUniqueUsers({
      user_id: userId,
      type: "sent",
      start_date,
      end_date,
    });
    setRevealLoading(false);
    setRevealCounts({
      receivedCount: response?.data?.total_received,
      sentCount: response?.data?.total_sent,
    });
    setSentReveals(response?.data?.data || []);
  };

  const fetchReceivedReveals = async (userId: string) => {
    setRevealLoading(true);
    const { start_date, end_date } = getDates(filter);
    const response = await api.getUniqueUsers({
      user_id: userId,
      type: "received",
      start_date,
      end_date,
    });
    setRevealLoading(false);
    setRevealCounts({
      receivedCount: response?.data?.total_received,
      sentCount: response?.data?.total_sent,
    });
    setReceivedReveals(response?.data?.data || []);
  };

  useEffect(() => {
    if (user && open) {
      if (selectedTab === "sent") {
        fetchSentReveals(user.user_id);
      } else {
        fetchReceivedReveals(user.user_id);
      }
    }
  }, [user, open]);

  useEffect(() => {
    if (!open) return;
    if (!user || !user.user_id) return;
    if (selectedTab === "sent") {
      fetchSentReveals(user.user_id);
    } else {
      fetchReceivedReveals(user.user_id);
    }
  }, [filter, selectedTab, user.user_id]);

  const handleSelectedTab = (tab: "sent" | "received") => {
    setSelectedTab(tab);
    // if (tab === "sent") {
    //   fetchSentReveals(user.user_id);
    // } else {
    //   fetchReceivedReveals(user.user_id);
    // }
  };

  if (!open) return null;

  return (
    <div className="bg-white rounded-[16px] p-6">
      <div className="flex justify-between items-center mb-4">
        <div className="bg-[#EEF1EF] rounded-full p-1 flex items-center gap-1">
          <button
            onClick={() => handleSelectedTab("sent")}
            className={`${
              selectedTab === "sent"
                ? "bg-white"
                : "bg-transparent hover:bg-white/60"
            } rounded-full px-4 py-2 text-base text-[#33363F]`}
          >
            People sent to ({revealCounts.sentCount})
          </button>
          <button
            onClick={() => handleSelectedTab("received")}
            className={`${
              selectedTab === "received"
                ? "bg-white"
                : "bg-transparent hover:bg-white/60"
            } rounded-full px-4 py-2 text-base text-[#33363F]`}
          >
            People received from ({revealCounts.receivedCount})
          </button>
        </div>
        <select
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          className="bg-[#EEF1EF] rounded-full pl-4 pr-8 py-2 outline-none appearance-none cursor-pointer hover:bg-[#DBE3DC]"
          style={{
            backgroundImage: `url(${ChevronDown})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "90% 55%",
          }}
        >
          <option value="">All time</option>
          <option value="one_day">Past one day</option>
          <option value="this_week">Past week</option>
          <option value="this_month">Past month</option>
          <option value="this_three_month">Past 3 months</option>
          <option value="this_year">Past year</option>
        </select>
      </div>
      <div className="max-h-[calc(100vh-425px)] overflow-auto">
        {selectedTab === "sent" ? (
          <ContactsAnalyticsTable
            key={selectedTab}
            type={selectedTab}
            userId={user.user_id}
            timeFilter={filter}
          />
        ) : (
          <ContactsAnalyticsTable
            key={selectedTab}
            type={selectedTab}
            userId={user.user_id}
            timeFilter={filter}
          />
        )}
      </div>
    </div>
  );
}
