import React from "react";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { ReportList } from "./ReportList";
import { AuthScreen } from "./AuthScreen";

export function Reports() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  return (
    <div className="bg-background h-[100vh] p-7 pt-6">
      <div className="mb-4">
        <h1 className="text-black text-[32px] font-bold">Reports</h1>
      </div>
      <LoadAnimatedContainer>
        {isAuthenticated ? (
          <ReportList />
        ) : (
          <div className="bg-white p-8 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] rounded-[16px] flex flex-wrap gap-5 h-[calc(100vh-100px)] overflow-auto">
            <AuthScreen onAuth={() => setIsAuthenticated(true)} />
          </div>
        )}
      </LoadAnimatedContainer>
    </div>
  );
}
