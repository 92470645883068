import { useEffect, useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-2.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { Button } from "../../components/Button";

import Spicy from "../../assets/icons/spicy.svg";
import { EmptyStateForm } from "./EmptyStateForm";
import { api } from "../../services/api";
import { EmptyStateDeleteConfirm } from "./components/EmptyStateDeleteConfirm";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { ShowToast } from "../../components/Toast";
import useEmptyStates from "./useEmptyState";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 9 }).map((_, i) => (
        <div
          key={i}
          className="relative bg-gray-50/50 flex items-center border border-solid border-[#E1E9E4] rounded-[16px] w-[330px] h-[240px] flex-col p-6 animate-pulse transition duration-50"
        >
          <div className="w-[70px] h-[70px] rounded-full bg-gray-300 animate-pulse transition duration-50"></div>
          <div className="flex flex-col justify-between items-center mt-[14px]">
            <div className="w-40 h-4 text-black1 text-lg font-bold mb-5 text-center bg-gray-300 rounded animate-pulse transition duration-50"></div>
            <div className="w-48 h-3.5 mb-2 text-[rgba(51,54,63,0.44)] text-base text-center bg-gray-300 rounded-md animate-pulse transition duration-50"></div>
            <div className="w-20 h-3.5 text-[rgba(51,54,63,0.44)] text-base text-center bg-gray-300 rounded-md animate-pulse transition duration-50"></div>
          </div>
          <div className="absolute top-6 right-6 flex gap-3 items-center">
            <div className="w-5 h-5 text-[rgba(51,54,63,0.44)] text-base text-center bg-gray-300 rounded animate-pulse transition duration-50"></div>
            <div className="w-5 h-5 text-[rgba(51,54,63,0.44)] text-base text-center bg-gray-300 rounded animate-pulse transition duration-50"></div>
          </div>
        </div>
      ))}
    </>
  );
}

export type State = {
  empty_state_id?: string;
  image?: string;
  reveal_to: string;
  text: string;
  shadow_color_code: string;
};
export function EmptyStates() {
  const {
    updateFilters,
    total,
    filters,
    emptyStates,
    isLoading,
    isFetching,
    loadMore,
    refetch,
  } = useEmptyStates();
  const [showForm, setShowForm] = useState<State | undefined>();
  const [removeStateModal, setRemoveStateModal] = useState<State | null>(null);
  const [deleting, setDeleting] = useState(false);

  const handleAddNew = () => {
    setShowForm({
      reveal_to: "",
      text: "",
      shadow_color_code: "rgba(0,0,0,0.5)",
    });
  };

  const handleEdit = (state: State) => {
    setShowForm(state);
  };

  const handleRemove = (state: State) => {
    setRemoveStateModal(state);
  };

  const handleRemoveState = () => {
    if (removeStateModal) {
      setDeleting(true);
      api
        .deleteEmptyState(removeStateModal.empty_state_id as string)
        .then(async (response) => {
          if (response.status === 200) {
            if (response.data.status) {
              await refetch();
              ShowToast({
                type: "success",
                message: response?.data?.message,
              });
            } else {
              ShowToast({
                type: "error",
                message: response?.data?.message || "Something went wrong",
              });
            }
          }
        })
        .catch((e) => {
          ShowToast({
            type: "error",
            message: "Network request failed",
          });
        })
        .finally(() => {
          setDeleting(false);
          setRemoveStateModal(null);
        });
    } else {
      setRemoveStateModal(null);
    }
  };

  console.log("data", emptyStates);

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // TODO: temporary adding -1 to total due to backend issue
        if (
          entries[0].isIntersecting &&
          emptyStates &&
          emptyStates.length < total
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, emptyStates, total]);

  const statesData = emptyStates || [];

  return (
    <div className="bg-background h-[100vh] p-7 pt-6">
      <div className="mb-4">
        <h1 className="text-black text-[32px] font-bold">Empty States</h1>
      </div>
      <LoadAnimatedContainer>
        <div className="bg-white p-8 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] rounded-[16px] flex flex-wrap gap-5 h-[calc(100vh-100px)] overflow-auto">
          {statesData.map((state) => (
            <div
              key={state.empty_state_id}
              className="relative flex items-center border border-solid border-[#E1E9E4] rounded-[16px] w-[330px] h-[240px] flex-col p-6"
            >
              <div className="p-2 rounded-full border-[5px] border-[#7F7E89]">
                <div className="w-[70px] h-[70px] rounded-full p-3 relative flex items-center justify-center">
                  <img
                    src={state.image}
                    alt=""
                    className="w-full h-full object-cover z-[1]"
                  />
                  <div
                    className="absolute rounded-full w-full h-full blur-xl"
                    style={{
                      backgroundColor:
                        state.shadow_color_code || "rgba(0,0,0,0.5)",
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between mt-[14px]">
                <div className="text-black1 text-lg font-bold mb-3 text-center">
                  {state.reveal_to}
                </div>
                <div className="text-[rgba(51,54,63,0.44)] text-base text-center">
                  {state.text}
                </div>
              </div>
              <div className="flex gap-3 items-center absolute top-6 right-6">
                <Button
                  variant="icon"
                  className="!p-0"
                  onClick={() => handleEdit(state)}
                >
                  <EditIcon className="w-5 h-5" />
                </Button>
                <Button
                  variant="icon"
                  className="!p-0"
                  onClick={() => handleRemove(state)}
                >
                  <TrashIcon className="w-5 h-5" />
                </Button>
              </div>
            </div>
          ))}

          {!isLoading && (
            <div>
              <button
                onClick={handleAddNew}
                className="flex justify-center  items-center group rounded-[16px] text-white px-5 py-2 border border-solid border-[#E1E9E4] rounded-[16px] w-[330px] bg-[#EEF1EF] hover:bg-[#EEF1EF]/90 h-[240px]"
              >
                <PlusIcon className="transition duration-75 group-hover:scale-150" />
              </button>
            </div>
          )}
          {isLoading && <LoadingSkeleton />}
          <div ref={observerTarget}></div>
        </div>
      </LoadAnimatedContainer>
      <EmptyStateForm
        open={!!showForm}
        onClose={(refresh) => {
          if (refresh === true) {
            refetch();
          }
          setShowForm(undefined);
        }}
        state={showForm}
      />
      <EmptyStateDeleteConfirm
        open={!!removeStateModal}
        onClose={() => setRemoveStateModal(null)}
        onConfirm={handleRemoveState}
        loading={deleting}
      />
    </div>
  );
}
