import { Button } from "../../components/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-2.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as Crown } from "../../assets/icons/crown.svg";
import { ReactComponent as More } from "../../assets/icons/More.svg";
import { ReactComponent as BoostIcon } from "../../assets/icons/boost.svg";
import { ReactComponent as UnBoostIcon } from "../../assets/icons/unboost.svg";

import Spicy from "../../assets/icons/spicy.svg";

import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { EditQuestionForm } from "./components/EditQuestionForm";
import useQuestions from "./useQuestions";
import { DeleteQuestionConfirm } from "./components/DeleteQuestionConfirm";
import { api } from "../../services/api";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { EmptyTableView } from "../../components/EmptyTableView";
import { ShowToast } from "../../components/Toast";
import { BoostQuestion } from "./components/BoostQuestion";
import { BoostedQuestions } from "./BoostedQuestions";
import { BoostViewButton } from "./components/BoostViewButton";
import { UploadCsvDialog } from "../plus-packs/components/UploadCsvDialog";
import UIPopover from "../../components/Popover/Popover";
import { QuestionFilter } from "./components/Filters";
import toast from "react-hot-toast";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr className="hover:bg-gray-100 [&_td]:py-3 border-b" key={i}>
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <div className="flex items-center gap-[2px]">
                  <div className="w-48 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
                </div>
              </div>
            </div>
          </td>
          <td className="account-executive">
            <div className="w-12 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="subscription">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-manager">
            <div className="w-12 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-executive">
            <div className="w-12 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="mrr">
            <div className="w-12 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
        </tr>
      ))}
    </>
  );
}

export function Questions({
  showQuestionForm,
  onQuestionFormClose,
  category,
  clearCategory,
}: {
  showQuestionForm: boolean;
  onQuestionFormClose: () => void;
  category: any;
  clearCategory: () => void;
}) {
  const navigate = useNavigate();
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    totalPages,
    questions,
    total,
    loadMore,
  } = useQuestions({ is_boosted: false });
  const [showEditQuestionForm, setShowEditQuestionForm] = useState<any>(false);
  const [deleting, setDeleting] = React.useState(false);
  const [boostedTotal, setBoostedTotal] = useState<number>(0);
  const [removeCategoryModal, setRemoveCategoryModal] = useState<any>(null);
  const [packView, setPackView] = React.useState<any>(null);
  const [boostQuestionForm, setBoostQuestionForm] = React.useState<any>(false);
  const [showBoostedQuestionsView, setShowBoostedQuestionsView] =
    React.useState<boolean>(false);

  const onSortChange = (newFilters) => {
    // setFilters({ ...filters, ...newFilters });
    updateFilters(newFilters);
  };

  const handleBoostedQuestions = () => {
    setShowBoostedQuestionsView(true);
  };

  const closeBoostedQuestions = () => {
    setShowBoostedQuestionsView(false);
  };

  const handleRemoveCategoryConfirm = () => {
    setDeleting(true);
    // delete category
    api
      .deleteQuestion(removeCategoryModal?.question_id)
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data?.status) {
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            refetch();
            setRemoveCategoryModal(null);
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      })
      .catch((e) => {
        ShowToast({
          type: "error",
          message: "Network request failed",
        });
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const handleUnBoostCategory = async (question) => {
    toast.loading("Unboosting category...");
    api
      .boostCategoryOrQuestion({
        category_id: question?._question_category_id,
        is_boost: 0,
        boost_until: "",
      })
      .then((response) => {
        toast.dismiss();
        if (response?.status === 200) {
          if (response?.data?.status) {
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            fetchBoostedCategoryTotal();
            refetch(undefined, undefined, true);
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      })
      .catch((e) => {
        toast.dismiss();
        ShowToast({
          type: "error",
          message: "Network request failed",
        });
      });
  };
  const handleUnBoostQuestion = async (question) => {
    toast.loading("Unboosting question...");
    api
      .boostCategoryOrQuestion({
        question_id: question?.question_id,
        is_boost: 0,
        boost_until: "",
      })
      .then((response) => {
        toast.dismiss();
        if (response?.status === 200) {
          if (response?.data?.status) {
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            fetchBoostedCategoryTotal();
            refetch(undefined, undefined, true);
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      })
      .catch((e) => {
        toast.dismiss();
        ShowToast({
          type: "error",
          message: "Network request failed",
        });
      });
  };

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();
  const handleSort = (key) => {
    if (key === filters.sort_by) {
      onSortChange({
        sort_by: filters.sort_order === "desc" ? "" : key,
        sort_order:
          filters.sort_order === "asc"
            ? "desc"
            : filters.sort_order === "desc"
            ? ""
            : "asc",
      });
    } else {
      onSortChange({ sort_order: "asc", sort_by: key });
    }
  };
  const handlePageChange = (page: number) => {
    updateFilters({ page });
  };

  const handleBoostQuestion = (question) => {
    setBoostQuestionForm(question);
  };

  const closeBoostQuestionForm = () => {
    setBoostQuestionForm(null);
  };

  const fetchBoostedCategoryTotal = () => {
    api
      .getAllQuestions({
        is_boosted: true,
        page: 1,
        limit: 10,
        categories: [],
        end_date: "",
        start_date: "",
        search: "",
      })
      .then((response) => {
        setBoostedTotal(response?.total);
      });
  };

  const handleCategoryFilter = (category) => {
    updateFilters({
      categories: filters.categories.filter(
        (c) => c.question_category_id !== category.question_category_id
      ),
    });
  };

  const handleClearCategoryFilter = () => {
    updateFilters({
      categories: [],
      start_date: "",
      end_date: "",
      timeType: "",
      is_boosted: false,
    });
    clearCategory();
  };

  const handleBoostFilter = () => {
    updateFilters({ is_boosted: true });
  };

  useEffect(() => {
    fetchBoostedCategoryTotal();
  }, []);

  useEffect(() => {
    if (category) {
      updateFilters({ categories: [category] });
    }
  }, [category]);

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // TODO: temporary adding -1 to total due to backend issue
        if (
          entries[0].isIntersecting &&
          questions &&
          total &&
          questions.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, questions, total, isLoading]);

  useEffect(() => {
    if (packView) {
      const newPack = questions?.find(
        (category) =>
          category.question_category_id === packView.question_category_id
      );
      if (newPack) {
        setPackView(newPack);
      }
    }
  }, [questions, packView]);

  const hasFilter =
    filters.categories.length > 0 || filters.is_boosted || filters.start_date;

  return (
    <>
      <LoadAnimatedContainer>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
              </div>
            </div>

            <div className="flex gap-2 items-center">
              <BoostViewButton
                onClick={handleBoostFilter}
                count={boostedTotal}
              />
              <QuestionFilter
                selectedFilters={{ ...filters }}
                onChange={(type, customDates, selectedCategory) => {
                  updateFilters({
                    timeType: type,
                    ...customDates,
                    categories: selectedCategory,
                  });
                }}
              />
            </div>
          </div>
          {hasFilter && (
            <div className="flex flex-wrap items-center gap-2 mt-3">
              {filters.is_boosted && (
                <div className="flex items-center py-2 px-4 gap-1 bg-[#EEF1EF] rounded-[50px]">
                  <span className="text-[#33363F] text-sm">Boosted</span>
                  <button onClick={() => updateFilters({ is_boosted: false })}>
                    <CloseIcon className="w-[14px] h-[14px] [&>path]:stroke-2" />
                  </button>
                </div>
              )}
              {filters.categories.map((category) => (
                <div
                  key={category.question_category_id}
                  className="flex items-center py-2 px-4 gap-1 bg-[#EEF1EF] rounded-[50px]"
                >
                  <span className="text-[#33363F] text-sm">
                    {category.category_name}
                  </span>
                  <button onClick={() => handleCategoryFilter(category)}>
                    <CloseIcon className="w-[14px] h-[14px] [&>path]:stroke-2" />
                  </button>
                </div>
              ))}
              {filters.start_date && (
                <div className="flex items-center py-2 px-4 gap-1 bg-[#EEF1EF] rounded-[50px]">
                  <span className="text-[#33363F] text-sm">
                    {moment
                      .utc(filters.start_date)
                      .local()
                      .format("DD MMM YYYY")}{" "}
                    {filters.end_date && (
                      <>
                        -{" "}
                        {moment
                          .utc(filters.end_date)
                          .local()
                          .format("DD MMM YYYY")}
                      </>
                    )}
                  </span>
                  <button
                    onClick={() =>
                      updateFilters({
                        timeType: "",
                        start_date: "",
                        end_date: "",
                      })
                    }
                  >
                    <CloseIcon className="w-[14px] h-[14px] [&>path]:stroke-2" />
                  </button>
                </div>
              )}
              <Button
                variant="link"
                onClick={() => handleClearCategoryFilter()}
                className="!text-[#18DEA3] !text-sm"
              >
                Clear all
              </Button>
            </div>
          )}
          <div
            className={`mt-3 text-[14px] ${
              hasFilter ? "h-[calc(100vh-280px)]" : "h-[calc(100vh-232px)]"
            } overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                <col style={{ width: "38%" }} />
                <col style={{ width: "8%" }} />
                <col style={{ width: "18%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td className="rounded-l pl-5">
                    <div className="flex items-center">
                      Question
                      {/* {filters.sort_by === "client_name" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "asc"
                              ? "rotate-[270deg]"
                              : "rotate-[90deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>
                  <td>Boosted</td>
                  <td>
                    <div className="flex items-center">
                      Category
                      {filters.sort_by === "total_active_subscription" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "asc"
                              ? "rotate-[270deg]"
                              : "rotate-[90deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">
                      {filters.is_boosted ? "Boosted on" : "Added on"}
                      {/* {filters.sort_by === "account_cs_manager" ? (
                    <ArrowRight
                      stroke="#2A2B2F"
                      className={`sort-icon ${
                        filters.sort_order === "asc"
                          ? "rotate-[270deg]"
                          : "rotate-[90deg]"
                      }`}
                    />
                  ) : null} */}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => handleSort("used")}
                      className="flex items-center cursor-pointer"
                    >
                      Used
                      {filters.sort_by === "used" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "asc"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => handleSort("skipped")}
                      className="flex items-center cursor-pointer"
                    >
                      Skipped
                      {filters.sort_by === "skipped" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "asc"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>

                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {isLoading === false && !isFetching && !questions?.length && (
                  <EmptyTableView
                    cols={6}
                    message="No questions found!"
                    className="h-[calc(100vh-300px)]"
                  />
                )}
                {questions?.map((category) => (
                  <tr
                    key={category.question_category_id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setPackView(category)}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <div className="flex items-center gap-[2px]">
                            <h5 className="flex-1">
                              {category?.question_text}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center pr-7">
                        {!!category?.is_boosted ? <BoostIcon /> : ""}
                      </div>
                    </td>
                    <td className="subscription">
                      <div className="flex items-center gap-2">
                        <div className="w-[18px] h-[18px]">
                          <img
                            alt="category"
                            src={category.category_image}
                            className="w-full h-full object-contain"
                          />
                        </div>
                        {category.category_name}
                      </div>
                    </td>
                    <td className="account-manager">
                      {filters.is_boosted
                        ? category.boosted_on
                          ? moment
                              .utc(category.boosted_on)
                              .local()
                              .format("MMM DD,YYYY")
                          : "N/A"
                        : moment
                            .utc(category.date_created)
                            .local()
                            .format("MMM DD,YYYY")}
                    </td>
                    <td className="account-executive">
                      {category.answered_count} time
                      {category.answered_count === 1 ? "" : "s"}
                    </td>
                    <td className="mrr">
                      {category.skipped_count} time
                      {category.skipped_count === 1 ? "" : "s"}
                    </td>

                    <td onClick={stopPropagate} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                        positions={["bottom", "top", "left", "right"]}
                      >
                        {(close) => (
                          <Dropdown
                            removeUser={() => {
                              close();
                              setRemoveCategoryModal(category);
                            }}
                            onBoost={() => {
                              close();
                              handleBoostQuestion(category);
                            }}
                            onEdit={() => {
                              close();
                              setShowEditQuestionForm(category);
                            }}
                            onUnBoost={() => {
                              close();
                              handleUnBoostQuestion(category);
                            }}
                            onUnboostCategory={() => {
                              close();
                              handleUnBoostCategory(category);
                            }}
                            isBoostedFilter={filters.is_boosted}
                            isCategoryBoosted={!!category.is_category_boosted}
                            isQuestionBoosted={!!category.is_boosted}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {isLoading && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      <UploadCsvDialog
        onClose={(refresh) => {
          if (refresh === true) {
            refetch();
          }
          onQuestionFormClose();
        }}
        open={showQuestionForm}
        showCategory={true}
        refetchQuestions={refetch}
      />

      <DeleteQuestionConfirm
        open={!!removeCategoryModal}
        onClose={() => {
          setRemoveCategoryModal(null);
        }}
        onConfirm={handleRemoveCategoryConfirm}
        // title={`Are you sure you want to delete the category ‘${removeCategoryModal?.category_name}’?`}
        loading={deleting}
        question={removeCategoryModal?.question_text}
      />
      {!!boostQuestionForm && (
        <BoostQuestion
          open={!!boostQuestionForm}
          onClose={closeBoostQuestionForm}
          question={boostQuestionForm}
          refresh={() => {
            fetchBoostedCategoryTotal();
            refetch(undefined, undefined, true);
          }}
        />
      )}
      {showBoostedQuestionsView && (
        <BoostedQuestions
          open={showBoostedQuestionsView}
          onClose={() => setShowBoostedQuestionsView(false)}
          refresh={() => {
            fetchBoostedCategoryTotal();
            refetch(undefined, undefined, true);
          }}
        />
      )}
      {!!showEditQuestionForm && (
        <EditQuestionForm
          open={!!showEditQuestionForm}
          onClose={(refresh) => {
            if (refresh === true) {
              refetch(undefined, undefined, true);
            }
            setShowEditQuestionForm(false);
          }}
          question={showEditQuestionForm}
        />
      )}
    </>
  );
}

const Dropdown = ({
  removeUser,
  onEdit,
  onBoost,
  onUnBoost,
  onUnboostCategory,
  isBoostedFilter,
  isCategoryBoosted,
  isQuestionBoosted,
}) => {
  return (
    <div
      className={`z-10 ${
        isBoostedFilter ? "w-60" : "w-60"
      } bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8`}
    >
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <>
          {isQuestionBoosted && (
            <li>
              <Button
                variant="icon"
                className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
                onClick={onUnBoost}
                scaleAnimated={false}
              >
                <UnBoostIcon />
                Unboost
              </Button>
            </li>
          )}
          {isCategoryBoosted && (
            <li>
              <Button
                variant="icon"
                className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
                onClick={onUnboostCategory}
                scaleAnimated={false}
              >
                <UnBoostIcon />
                Unboost whole category
              </Button>
            </li>
          )}
        </>
        {!isBoostedFilter && (
          <li>
            <Button
              variant="icon"
              className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
              onClick={onBoost}
              scaleAnimated={false}
            >
              <BoostIcon />
              {isQuestionBoosted ? "Reboost" : "Boost"}
            </Button>
          </li>
        )}
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onEdit}
            scaleAnimated={false}
          >
            <EditIcon />
            Edit
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-gray-100 text-[#F54343] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            <TrashIcon />
            Delete
          </Button>
        </li>
      </ul>
    </div>
  );
};
