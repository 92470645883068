import moment from "moment";
import { Modal } from "../../../components/Modals/Modal";
import { api } from "../../../services/api";
import { Loading } from "../../../components/Loading/Loading";
import { useEffect, useState } from "react";
import { ShowToast } from "../../../components/Toast";

export function ReportedCommentModal({ open, onClose, data, title }) {
  const [isLoading, setIsLoading] = useState(false);
  const [reveal, setReveal] = useState<any>(null);

  useEffect(() => {
    if (!data) return;
    setIsLoading(true);
    api
      .getCommentDetails(data?._revealed_comment_id)
      .then((res) => {
        setReveal(res);
      })
      .catch((err) => {
        ShowToast({
          type: "error",
          message: "Failed to fetch reveal details",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [data]);

  const revealData = reveal?.data;
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      contentClassName="z-[1000] !w-[390px] !pt-4"
      modalClassName="[&_h1]:text-lg"
    >
      <div className="relative">
        <div>
          <div className="rounded-[20px] h-full opacity-40 absolute top-0 bottom-0 right-0 left-0 "></div>
          <div className="bg-[rgba(255,255,255,0.84)] px-4 py-4 rounded-[26px] border border-[#DDD] z-[10] relative overflow-auto max-h-[calc(100vh-200px)]">
            {isLoading ? (
              <div className="flex justify-center items-center">
                <Loading />
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="flex gap-2 items-center">
                  <div className="min-w-11 min-h-11 w-11 h-11 rounded-full">
                    <img
                      src={revealData?.commented_by?.user_image}
                      alt=""
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="text-black text-[17px] font-medium mr-1 inline">
                      <b>
                        {revealData?.commented_by?.first_name}{" "}
                        {revealData?.commented_by?.last_name}
                      </b>
                    </div>
                    <span className="text-[#9F9F9F] text-[13px] font-medium">
                      {moment.utc(revealData?.comment_date).local().fromNow()}
                    </span>
                  </div>
                </div>
                <div className="mt-7 flex flex-col overflow-auto max-h-[50vh]">
                  <span className="text-base opacity-50 text-black">
                    Comment
                  </span>
                  <div className="text-black font-medium pt-1 pb-1 text-2xl">
                    {revealData?.comment_text}
                  </div>
                </div>
                <div className="bg-[#F5F5F5] rounded-[12px] p-[14px] flex flex-col gap-1.5 my-6">
                  <span className="text-base opacity-50 text-black font-medium">
                    Reason of report
                  </span>
                  <span className="text-lg font-medium text-black">
                    {data?.reason}
                  </span>
                </div>
                <p className="text-base opacity-50 text-black font-medium">
                  Reveal, the commented added in
                </p>
                <div className="relative mt-4">
                  <div className="rounded-[20px] h-full opacity-40 absolute top-0 bottom-0 right-0 left-0 blur bg-[linear-gradient(125deg,#FF8C00_-2.31%,#FF0F7B_97.12%)]"></div>
                  <div className="bg-[rgba(255,255,255,0.84)] px-4 py-4 rounded-[26px] shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] z-[10] relative">
                    <div className="flex flex-col">
                      <div className="flex gap-2 items-center">
                        <div className="min-w-11 min-h-11 w-11 h-11 rounded-full">
                          <img
                            src={revealData?.shared_by?.user_image}
                            alt=""
                            className="w-full h-full object-cover rounded-full"
                          />
                        </div>
                        <div className="flex-1">
                          <div className="text-black text-[17px] font-medium mr-1 inline">
                            <b>
                              {revealData?.shared_by?.first_name}{" "}
                              {revealData?.shared_by?.last_name}
                            </b>{" "}
                            revealed to{" "}
                            {revealData?.shared_with?.length > 0 && (
                              <>
                                <b>
                                  {revealData?.shared_with[0]?.first_name}{" "}
                                  {revealData?.shared_with[0]?.last_name}
                                </b>{" "}
                                {!!(revealData?.shared_with?.length - 1) && (
                                  <span>
                                    and {revealData?.shared_with?.length - 1}{" "}
                                    others
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                          <span className="text-[#9F9F9F] text-[13px] font-medium">
                            {moment
                              .utc(revealData?.reveal_date)
                              .local()
                              .fromNow()}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col overflow-auto max-h-[50vh]">
                        <div className="text-black/50 py-5 text-2xl">
                          {revealData?.question_text}
                        </div>
                        <div className="w-20 bg-black/50 h-[1px]" />
                        <div className="text-black font-medium pt-5 pb-1 text-2xl">
                          {revealData?.answer_text}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
