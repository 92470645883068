import moment from "moment";
import { Button } from "../../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";
import { ReactComponent as BanIcon } from "../../../assets/icons/slash.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import useUsers from "./useUsers";
import { UserView } from "../View/View";
import { UserDeleteConfirm } from "../components/UserDeleteConfirm";
import { api } from "../../../services/api";
import Tippy from "@tippyjs/react";
import { BlockedByView } from "../components/BlockedByPopover";
import { ReactComponent as InfoCircle } from "../../../assets/icons/info-circle.svg";
import { ReactComponent as Subtract } from "../../../assets/icons/subtract.svg";
import { BlockedContentModal } from "../components/BlockedContentModal";
import toast from "react-hot-toast";
import { EmptyTableView } from "../../../components/EmptyTableView";
import { ShowToast } from "../../../components/Toast";
import { CrownTooltip } from "../components/CrownTooltip";
import { UserIdWithTooltip } from "../components/UserIdWithTooltip";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                  <div className="w-4 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
                <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function BlockedByUserList() {
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    users,
    total,
    loadMore,
  } = useUsers({ type: "blocked" });

  const [deleting, setDeleting] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [blockedContent, setBlockedContent] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  const onSortChange = (newFilters) => {
    // setFilters({ ...filters, ...newFilters });
    updateFilters(newFilters);
  };

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      onSortChange({
        sort_by: filters.sort_order === "desc" ? "" : key,
        sort_order:
          filters.sort_order === "asc"
            ? "desc"
            : filters.sort_order === "desc"
            ? ""
            : "asc",
      });
    } else {
      onSortChange({ sort_order: "asc", sort_by: key });
    }
  };
  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleDeleteUser = (user) => {
    setRemoveUserModal(user);
  };
  const handleActivateUserConfirm = async (user) => {
    try {
      // api call
      toast.loading("Unblocking user...");
      const response = await api.blockUser({ user_id: user.user_id });
      if (response.status === 200) {
        if (response?.data?.status) {
          toast.dismiss();
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch();
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleRemoveUserConfirm = async () => {
    // api call
    const user = removeUserModal;
    setDeleting(true);
    const response = await api.deleteUser({ user_id: user.user_id });
    setDeleting(false);
    if (response.status === 200) {
      if (response.data.status) {
        ShowToast({
          type: "success",
          message: response?.data?.message,
        });
        refetch(user.user_id, true);
        setRemoveUserModal(null);
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } else {
      ShowToast({
        type: "error",
        message: response?.data?.message || "Something went wrong",
      });
    }
  };

  const handleReportedContent = (item) => {
    setBlockedContent(item);
  };

  const closeReportedContent = () => {
    setBlockedContent(null);
  };

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // TODO: temporary adding -1 to total due to backend issue
        if (entries[0].isIntersecting && users && users.length < total) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, users, total]);

  return (
    <>
      <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5">
        <div className="flex items-center justify-between pl-2">
          <div className="flex items-center gap-2">
            <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
              <SearchIcon />
              <input
                type="text"
                placeholder="Search"
                className="outline-none"
                onChange={(e) => updateFilters({ keyword: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 text-[14px] h-[calc(100vh-250px)] overflow-auto">
          <table className="w-full">
            <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
              <tr className="sticky top-0 z-[1]">
                <td
                  onClick={() => handleSort("name")}
                  className="rounded-l pl-5"
                >
                  <div className="cursor-pointer flex items-center">
                    User Name/ID
                    {filters.sort_by === "name" ? (
                      <ArrowRight
                        stroke="#2A2B2F"
                        className={`sort-icon ${
                          filters.sort_order === "asc"
                            ? "rotate-[180deg]"
                            : "rotate-[0deg]"
                        }`}
                      />
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="flex items-center">Phone number</div>
                </td>
                <td>
                  <div className="flex items-center">Blocked on</div>
                </td>
                <td>
                  <div className="flex items-center">Blocked by</div>
                </td>
                <td className="rounded-r">Action</td>
              </tr>
            </thead>
            <tbody>
              {!isLoading && !isFetching && !users?.length && (
                <EmptyTableView
                  cols={5}
                  message="No blocked user found!"
                  className="h-[calc(100vh-300px)]"
                />
              )}
              {users?.map((aclient, i) => (
                <tr
                  key={aclient.user_id}
                  className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                  onClick={() => setUser(aclient)}
                >
                  <td className="name pl-5">
                    <div className="flex items-center gap-3">
                      <img
                        src={aclient.user_image}
                        className="w-10 h-10 rounded-full object-cover"
                        alt="user"
                      />
                      <div className="flex flex-col">
                        <div className="flex items-center gap-1">
                          <h5>
                            {aclient.first_name} {aclient.last_name}
                          </h5>
                          <CrownTooltip />
                        </div>
                        <UserIdWithTooltip userId={aclient.user_id} />
                      </div>
                    </div>
                  </td>
                  <td className="subscription">
                    {/* {!!isInactive && aclient.total_past_subscription} */}
                    {/* {!!isTrial && aclient.total_trial_subscription} */}
                    {/* {!!isActive && aclient.total_active_subscription} */}
                    {aclient.u_phone_number}
                  </td>
                  <td className="account-manager">
                    {moment
                      .utc(aclient.blocked_on)
                      .local()
                      .format("MMM DD,YYYY | hh:mm A")}
                  </td>
                  <td className="account-executive">
                    <td className="name">
                      <Tippy
                        className="my-tooltip"
                        arrow={true}
                        interactive={true}
                        hideOnClick={true}
                        content={
                          <BlockedByView
                            list={aclient.blocked_by} // TODO: change this to blocked_by array
                            onBlockedContent={handleReportedContent}
                          />
                        }
                      >
                        <div className="flex items-center gap-1">
                          <span>
                            {aclient?.blocked_by?.length || 1}{" "}
                            {aclient?.blocked_by?.length > 1 ? "users" : "user"}
                          </span>
                          <span className="cursor-pointer mt-[2px]">
                            <div className="w-4 h-4 rounded-full ">
                              <Subtract className="[&_path]:fill-black" />
                            </div>
                          </span>
                        </div>
                      </Tippy>
                    </td>
                  </td>
                  <td onClick={stopPropagate}>
                    <div className="flex items-center gap-7">
                      <Button
                        variant="icon"
                        className="!p-0 text-black hover:text-[#F54343]"
                        onClick={() => handleDeleteUser(aclient)}
                      >
                        <TrashIcon />
                        Remove User
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
              {(isLoading || isFetching) && <LoadingSkeleton />}

              <div ref={observerTarget}></div>
            </tbody>
          </table>
        </div>
      </div>
      <UserView
        open={!!user}
        onClose={() => setUser(null)}
        user={user}
        refetch={refetch}
      />
      <UserDeleteConfirm
        title={`Are you sure you want to remove ‘${removeUserModal?.first_name} ${removeUserModal?.last_name}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      <BlockedContentModal
        open={!!blockedContent}
        onClose={closeReportedContent}
        data={blockedContent}
        title={"Blocked Content"}
      />
    </>
  );
}
