import { FileUploader } from "react-drag-drop-files";
import { ReactComponent as Upload } from "../../assets/icons/csv-upload.svg";

const fileTypes = ["csv"];

export function UploadCsv({
  onChange,
  name,
}: {
  onChange: (file: any) => void;
  name?: string;
}) {
  return (
    <div>
      <FileUploader handleChange={onChange} name="file" types={fileTypes}>
        <div className="border border-dashed border-[#C1AFCF] cursor-pointer p-7 flex flex-col gap-1.5 justify-center items-center bg-[#FBF7FF] rounded-lg">
          <div className="w-11 h-11 bg-[#F2E0FF] rounded-full flex justify-center items-center">
            <Upload />
          </div>
          <div className="flex flex-col gap-[3px] items-center">
            {name ? (
              <span className="text-sm">{name}</span>
            ) : (
              <>
                <span className="text-sm">Choose file or drag & drop </span>
                <span className="text-sm text-[#9A9A9A]">(.csv)</span>
              </>
            )}
          </div>
        </div>
      </FileUploader>
    </div>
  );
}
