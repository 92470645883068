import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { api } from "../../services/api";
import { useDebounce } from "../../services/useDebounce";
const LIMIT = 10;

function usePlusPacks({ is_boosted = false }: { is_boosted?: boolean }) {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    keyword: "",
    sort_by: "",
    sort_order: "",
    is_boosted,
  });

  const signalRef = useRef<any>();
  const [hasData, setHasData] = useState(true);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedFilters = useDebounce(state, 500);
  const fetchingParams = useRef<any>();

  const fetchPlusPacks = async (
    params,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    try {
      setIsLoading(true);
      signalRef.current = new AbortController();
      fetchingParams.current = params;
      const { data, total } = await api.getCategories(
        {
          ...params,
          ...pageInfo,
        },
        signalRef.current.signal
      );
      setIsLoading(false);
      if (data.length === 0) {
        setHasData(false);
      }
      // filter deleted question
      if (isDeleted && refetchId) {
        setData((prev) =>
          prev.filter((item) => item.question_category_id !== refetchId)
        );
      } else if (refetchId) {
        setData((prev) => [
          ...prev.filter((item) => item.question_category_id !== refetchId),
          ...data,
        ]);
      } else if (refetchAll) {
        const newData: any[] = [];
        // refetch all pages
        for (let i = 1; i <= pageInfo.page; i++) {
          const { data } = await api.getCategories({
            ...params,
            ...pageInfo,
            page: i,
          });
          newData.push(...data);
        }
        setData(newData);
      } else {
        if (pageInfo.page === 1) {
          setData([...data]);
        } else {
          setData((prev) => [...prev, ...data]);
        }
      }
      setTotal(total);
    } catch (e) {
      console.log(e);
    }
  };

  const refetch = (
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    fetchPlusPacks(
      debouncedFilters,
      pagination,
      refetchId,
      isDeleted,
      refetchAll
    );
  };

  // useEffect(() => {
  //   refetch();
  // }, [debouncedFilters.sort_by, debouncedFilters.sort_order, refetch]);

  const loadMore = () => {
    // update page only we have data
    const page = pagination.page;
    const totalPage = Math.ceil(total / LIMIT);
    if (page < totalPage) {
      if (signalRef.current) {
        signalRef.current.abort();
        signalRef.current = null;
      }
      setIsLoading(true);
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchPlusPacks(debouncedFilters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    setIsLoading(true);
    setTimeout(() => {
      setData([]);
    }, 0);
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    setPagination((prev) => ({ ...prev, page: 1 }));
    setState((prev) => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    fetchPlusPacks(debouncedFilters);
  }, [debouncedFilters]);

  const categories: any[] = data || [];
  return {
    categories,
    totalPages: Math.ceil(total / LIMIT),
    total,
    pagination,
    isLoading: isLoading,
    isFetching,
    filters: state,
    updateFilters: (updates) => handleFilters(updates),
    refetch,
    loadMore,
    reset: () => {
      setData([]);
      setPagination({ page: 1, limit: LIMIT });
      setState({
        keyword: "",
        sort_by: "",
        sort_order: "",
      });
    },
  };
}

export default usePlusPacks;
