import { useEffect, useRef, useState } from "react";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-2.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { Button } from "../../components/Button";

import Spicy from "../../assets/icons/spicy.svg";
import { api } from "../../services/api";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { ShowToast } from "../../components/Toast";
import { useQuery } from "@tanstack/react-query";
import { SettingInput } from "./components/SettingInput";

function PlanFeature({ title, description, icon }) {
  return (
    <div className="flex items-center gap-4">
      <div className="text-[54px]">{icon}</div>
      <div className="flex flex-col gap-1">
        <span className="text-[#33363F] text-2xl font-bold">{title}</span>
        <span className="text-[#8F8F8F] text-lg">{description}</span>
      </div>
    </div>
  );
}

export type State = {
  empty_state_id?: string;
  image?: string;
  reveal_to: string;
  text: string;
  shadow_color_code: string;
};
export function PaywallSettings() {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["paywall-settings"],
    queryFn: () => api.getAdminSettings(),
  });

  return (
    <div className="bg-background flex flex-col h-[100vh] p-7 pt-6">
      <div className="mb-4">
        <h1 className="text-black text-[32px] font-bold">Paywall Settings</h1>
      </div>
      <LoadAnimatedContainer className="flex flex-col flex-1">
        <div className="flex items-start gap-6 flex-wrap flex-1">
          <div
            className={`relative h-full p-9 bg-white rounded-[16px] flex-1 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)]`}
          >
            <h3 className="text-[26px] font-bold text-black">Free Plan</h3>
            <div className="mt-12 flex flex-col gap-8">
              <div>
                <SettingInput
                  icon={"🎲"}
                  name="max_allowed_shuffles"
                  label="Shuffles"
                  data={data?.data || {}}
                  onUpdate={() => refetch()}
                  isLoading={isLoading}
                />
              </div>
              <div>
                <SettingInput
                  icon={"💖"}
                  name="max_allowed_reveals"
                  label="Reveals"
                  data={data?.data || {}}
                  onUpdate={() => refetch()}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
          <div
            className={`relative h-full p-9 bg-white rounded-[16px] flex-1 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)]`}
          >
            <h3 className="text-[26px] font-bold text-black">
              Reveal Plus Plan
            </h3>
            <h4 className="text-[26px] text-black">$1.99/month</h4>
            <div className="mt-12 flex flex-col gap-8">
              <PlanFeature
                icon="🎲"
                title="Unlimited shuffles"
                description={"Skip questions without limits"}
              />
              <PlanFeature
                icon="💖"
                title="Unlimited reveals"
                description={"Send as many reveals as you want"}
              />
              <PlanFeature
                icon="🔓"
                title="Instant Unlock"
                description={"Unlock without revealing to anyone"}
              />
            </div>
          </div>
        </div>
      </LoadAnimatedContainer>
    </div>
  );
}
