import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../services/useDebounce";
import { api } from "../../../services/api";
export const LIMIT = 25;

function useReveal({
  type,
  userId,
}: {
  type: "sent" | "received";
  userId: string;
}) {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    type,
    id: userId,
  });
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const debouncedFilters = useDebounce(state, 500);
  const signalRef = useRef<any>();
  const fetchingParams = useRef<any>();

  const fetchReveals = async (
    params,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    try {
      setIsLoading(true);
      signalRef.current = new AbortController();
      fetchingParams.current = params;
      const { data: revealData } = await api.getReveals(
        {
          ...params,
          ...pageInfo,
        },
        signalRef.current.signal
      );
      const total = Number(revealData[`${type}Count`]);
      const data = revealData.data;
      setIsLoading(false);
      // filter deleted question
      if (isDeleted && refetchId) {
        setData((prev) =>
          prev.filter((item) => item.question_id !== refetchId)
        );
      } else if (refetchId) {
        setData((prev) => [
          ...prev.filter((item) => item.question_id !== refetchId),
          ...data,
        ]);
      } else if (refetchAll) {
        const newData: any[] = [];
        // refetch all pages
        for (let i = 1; i <= pageInfo.page; i++) {
          const { data } = await api.getReveals({
            ...params,
            ...pageInfo,
            page: i,
          });
          newData.push(...data);
        }
        setData(newData);
      } else {
        if (pageInfo.page === 1) {
          setData([...data]);
        } else {
          setData((prev) => [...prev, ...data]);
        }
      }
      setTotal(total);
    } catch (e) {
      console.log(e);
    }
  };

  const refetch = (
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    fetchReveals(
      debouncedFilters,
      pagination,
      refetchId,
      isDeleted,
      refetchAll
    );
  };

  const loadMore = () => {
    // update page only we have data
    const page = pagination.page;
    const totalPage = Math.ceil(total / LIMIT);
    if (page < totalPage) {
      setIsLoading(true);
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchReveals(debouncedFilters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    setIsLoading(true);
    setData([]);
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    setPagination((prev) => ({ ...prev, page: 1 }));
    setState((prev) => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    fetchReveals(debouncedFilters);
  }, [debouncedFilters]);

  const reveals: any[] = data || [];
  return {
    reveals,
    totalPages: Math.ceil(total / LIMIT),
    total: total,
    pagination,
    isLoading: isLoading,
    isFetching,
    filters: state,
    updateFilters: (updates) => handleFilters(updates),
    refetch,
    loadMore,
    reset: () => {
      setData([]);
      setPagination({ page: 1, limit: LIMIT });
      setState({
        id: userId,
        type,
      });
    },
  };
}

export default useReveal;
