import moment from "moment";
import { ReactComponent as Subtract } from "../../../../assets/icons/subtract.svg";
import { AudioView } from "./Audio";
import Tippy from "@tippyjs/react";
import { EmptyTableView } from "../../../../components/EmptyTableView";
import useReveal from "../useReveal";
import { useEffect, useRef } from "react";
import { UserIdWithTooltip } from "../../components/UserIdWithTooltip";
import useContactAnalytics from "../useContactAnalytics";
import { getDates } from "../utils";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-4 border-b"
        >
          <td className="pl-5">
            <div className="w-48 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

function Contacts({ list, id }) {
  return (
    <div key={id} className="flex flex-col gap-2">
      {list?.map((item, index) => (
        <div key={index} className="flex items-center gap-2">
          <div className="w-4 h-4 rounded-full">
            <img
              src={item.user_image}
              className="w-full h-full object-cover rounded-full"
              alt=""
            />
          </div>
          <span className="text-sm text-[#202020]">
            {item.first_name} {item.last_name}
          </span>
        </div>
      ))}
    </div>
  );
}

export function ContactsAnalyticsTable({ type, userId, timeFilter }) {
  const observerTarget = useRef(null);
  const { isLoading, reveals, total, loadMore, updateFilters } =
    useContactAnalytics({
      type,
      user_id: userId,
    });

  useEffect(() => {
    const { start_date, end_date } = getDates(timeFilter);
    updateFilters({ start_date, end_date });
  }, [timeFilter]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          reveals &&
          reveals.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, reveals, total, isLoading]);

  return (
    <table className="w-full">
      <colgroup>
        <col style={{ width: "40%" }} />
        <col style={{ width: "15%" }} />
        <col style={{ width: "20%" }} />
        <col style={{ width: "25%" }} />
      </colgroup>
      <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4 [&_td]:text-sm">
        <tr className="sticky top-0 z-[1]">
          <td className="rounded-l pl-5">Contact name</td>
          <td>Phone number</td>
          <td>
            <div className="flex items-center">
              No. of reveals {type === "sent" ? "sent" : "received"}
            </div>
          </td>
          <td>
            <div className="flex items-center">
              Last reveal {type === "sent" ? "sent on" : "received on"}
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        {reveals?.length === 0 && !isLoading && (
          <EmptyTableView
            cols={4}
            message="No contacts found"
            className="pt-10"
          />
        )}
        {reveals?.map((item) => (
          <tr
            key={item.user_id}
            className="[&_td]:py-4 border-b border-b-[#EFF1FF]"
          >
            <td className="name pl-5">
              <div className="flex items-center gap-3">
                <img
                  src={item?.user_image}
                  className="w-10 h-10 rounded-full object-cover"
                  alt="user"
                />
                <div className="flex flex-col">
                  <div className="flex items-center gap-1">
                    <h5>
                      {item?.first_name} {item?.last_name}
                    </h5>
                  </div>
                  <UserIdWithTooltip
                    userId={item?.user_id}
                    contentClassName="!max-w-[240px]"
                  />
                </div>
              </div>
            </td>
            <td className="text-[#202020] text-sm">{item.u_phone_number}</td>
            <td className="text-center text-sm text-[#202020]">
              {item.share_count}
            </td>
            <td className="text-sm text-[#202020]">
              {moment
                .utc(item.last_reveal_sent_date)
                .local()
                .format("MMM DD,YYYY | hh:mm A")}
            </td>
          </tr>
        ))}
        {isLoading && <LoadingSkeleton />}
        <div ref={observerTarget}></div>
        <div className="h-5"></div>
      </tbody>
    </table>
  );
}
