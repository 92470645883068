import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-2.svg";
import Spicy from "../../../assets/icons/spicy.svg";
import { TextInput } from "../../../components/TextInput";
import { PlusPackForm } from "../PlusPackForm";
import moment from "moment";

export function CategoryView({
  category,
  refetch,
}: {
  category: any;
  refetch: () => void;
}) {
  const [categoryForm, setCategoryForm] = useState<any>(null);

  const handleChange = (e: any) => {
    setCategoryForm({
      ...categoryForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleEdit = () => {
    setCategoryForm(category);
  };

  if (!category) return null;

  // if(categoryForm) {
  //   return (
  //       <div className="flex justify-between items-center p-6 bg-white rounded-[16px] gap-3">
  //     <div className="gap-3.5 flex items-center flex-1">
  //       <div className="max-w-[52px] max-h-[52px] min-w-[52px] min-h-[52px] rounded-full relative ">
  //           <img src={categoryForm.image || Spicy} alt="category" className="w-full h-full object-cover" />
  //           <div className="flex items-center justify-center bg-[rgba(9,9,9,0.71)] absolute rounded-full left-0 top-0 w-full h-full">
  //               <UploadIcon className="w-[18px] h-[18px]" />
  //           </div>
  //       </div>
  //       <TextInput
  //           value={categoryForm.name}
  //           name="name"
  //           onChange={handleChange}
  //           className="!p-4"
  //       />
  //     </div>
  //     <Button variant="icon" className="!p-0 text-base text-[#0EDB9E]" onClick={handleEdit}>
  //       Save
  //     </Button>
  //   </div>
  //   )
  //   }

  return (
    <div className="flex justify-between items-center p-6 bg-white rounded-[16px]">
      <div className="gap-3.5 flex items-center">
        <div className="w-[52px] h-[52px] rounded-full">
          <img
            src={category.category_image || Spicy}
            alt="category"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col">
          <span className="text-lg text-black1 font-bold">
            {category?.category_name}
          </span>
          <span className="text-[#787C86] text-base">{category?.text}</span>
        </div>
      </div>
      <div className="flex gap-10 flex-wrap items-center">
        <div className="flex flex-col gap-2">
          <span className="text-[#8A8A8A] text-sm">Created on</span>
          <span className="text-[13px] text-[#202020]">
            {moment.utc(category.created_on).local().format("MMM DD,YYYY")}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-[#8A8A8A] text-sm">Publish on</span>
          <span className="text-[13px] text-[#202020]">
            {category.publish_on
              ? moment.utc(category.publish_on).local().format("MMM DD,YYYY")
              : "N/A"}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-[#8A8A8A] text-sm">Expires on</span>
          <span className="text-[13px] text-[#202020]">
            {category.publish_on
              ? moment.utc(category.expire_on).local().format("MMM DD,YYYY")
              : "N/A"}
          </span>
        </div>
        <Button variant="icon" className="!p-0" onClick={handleEdit}>
          <EditIcon className="w-5 h-5" />
        </Button>
      </div>
      <PlusPackForm
        open={!!categoryForm}
        onClose={() => {
          refetch();
          setCategoryForm(null);
        }}
        pack={categoryForm}
      />
    </div>
  );
}
