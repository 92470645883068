import { Link } from "react-router-dom";
import { Button } from "../../components/Button";

export function Amplitude() {
  return (
    <div className="bg-background h-[100vh] p-7 pt-6">
      <div className="mb-4">
        <h1 className="text-black text-[32px] font-bold">
          Amplitude Analytics
        </h1>
      </div>
      <div className="[&_div]:!border-none overflow-hidden">
        <iframe
          src="https://app.amplitude.com/analytics/share/4ecb07da394146c084b5f8cd66eade76/embed"
          width="100%"
          height="500"
          title="Amplitude"
        />
        <div className="mt-4">
          <Link to="https://app.amplitude.com/analytics/primal/chart/llo03mko?source=copy+url">
            <Button>Go to the amplitude</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
