import { Button } from "../../components/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-2.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as More } from "../../assets/icons/More.svg";
import { ReactComponent as BoostIcon } from "../../assets/icons/boost.svg";
import { ReactComponent as UnBoostIcon } from "../../assets/icons/unboost.svg";

import Spicy from "../../assets/icons/spicy.svg";

import React, { useEffect, useRef, useState } from "react";
import { PlusPackView } from "./PlusPackView";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PlusPackForm } from "./PlusPackForm";
import usePlusPacks from "./usePlusPacks";
import { DeleteCategoryConfirm } from "./components/DeleteCategoryConfirm";
import { api } from "../../services/api";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { EmptyTableView } from "../../components/EmptyTableView";
import { ShowToast } from "../../components/Toast";
import { BoostCategory } from "./components/BoostCategory";
import UIPopover from "../../components/Popover/Popover";
import { BoostViewButton } from "../questions/components/BoostViewButton";
import { BoostedPlusPacks } from "./BoostedPlusPacks";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr className="hover:bg-gray-100 [&_td]:py-3 border-b" key={i}>
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-300 animate-pulse transition duration-50"></div>
              <div className="flex flex-col">
                <div className="flex items-center gap-[2px]">
                  <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className="flex justify-center">
              <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50 mr-5"></div>
            </div>
          </td>
          <td className="subscription">
            <div className="flex justify-center">
              <div className="w-10 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
            </div>
          </td>
          <td className="account-manager">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-executive">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="mrr">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
        </tr>
      ))}
    </>
  );
}

export function PlusPacks({
  showPlusPackForm,
  onPlusPackFormClose,
  onCategoryClick,
}: {
  showPlusPackForm: boolean;
  onPlusPackFormClose: () => void;
  onCategoryClick: (category: any) => void;
}) {
  const navigate = useNavigate();
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    categories,
    total,
    loadMore,
  } = usePlusPacks({ is_boosted: false });
  const [categoryForm, setCategoryForm] = useState<any>(null);
  const [boostedTotal, setBoostedTotal] = useState<number>(0);
  const [deleting, setDeleting] = React.useState(false);
  const [removeCategoryModal, setRemoveCategoryModal] = useState<any>(null);
  const [packView, setPackView] = React.useState<any>(null);
  const [showBoostedCategoryView, setShowBoostedCategoryView] =
    React.useState<boolean>(false);
  const [boostCategoryForm, setBoostCategoryForm] = React.useState<any>(null);

  const onSortChange = (newFilters) => {
    // setFilters({ ...filters, ...newFilters });
    updateFilters(newFilters);
  };

  const handleBoostCategory = (category) => {
    setBoostCategoryForm(category);
  };

  const closeBoostCategoryForm = () => {
    setBoostCategoryForm(null);
  };

  const handleRemoveCategoryConfirm = () => {
    setDeleting(true);
    // delete category
    api
      .deleteCategory(removeCategoryModal?.question_category_id)
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data?.status) {
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            refetch(removeCategoryModal?.question_category_id, true);
            setRemoveCategoryModal(null);
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      })
      .catch((e) => {
        ShowToast({
          type: "error",
          message: "Network request failed",
        });
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const handleUnBoostCategory = (category) => {
    api
      .boostCategoryOrQuestion({
        category_id: category?.question_category_id,
        is_boost: 0,
        boost_until: "",
      })
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data?.status) {
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            refetch(undefined, undefined, true);
            fetchBoostedCategoryTotal();
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      })
      .catch((e) => {
        ShowToast({
          type: "error",
          message: "Network request failed",
        });
      });
  };

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();
  const handleSort = (key) => {
    if (key === filters.sort_by) {
      onSortChange({
        sort_by: filters.sort_order === "desc" ? "" : key,
        sort_order:
          filters.sort_order === "asc"
            ? "desc"
            : filters.sort_order === "desc"
            ? ""
            : "asc",
      });
    } else {
      onSortChange({ sort_order: "asc", sort_by: key });
    }
  };

  const handleBoostFilter = () => {
    updateFilters({ is_boosted: true });
  };

  const handlePageChange = (page: number) => {
    updateFilters({ page });
  };

  const handleBoostedCategory = () => {
    setShowBoostedCategoryView(true);
  };

  const closeBoostedCategoryView = () => {
    setShowBoostedCategoryView(false);
  };

  const fetchBoostedCategoryTotal = () => {
    api
      .getCategories({ is_boosted: true, page: 1, limit: 10 })
      .then((response) => {
        setBoostedTotal(response?.total);
      });
  };

  useEffect(() => {
    fetchBoostedCategoryTotal();
  }, []);

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          categories &&
          total &&
          categories.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, categories, total, isLoading]);

  useEffect(() => {
    if (packView) {
      const newPack = categories?.find(
        (category) =>
          category.question_category_id === packView.question_category_id
      );
      if (newPack) {
        setPackView(newPack);
      }
    }
  }, [categories, packView]);

  return (
    <>
      <LoadAnimatedContainer>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
              </div>
            </div>
            <BoostViewButton onClick={handleBoostFilter} count={boostedTotal} />
          </div>
          {filters.is_boosted && (
            <div className="flex flex-wrap items-center gap-2 mt-3">
              {filters.is_boosted && (
                <div className="flex items-center py-2 px-4 gap-1 bg-[#EEF1EF] rounded-[50px]">
                  <span className="text-[#33363F] text-sm">Boosted</span>
                  <button onClick={() => updateFilters({ is_boosted: false })}>
                    <CloseIcon className="w-[14px] h-[14px] [&>path]:stroke-2" />
                  </button>
                </div>
              )}
            </div>
          )}

          <div className="mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "34%" }} />
                <col style={{ width: "8%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "12%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td className="rounded-l pl-5">
                    <div className="flex items-center">
                      Pack name
                      {/* {filters.sort_by === "client_name" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "asc"
                              ? "rotate-[270deg]"
                              : "rotate-[90deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>
                  <td className="text-center">Boosted</td>
                  <td>
                    <div className="flex items-center">
                      <span className="flex-1">No. of questions</span>
                      {filters.sort_by === "total_active_subscription" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "asc"
                              ? "rotate-[270deg]"
                              : "rotate-[90deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">
                      {filters.is_boosted ? "Boosted on" : "Created on"}
                      {/* {filters.sort_by === "account_cs_manager" ? (
                    <ArrowRight
                      stroke="#2A2B2F"
                      className={`sort-icon ${
                        filters.sort_order === "asc"
                          ? "rotate-[270deg]"
                          : "rotate-[90deg]"
                      }`}
                    />
                  ) : null} */}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">
                      {filters.is_boosted ? "Unboosting on" : "Publish on"}
                      {/* {filters.sort_by === "manager_name" ? (
                    <ArrowRight
                      stroke="#2A2B2F"
                      className={`sort-icon ${
                        filters.sort_order === "asc"
                          ? "rotate-[270deg]"
                          : "rotate-[90deg]"
                      }`}
                    />
                  ) : null} */}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">
                      Expires on
                      {/* {filters.sort_by === "manager_name" ? (
                    <ArrowRight
                      stroke="#2A2B2F"
                      className={`sort-icon ${
                        filters.sort_order === "asc"
                          ? "rotate-[270deg]"
                          : "rotate-[90deg]"
                      }`}
                    />
                  ) : null} */}
                    </div>
                  </td>

                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !categories?.length && (
                  <EmptyTableView
                    cols={6}
                    message="No plus pack found!"
                    className="h-[calc(100vh-300px)]"
                  />
                )}
                {categories?.map((category) => (
                  <tr
                    key={category.question_category_id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => onCategoryClick(category)}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-3">
                        <img
                          src={category.category_image || Spicy}
                          className="w-10 h-10 object-contain"
                          alt="pack"
                        />
                        <div className="flex flex-col">
                          <div className="flex items-center gap-[2px]">
                            <h5 className="flex-1">
                              {category?.category_name}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center">
                        {!!category?.is_boosted ? <BoostIcon /> : ""}
                      </div>
                    </td>
                    <td className="text-center">{category.questions}</td>
                    <td className="account-manager">
                      {filters.is_boosted
                        ? moment
                            .utc(category.boosted_on)
                            .local()
                            .format("MMM DD,YYYY")
                        : moment
                            .utc(category.date_created)
                            .local()
                            .format("MMM DD,YYYY")}
                    </td>
                    <td className="account-executive">
                      {filters.is_boosted
                        ? category.boost_until
                          ? moment
                              .utc(category.boosted_on)
                              .local()
                              .format("MMM DD,YYYY")
                          : "N/A"
                        : category.publish_on
                        ? moment
                            .utc(category.publish_on)
                            .local()
                            .format("MMM DD,YYYY")
                        : "N/A"}
                    </td>
                    <td className="mrr">
                      {category.expire_on
                        ? moment
                            .utc(category.expire_on)
                            .local()
                            .format("MMM DD,YYYY")
                        : "N/A"}
                    </td>
                    <td onClick={stopPropagate} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                        positions={["bottom", "top", "left", "right"]}
                      >
                        {(close) => (
                          <Dropdown
                            removeUser={() => {
                              close();
                              setRemoveCategoryModal(category);
                            }}
                            onBoost={() => {
                              close();
                              handleBoostCategory(category);
                            }}
                            onEdit={() => {
                              close();
                              setCategoryForm(category);
                            }}
                            onUnBoost={() => {
                              close();
                              handleUnBoostCategory(category);
                            }}
                            isBoostedFilter={filters.is_boosted}
                            isCategoryBoosted={!!category.is_boosted}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {isLoading && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      {!!packView && (
        <PlusPackView
          open={!!packView}
          onClose={() => setPackView(null)}
          pack={packView}
          refetch={refetch}
        />
      )}

      <DeleteCategoryConfirm
        open={!!removeCategoryModal}
        onClose={() => {
          setRemoveCategoryModal(null);
        }}
        onConfirm={handleRemoveCategoryConfirm}
        title={`Are you sure you want to delete the category ‘${removeCategoryModal?.category_name}’?`}
        loading={deleting}
      />
      {!!boostCategoryForm && (
        <BoostCategory
          open={!!boostCategoryForm}
          category={boostCategoryForm}
          onClose={closeBoostCategoryForm}
          refresh={() => {
            refetch(undefined, undefined, true);
            fetchBoostedCategoryTotal();
          }}
        />
      )}
      {showBoostedCategoryView && (
        <BoostedPlusPacks
          open={showBoostedCategoryView}
          onClose={closeBoostedCategoryView}
          refresh={() => {
            fetchBoostedCategoryTotal();
            refetch(undefined, undefined, true);
          }}
        />
      )}
      {showPlusPackForm && (
        <PlusPackForm
          open={showPlusPackForm}
          onClose={(refresh) => {
            refresh && refetch();
            onPlusPackFormClose();
          }}
        />
      )}
      {!!categoryForm && (
        <PlusPackForm
          open={!!categoryForm}
          onClose={(refresh) => {
            refresh && refetch(undefined, undefined, true);
            setCategoryForm(null);
          }}
          pack={categoryForm}
        />
      )}
    </>
  );
}

const Dropdown = ({
  removeUser,
  onEdit,
  onBoost,
  onUnBoost,
  isBoostedFilter,
  isCategoryBoosted,
}) => {
  return (
    <div
      className={`z-10 ${
        isBoostedFilter ? "w-48" : "w-48"
      } bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8`}
    >
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        {isCategoryBoosted ? (
          <li>
            <Button
              variant="icon"
              className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
              onClick={onUnBoost}
              scaleAnimated={false}
            >
              <UnBoostIcon />
              Unboost Category
            </Button>
          </li>
        ) : null}
        {!isBoostedFilter && (
          <li>
            <Button
              variant="icon"
              className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
              onClick={onBoost}
              scaleAnimated={false}
            >
              <BoostIcon />
              {isCategoryBoosted ? "Reboost" : "Boost"}
            </Button>
          </li>
        )}
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onEdit}
            scaleAnimated={false}
          >
            <EditIcon />
            Edit
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-gray-100 text-[#F54343] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            <TrashIcon />
            Delete
          </Button>
        </li>
      </ul>
    </div>
  );
};
