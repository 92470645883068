import React, { useEffect, useState } from "react";
import { FullModal } from "../../../components/Modals/FullModal";
import { Button } from "../../../components/Button";
import { ReactComponent as BanIcon } from "../../../assets/icons/slash.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-2.svg";
import { UserInfoCard } from "./component/UserInfoCard";
import { api } from "../../../services/api";
import { RevealTable } from "./component/RevealTable";
import moment from "moment";
import { EditUserForm } from "./component/EditUserForm";
import { UserDeleteConfirm } from "../components/UserDeleteConfirm";
import { UserBlockConfirm } from "../components/UserBlockConfirm";
import { ShowToast } from "../../../components/Toast";
import { RevealTableWrapper } from "./component/RevealTableWrapper";
import { ContactsAnalyticsTableWrapper } from "./component/ContactsAnalyticsTableWrapper";

const tabs = [
  {
    name: "reveals",
    label: "Reveals",
  },
  {
    name: "contacts",
    label: "Contacts",
  },
];

export function UserView({
  open,
  onClose,
  user,
  refetch,
}: {
  open: boolean;
  onClose: () => void;
  user: any;
  refetch: (refetchId?: string, isDelete?: boolean) => void;
}) {
  const [activeTab, setActiveTab] = React.useState<string>("reveals");
  const [sentReveals, setSentReveals] = React.useState<any>([]);
  const [revealCounts, setRevealCounts] = React.useState<any>({});
  const [receivedReveals, setReceivedReveals] = React.useState<any>([]);
  const [revealLoading, setRevealLoading] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<"sent" | "received">(
    "sent"
  );
  const [deleting, setDeleting] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState<any>(null);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [userInfo, setUserInfo] = React.useState<any>({});
  const [userForm, setUserForm] = React.useState<any>(null);
  const handleEdit = () => {
    setUserForm({
      ...userInfo,
    });
  };

  const handleBlock = () => {
    setDeactivateUser(userInfo);
  };

  const fetchUserInfo = async (userId: string) => {
    const response = await api.getUserInfo(userId);
    setUserInfo(response?.data || {});
  };

  const fetchSentReveals = async (userId: string) => {
    setRevealLoading(true);
    const response = await api.getReveals({ id: userId, type: "sent" });
    setRevealLoading(false);
    setRevealCounts({
      receivedCount: response?.data?.receivedCount,
      sentCount: response?.data?.sentCount,
    });
    setSentReveals(response?.data?.data || []);
  };

  const fetchReceivedReveals = async (userId: string) => {
    setRevealLoading(true);
    const response = await api.getReveals({ id: userId, type: "received" });
    setRevealLoading(false);
    setRevealCounts({
      receivedCount: response?.data?.receivedCount,
      sentCount: response?.data?.sentCount,
    });
    setReceivedReveals(response?.data?.data || []);
  };

  useEffect(() => {
    if (user && open) {
      fetchUserInfo(user.user_id);
      if (selectedTab === "sent") {
        fetchSentReveals(user.user_id);
      } else {
        fetchReceivedReveals(user.user_id);
      }
    }
  }, [user, open]);

  const handleDeleteUser = () => {
    setRemoveUserModal(userInfo);
  };

  const handleDeactivateUserConfirm = async () => {
    try {
      // api call
      const user = deactivateUser;
      setBlocking(true);
      const response = await api.blockUser({ user_id: user.user_id });
      setBlocking(false);
      if (response.status === 200) {
        if (response?.data?.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(user.user_id, true);
          setDeactivateUser(null);
          onClose();
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleRemoveUserConfirm = async () => {
    try {
      // api call
      const user = removeUserModal;
      setDeleting(true);
      const response = await api.deleteUser({ user_id: user.user_id });
      setDeleting(false);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(user.user_id, true);
          setRemoveUserModal(null);
          onClose();
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleSelectedTab = (tab: "sent" | "received") => {
    setSelectedTab(tab);
    if (tab === "sent") {
      fetchSentReveals(user.user_id);
    } else {
      fetchReceivedReveals(user.user_id);
    }
  };

  if (!open) return null;

  return (
    <FullModal open={open} onClose={onClose}>
      <div className="w-[900px] mx-auto flex flex-col">
        <div>
          <div className="flex items-center justify-between mb-5">
            <div className="flex items-center gap-2">
              <span className="text-[#777777] text-sm">Last Activity:</span>
              <span className="text-[#202020] text-sm">
                {userInfo?.last_activity_date
                  ? moment
                      .utc(userInfo.last_activity_date)
                      .local()
                      .format("MMM DD,YYYY | hh:mm A")
                  : "-"}
              </span>
            </div>
            <div className="flex justify-end gap-6">
              <Button
                variant="icon"
                className="!p-0 text-black hover:text-[#00C88C]"
                onClick={handleEdit}
              >
                <EditIcon />
                Edit profile
              </Button>
              <Button
                variant="icon"
                className="!p-0 text-black hover:text-[#00C88C]"
                onClick={handleBlock}
              >
                <BanIcon />
                Block user
              </Button>
              <Button
                variant="icon"
                className="!p-0 text-black hover:text-[#F54343]"
                onClick={handleDeleteUser}
              >
                <TrashIcon />
                Remove User
              </Button>
            </div>
          </div>
          <UserInfoCard
            user={{ ...userInfo, is_promo_code_used: user.is_promo_code_used }}
          />
        </div>

        <div className="flex items-center gap-6 my-4">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={`${
                activeTab === tab.name
                  ? "border-[#18DEA3] text-[#33363F]"
                  : "border-transparent text-[#33363F]/50 hover:text-[#33363F]/60"
              } text-xl font-bold border-b-[2px] transition duration-500 ease-in-out focus:outline-none`}
            >
              {tab.label}
            </button>
          ))}
        </div>
        {activeTab === "reveals" && (
          <RevealTableWrapper open={open} user={user} />
        )}
        {activeTab === "contacts" && (
          <ContactsAnalyticsTableWrapper open={open} user={user} />
        )}
      </div>
      <EditUserForm
        open={!!userForm}
        onClose={() => setUserForm(null)}
        user={userForm}
        refetch={refetch}
      />

      <UserDeleteConfirm
        title={`Are you sure you want to remove ‘${removeUserModal?.first_name} ${removeUserModal?.last_name}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      <UserBlockConfirm
        title={`Are you sure you want to block ‘${deactivateUser?.first_name} ${deactivateUser?.last_name}’?`}
        open={!!deactivateUser}
        onClose={() => setDeactivateUser(null)}
        onConfirm={handleDeactivateUserConfirm}
        loading={blocking}
      />
    </FullModal>
  );
}
