import { Modal } from "../../../../components/Modals/Modal";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload.svg";
import { TextInput } from "../../../../components/TextInput";
import { useEffect, useState } from "react";
import { Button } from "../../../../components/Button";
import { UploadCsv } from "../../../../components/Uploads/UploadCsv";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import { UploadPicture } from "../../../../components/Uploads/UploadPicture";
import { api } from "../../../../services/api";
import { fileUpload } from "../../../../services/file-upload";
import { Loading } from "../../../../components/Loading/Loading";

import "react-phone-number-input/style.css";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { ShowToast } from "../../../../components/Toast";
import ImageKit from "imagekit-javascript";
import { IMAGEKIT_ENDPOINT, imagekit_key } from "../../../../env";

export const getImage = (image: string | undefined, file: File | null) => {
  if (file) {
    return URL.createObjectURL(file);
  }
  if (image) {
    return image;
  }
};

export function EditUserForm({
  open,
  onClose,
  user,
  refetch,
}: {
  open: boolean;
  onClose: (refetch?: boolean) => void;
  user?: any;
  refetch: any;
}) {
  const [saving, setSaving] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [userForm, setUserForm] = useState<any>({
    reveal_to: "",
    text: "",
  });

  const handleChange = (e: any) => {
    setUserForm({
      ...userForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleScheduleCheck = (e: any) => {
    setUserForm({
      ...userForm,
      [e.target.name]: e.target.checked,
    });
  };

  const handleDateChange = (name: string, value: any) => {
    setUserForm({
      ...userForm,
      [name]: value,
    });
  };

  const authenticateImageKit = async () => {
    try {
      const authResponse = await api.getImageAuth();
      console.log(authResponse);
      return authResponse;
    } catch (e: any) {
      console.log(e);
    }
  };

  const updateUser = async (payload) => {
    try {
      // update
      const response = await api.updateUser(payload);
      setSaving(false);

      if (response.status === 200) {
        if (response?.data?.status) {
          refetch();
          onClose(true);
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Failed to save",
      });
    }
  };

  const handleSubmit = async () => {
    if (!userForm.first_name || !userForm.user_age) {
      return;
    }
    let payload: any = {
      user_id: userForm.user_id,
      first_name: userForm.first_name,
      last_name: userForm.last_name,
      age: userForm.user_age,
      // u_phone_number: userForm.u_phone_number,
    };
    try {
      setSaving(true);
      // upload file
      if (file) {
        const imageResponse = await fileUpload(file, "images");
        if (imageResponse.response.status === 200 && imageResponse?.url) {
          payload.user_image = imageResponse?.url;
        }
        await updateUser(payload);
      } else {
        await updateUser(payload);
      }
      // if (file) {
      //   const imagekit = new ImageKit({
      //     publicKey: imagekit_key,
      //     urlEndpoint: IMAGEKIT_ENDPOINT,
      //   });
      //   const authResponse = await authenticateImageKit();
      //   if (authResponse?.uploadURL) {
      //     const authData = authResponse.uploadURL;
      //     imagekit.upload(
      //       {
      //         file: file,
      //         fileName: file.name,
      //         token: authData.token,
      //         signature: authData.signature,
      //         expire: authData.expire,
      //       },
      //       async function (err, result) {
      //         console.log(result);
      //         payload.user_image = result?.url;
      //         await updateUser(payload);
      //       }
      //     );
      //   }
      // }
    } catch (e) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: "Failed to save",
      });
    }
  };

  useEffect(() => {
    if (open) {
      setUserForm({ ...user });
    }
  }, [open, user]);

  const isEdit = !!user?.user_id;

  if (!open) return null;

  const isValidate = () => {
    return (
      userForm.first_name &&
      userForm.user_age &&
      // userForm.u_phone_number &&
      Number(userForm.user_age) > 0
    );
  };
  return (
    <Modal
      title={`${isEdit ? "Edit" : "Add"} profile`}
      open={open}
      onClose={onClose}
      contentClassName="[&_h1]:text-lg [&_.close]:w-5 [&_.close]:h-5"
    >
      <div className="flex flex-col gap-5 max-h-[75vh] overflow-auto">
        <UploadPicture
          onChange={(file) => setFile(file)}
          image={getImage(user.user_image, file)}
        />
        <div className="flex items-center gap-4">
          <div className="flex flex-col gap-1.5 flex-1">
            <label className="text-sm opacity-80">First name</label>
            <TextInput
              name="first_name"
              value={userForm.first_name}
              onChange={handleChange}
              placeholder="Enter first name"
            />
          </div>
          <div className="flex flex-col gap-1.5 flex-1">
            <label className="text-sm opacity-80">Last name</label>
            <TextInput
              name="last_name"
              value={userForm.last_name}
              onChange={handleChange}
              placeholder="Enter last name"
            />
          </div>
        </div>
        <div className="flex flex-col gap-1.5">
          <label className="text-sm opacity-80">Phone number</label>
          {/* <PhoneInput
            placeholder="Enter phone number"
            countryCallingCodeEditable={false}
            className="p-[18px] border border-[#D9D9E7]/80 rounded-lg [&_input]:outline-none"
            value={`${userForm.country_code}${userForm.u_phone_number}`}
            onChange={(value) => {
              if (value) {
                const parsed = parsePhoneNumber(value as string);
                setUserForm((form) => ({
                  ...form,
                  u_phone_number: parsed?.nationalNumber,
                  country_code: `+${parsed?.countryCallingCode}`,
                }));
              }
            }}
          /> */}
          <TextInput
            name="u_phone_number"
            value={`${userForm.country_code} ${userForm.u_phone_number}`}
            onChange={handleChange}
            placeholder="Enter phone number"
            readOnly
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <label className="text-sm opacity-80">Age</label>
          <TextInput
            name="user_age"
            value={userForm.user_age}
            onChange={handleChange}
            placeholder="Enter age"
            type="number"
          />
        </div>
        <div className="flex justify-end p-2">
          <Button
            className="!rounded-full !px-10 !py-4 !text-base !font-bold"
            onClick={handleSubmit}
            disabled={saving || !isValidate()}
          >
            {isEdit ? "Save" : "Add"}
            {saving && <Loading />}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
