import moment from "moment";
import { Button } from "../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { api } from "../../services/api";
import toast from "react-hot-toast";
import { DeleteQuestionConfirm } from "./components/DeleteQuestionConfirm";
import useQuetions from "./useQuestions";
import { ShowToast } from "../../components/Toast";
import { EmptyTableView } from "../../components/EmptyTableView";
import { FullModal } from "../../components/Modals/FullModal";
import { ReactComponent as More } from "../../assets/icons/More.svg";
import { ReactComponent as BoostIcon } from "../../assets/icons/boost.svg";
import { ReactComponent as UnBoostIcon } from "../../assets/icons/unboost.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit-2.svg";
import UIPopover from "../../components/Popover/Popover";
import { EditQuestionForm } from "./components/EditQuestionForm";
import { BoostedCategoryTooltip } from "./components/BoostedCategoryTooltip";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                  <div className="w-4 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
                <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function BoostedQuestions({ open, onClose, refresh }) {
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    questions,
    total,
    loadMore,
  } = useQuetions({ is_boosted: true });
  const [showEditQuestionForm, setShowEditQuestionForm] = useState<any>(false);
  const [deleting, setDeleting] = useState(false);
  const [removeQuestionModal, setRemoveQuestionModal] = useState<any>(null);
  const [blockedContent, setBlockedContent] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  const onSortChange = (newFilters) => {
    // setFilters({ ...filters, ...newFilters });
    updateFilters(newFilters);
  };

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      onSortChange({
        sort_by: filters.sort_order === "desc" ? "" : key,
        sort_order:
          filters.sort_order === "asc"
            ? "desc"
            : filters.sort_order === "desc"
            ? ""
            : "asc",
      });
    } else {
      onSortChange({ sort_order: "asc", sort_by: key });
    }
  };
  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleUnBoostCategory = async (question) => {
    toast.loading("Unboosting category...");
    api
      .boostCategoryOrQuestion({
        category_id: question?._question_category_id,
        is_boost: 0,
        boost_until: "",
      })
      .then((response) => {
        toast.dismiss();
        if (response?.status === 200) {
          if (response?.data?.status) {
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            refetch(undefined, undefined, true);
            refresh();
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      })
      .catch((e) => {
        toast.dismiss();
        ShowToast({
          type: "error",
          message: "Network request failed",
        });
      });
  };
  const handleUnBoostQuestion = async (question) => {
    toast.loading("Unboosting question...");
    api
      .boostCategoryOrQuestion({
        question_id: question?.question_id,
        is_boost: 0,
        boost_until: "",
      })
      .then((response) => {
        toast.dismiss();
        if (response?.status === 200) {
          if (response?.data?.status) {
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            refetch(question?.question_id, true);
            refresh();
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      })
      .catch((e) => {
        toast.dismiss();
        ShowToast({
          type: "error",
          message: "Network request failed",
        });
      });
  };
  const handleDeleteQuestion = (user) => {
    setRemoveQuestionModal(user);
  };
  const handleActivateUserConfirm = async (user) => {
    try {
      // api call
      toast.loading("Unblocking user...");
      const response = await api.blockUser({ user_id: user.user_id });
      if (response.status === 200) {
        if (response?.data?.status) {
          toast.dismiss();
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch();
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleRemoveUserConfirm = async () => {
    // api call
    setDeleting(true);
    const response = await api.deleteQuestion(removeQuestionModal.question_id);
    setDeleting(false);
    if (response.status === 200) {
      if (response.data.status) {
        ShowToast({
          type: "success",
          message: response?.data?.message,
        });
        refetch(user.user_id, true);
        setRemoveQuestionModal(null);
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } else {
      ShowToast({
        type: "error",
        message: response?.data?.message || "Something went wrong",
      });
    }
  };

  const handleReportedContent = (item) => {
    setBlockedContent(item);
  };

  const closeReportedContent = () => {
    setBlockedContent(null);
  };

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // TODO: temporary adding -1 to total due to backend issue
        if (
          entries[0].isIntersecting &&
          questions &&
          total &&
          questions.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, questions, total, isLoading]);

  return (
    <FullModal open={open} onClose={onClose}>
      <div className="w-[1100px] mx-auto">
        <div className="mb-6 mt-1 flex items-center justify-between flex-wrap gap-4">
          <h1 className="text-black text-[32px] font-bold flex items-center gap-1.5">
            Boosted Questions{" "}
            <div className="text-lg text-black font-normal mt-1.5">
              ({total})
            </div>
            <BoostIcon className="mt-2" />
          </h1>
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5">
          <div className="flex items-center justify-between pl-2">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="mt-5 text-[14px] h-[calc(100vh-250px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "38%" }} />
                <col style={{ width: "16%" }} />
                <col style={{ width: "14%" }} />
                <col style={{ width: "14%" }} />
                <col style={{ width: "9%" }} />
                <col style={{ width: "9%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td
                    onClick={() => handleSort("name")}
                    className="rounded-l pl-5"
                  >
                    <div className="cursor-pointer flex items-center">
                      Question
                      {filters.sort_by === "name" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "asc"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">Category</div>
                  </td>
                  <td>
                    <div className="flex items-center">Boosted on</div>
                  </td>
                  <td>
                    <div className="flex items-center">Unboosting on</div>
                  </td>
                  <td>
                    <div className="flex items-center">
                      Used
                      {/* {filters.sort_by === "manager_name" ? (
                    <ArrowRight
                      stroke="#2A2B2F"
                      className={`sort-icon ${
                        filters.sort_order === "asc"
                          ? "rotate-[270deg]"
                          : "rotate-[90deg]"
                      }`}
                    />
                  ) : null} */}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">
                      Skipped
                      {/* {filters.sort_by === "manager_name" ? (
                    <ArrowRight
                      stroke="#2A2B2F"
                      className={`sort-icon ${
                        filters.sort_order === "asc"
                          ? "rotate-[270deg]"
                          : "rotate-[90deg]"
                      }`}
                    />
                  ) : null} */}
                    </div>
                  </td>
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !questions?.length && (
                  <EmptyTableView
                    cols={5}
                    message="No blocked user found!"
                    className="h-[calc(100vh-300px)]"
                  />
                )}
                {questions?.map((aclient, i) => (
                  <tr
                    key={aclient.user_id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setUser(aclient)}
                  >
                    <td className="name pl-5">{aclient?.question_text}</td>
                    <td className="subscription">
                      <div className="flex items-center gap-2">
                        <div className="w-[18px] h-[18px]">
                          <img
                            alt="category"
                            src={aclient.category_image}
                            className="w-full h-full object-contain"
                          />
                        </div>
                        {aclient.category_name}
                        {!!aclient?.is_category_boosted && (
                          <BoostedCategoryTooltip
                            categoryName={aclient.category_name}
                          />
                        )}
                      </div>
                    </td>
                    <td className="account-manager">
                      {aclient.boosted_on
                        ? moment
                            .utc(aclient.boosted_on)
                            .local()
                            .format("MMM DD,YYYY")
                        : "N/A"}
                    </td>
                    <td className="account-manager">
                      {aclient.boosted_until
                        ? moment
                            .utc(aclient.boosted_until)
                            .local()
                            .format("MMM DD,YYYY")
                        : "N/A"}
                    </td>

                    <td className="account-executive">
                      {aclient.answered_count} time
                      {aclient.answered_count === 1 ? "" : "s"}
                    </td>
                    <td className="mrr">
                      {aclient.skipped_count} time
                      {aclient.skipped_count === 1 ? "" : "s"}
                    </td>
                    <td onClick={stopPropagate} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                      >
                        {(close) => (
                          <Dropdown
                            isCategoryBoosted={!!aclient.is_category_boosted}
                            removeUser={() => {
                              close();
                              handleDeleteQuestion(aclient);
                            }}
                            onBoost={() => {
                              close();
                              handleUnBoostQuestion(aclient);
                            }}
                            onUnboostCategory={() => {
                              close();
                              handleUnBoostCategory(aclient);
                            }}
                            onEdit={() => {
                              close();
                              setShowEditQuestionForm(aclient);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {(isLoading || isFetching) && <LoadingSkeleton />}

                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DeleteQuestionConfirm
        question={removeQuestionModal?.question_text}
        open={!!removeQuestionModal}
        onClose={() => setRemoveQuestionModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      {!!showEditQuestionForm && (
        <EditQuestionForm
          open={!!showEditQuestionForm}
          onClose={(refresh) => {
            if (refresh === true) {
              refetch(undefined, undefined, true);
            }
            setShowEditQuestionForm(false);
          }}
          question={showEditQuestionForm}
        />
      )}
    </FullModal>
  );
}

const Dropdown = ({
  removeUser,
  onEdit,
  onBoost,
  onUnboostCategory,
  isCategoryBoosted,
}) => {
  return (
    <div className="z-10 w-60 bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onBoost}
            scaleAnimated={false}
          >
            <UnBoostIcon />
            Unboost
          </Button>
        </li>
        {isCategoryBoosted && (
          <li>
            <Button
              variant="icon"
              className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
              onClick={onUnboostCategory}
              scaleAnimated={false}
            >
              <UnBoostIcon />
              Unboost whole category
            </Button>
          </li>
        )}
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onEdit}
            scaleAnimated={false}
          >
            <EditIcon />
            Edit
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-gray-100 text-[#F54343] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            <TrashIcon />
            Delete
          </Button>
        </li>
      </ul>
    </div>
  );
};
