export function SelectInput({
  data,
  ...props
}: React.InputHTMLAttributes<HTMLSelectElement> & { data?: string[] | any[] }) {
  return (
    <div className="w-full">
      <select
        {...props}
        className={`!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus:border-grey2 p-4 text-base ${
          props.value ? "text-black" : "text-dark3"
        } ${props.className || ""}`}
        required
      >
        <option className="text-dark3" value={undefined} selected>
          {props.placeholder}
        </option>
        {data?.map((item, index) =>
          typeof item === "object" ? (
            <option key={index} value={item.value}>
              <div className="w-1 h-1 bg-[#EE826B]" />
              {item.label}
            </option>
          ) : (
            <option key={index} value={item}>
              {item}
            </option>
          )
        )}
      </select>
    </div>
  );
}
