import moment from "moment";
import { Modal } from "../../../components/Modals/Modal";
import { useEffect } from "react";
import { api } from "../../../services/api";
import { useQuery } from "@tanstack/react-query";

export function BlockedContentModal({ open, onClose, data, title }) {
  const { data: reveal, isLoading } = useQuery(
    ["reveal", data?._user_revealed_answer_id],
    () => api.getRevealDetails(data?._user_revealed_answer_id),
    {
      enabled: !!data?._user_revealed_answer_id,
    }
  );
  const revealData = reveal?.data;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      contentClassName="z-[1000] w-[390px]"
      modalClassName="[&_h1]:text-lg"
    >
      <div className="relative">
        <div>
          <div className="rounded-[20px] h-full opacity-40 absolute top-0 bottom-0 right-0 left-0 blur bg-[linear-gradient(125deg,#FF8C00_-2.31%,#FF0F7B_97.12%)]"></div>
          <div className="bg-[rgba(255,255,255,0.84)] px-4 py-4 rounded-[26px] shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] z-[10] relative">
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <div className="min-w-11 min-h-11 w-11 h-11 rounded-full">
                  <img
                    src={revealData?.shared_by?.user_image}
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
                <div className="flex-1">
                  <div className="text-black text-[17px] font-medium mr-1 inline">
                    <b>
                      {revealData?.shared_by?.first_name}{" "}
                      {revealData?.shared_by?.last_name}
                    </b>{" "}
                    revealed to{" "}
                    {revealData?.shared_with?.length > 0 && (
                      <>
                        <b>
                          {revealData?.shared_with[0]?.first_name}{" "}
                          {revealData?.shared_with[0]?.last_name}
                        </b>{" "}
                        {!!(revealData?.shared_with?.length - 1) && (
                          <span>
                            and {revealData?.shared_with?.length - 1} others
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <span className="text-[#9F9F9F] text-[13px] font-medium">
                    {moment(revealData?.reveal_date).fromNow()}
                  </span>
                </div>
              </div>
              <div className="text-black/50 py-5 text-2xl">
                {revealData?.question_text}
              </div>
              <div className="w-20 bg-black/50 h-[1px]" />
              <div className="text-black font-medium pt-5 pb-1 text-2xl">
                {revealData?.answer_text}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
