import { useEffect, useMemo, useState } from "react";
import { Modal } from "../../../components/Modals/Modal";
import { Button } from "../../../components/Button";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { api } from "../../../services/api";
import { ShowToast } from "../../../components/Toast";

export function BoostCategory({ category, open, onClose, refresh }) {
  const [form, setForm] = useState({
    boost_until: "",
    until_unboost_check: false,
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    const payload = {
      category_id: category.question_category_id,
      is_boost: 1,
      boost_until: form.boost_until || "",
    };
    try {
      setLoading(true);
      const response = await api.boostCategoryOrQuestion(payload);
      setLoading(false);
      if (response.status === 200) {
        onClose();
        refresh();
        ShowToast({
          type: "success",
          message: "Category boosted!",
        });
      } else {
        ShowToast({
          type: "error",
          message: "Failed to boost category",
        });
      }
    } catch (error) {
      setLoading(false);
      ShowToast({
        type: "error",
        message: "Failed to boost category",
      });
    }
  };

  useEffect(() => {
    if (open && category) {
      setForm({
        boost_until: category.boost_until,
        until_unboost_check: category.is_boosted && !category.boost_until,
      });
    }
  }, [open, category]);

  const disabled = () => {
    if (!!form.until_unboost_check || !!form.boost_until) {
      const isChecked = category.is_boosted && !category.boost_until;
      if (
        category.boost_until === form.boost_until &&
        isChecked === form.until_unboost_check
      ) {
        return true;
      }
      return false;
    }
    return true;
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Boost category"
      contentClassName="[&_h1]:text-lg [&_h1]:text-[#33363F] [&_.close]:w-5 [&_.close]:h-5 !max-w-[500px] !p-6"
    >
      <div className="flex flex-col gap-5 max-h-[70vh] overflow-auto">
        <div className="p-5 flex items-center gap-2.5 text-base text-[#202020] bg-[#F3F3F3] rounded-md">
          <div className="w-5 h-5">
            <img
              src={category.category_image}
              alt="categoey"
              className="w-full h-full object-contain"
            />
          </div>
          <span>{category.category_name}</span>
          <span>({category.questions} questions)</span>
        </div>
        <div className="flex flex-col gap-1.5">
          <label className="text-sm opacity-80">End of boost</label>
          <DatePicker
            className="category-question-date-picker"
            value={form.boost_until}
            minDate={new Date()}
            disabled={form.until_unboost_check}
            onChange={(date) => setForm({ ...form, boost_until: date })}
          />
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="isChecked"
            id="isChecked"
            checked={form.until_unboost_check}
            onChange={(e) =>
              setForm({
                ...form,
                until_unboost_check: e.target.checked,
                boost_until: "",
              })
            }
            className="w-[22px] h-[22px] accent-[#00C88C] cursor-pointer"
          />
          <label htmlFor="isChecked" className="ml-2 cursor-pointer">
            Until I unboost it
          </label>
        </div>
        <div className="flex justify-end">
          <Button
            className="!rounded-full !font-bold !py-4 !px-10"
            variant="primary"
            disabled={disabled() || loading}
            loading={loading}
            onClick={handleSubmit}
          >
            Boost
          </Button>
        </div>
      </div>
    </Modal>
  );
}
