import moment from "moment";
import { ReactComponent as Subtract } from "../../../../assets/icons/subtract.svg";
import { AudioView } from "./Audio";
import Tippy from "@tippyjs/react";
import { EmptyTableView } from "../../../../components/EmptyTableView";
import useReveal from "../useReveal";
import { useEffect, useRef } from "react";
import InstagramImage from "../../../../assets/social/instagram.png";
import SnapchatImage from "../../../../assets/social/snapchat.png";
import ImessageImage from "../../../../assets/social/imessage.png";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-4 border-b"
        >
          <td className="pl-5">
            <div className="w-48 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-36 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

function Contacts({
  list,
  id,
  isInstagramShared,
  isSnapshotShared,
  isImessageShared,
  socialTotal,
}) {
  return (
    <div className="flex flex-col gap-[14px]">
      {list?.length > 0 && (
        <div>
          <div className="text-[#202020]/40 text-xs font-normal mb-1.5">
            Contacts ({list?.length})
          </div>
          <div key={id} className="flex flex-col gap-2">
            {list?.map((item, index) => (
              <div key={index} className="flex items-center gap-2">
                <div className="w-[17px] h-[17px] rounded-full">
                  <img
                    src={item.user_image}
                    className="w-full h-full object-cover rounded-full"
                    alt=""
                  />
                </div>
                <span className="text-sm text-[#202020]">
                  {item.first_name} {item.last_name}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      {socialTotal > 0 && (
        <div>
          <div className="text-[#202020]/40 text-xs font-normal mb-1.5">
            Social ({socialTotal})
          </div>
          <div className="flex flex-col gap-2">
            {isInstagramShared && (
              <div className="flex items-center gap-2">
                <div className="w-[17px] h-[17px] rounded-full">
                  <img
                    src={InstagramImage}
                    className="w-full h-full object-cover rounded-full"
                    alt=""
                  />
                </div>
                <span className="text-sm text-[#202020]">Instagram</span>
              </div>
            )}
            {isSnapshotShared && (
              <div className="flex items-center gap-2">
                <div className="w-[17px] h-[17px] rounded-full">
                  <img
                    src={SnapchatImage}
                    className="w-full h-full object-cover rounded-full"
                    alt=""
                  />
                </div>
                <span className="text-sm text-[#202020]">Snap</span>
              </div>
            )}
            {isImessageShared && (
              <div className="flex items-center gap-2">
                <div className="w-[17px] h-[17px] rounded-full">
                  <img
                    src={ImessageImage}
                    className="w-full h-full object-cover rounded-full"
                    alt=""
                  />
                </div>
                <span className="text-sm text-[#202020]">iMessage</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export function RevealTable({ type, userId }) {
  const observerTarget = useRef(null);
  const { isLoading, reveals, total, loadMore } = useReveal({ type, userId });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          reveals &&
          reveals.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, reveals, total, isLoading]);

  return (
    <table className="w-full">
      <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
        <tr className="sticky top-0 z-[1]">
          <td className="rounded-l pl-5">
            Question <b>answer</b>
          </td>
          <td>
            <div className="flex items-center">
              {type === "sent" ? "Sent on" : "Received on"}
            </div>
          </td>
          <td>
            <div className="flex items-center">
              {type === "sent" ? "Sent to" : "Received from"}
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        {reveals?.length === 0 && !isLoading && (
          <EmptyTableView
            cols={3}
            message="No reveals found"
            className="pt-10"
          />
        )}
        {reveals?.map((item) => {
          const isInstagramShared = item.is_instagram_shared === 1;
          const isSnapshotShared = item.is_snapchat_shared === 1;
          const isImessageShared = item.is_message_shared === 1;
          const socialTotal =
            (isInstagramShared ? 1 : 0) +
            (isSnapshotShared ? 1 : 0) +
            (isImessageShared ? 1 : 0);
          const total =
            (type === "sent" ? item?.sent_list?.length : 1) + socialTotal;
          return (
            <tr
              key={item.user_revealed_answer_id}
              className="[&_td]:py-4 border-b border-b-[#EFF1FF]"
            >
              <td>
                <div className="flex gap-1.5 text-[#202020] text-sm pl-5">
                  <div className="max-w-[430px]">
                    {item.question_text}
                    {item.answer_text && <b>{item.answer_text}</b>}
                  </div>

                  {item.answer_audio_url ? (
                    <AudioView url={item.answer_audio_url} />
                  ) : null}
                </div>
              </td>
              <td className="align-baseline w-[200px] text-[#202020] text-sm">
                {moment
                  .utc(item.sent_on)
                  .local()
                  .format("MMM DD,YYYY | hh:mm A")}
              </td>
              <td className="align-baseline w-[175px]">
                {(
                  type === "sent"
                    ? item?.sent_list?.length > 0 || socialTotal > 0
                    : !!item.received_from || socialTotal > 0
                ) ? (
                  <div className="flex items-center gap-1">
                    <span className="text-sm text-[#202020] flex items-center">
                      {type === "sent"
                        ? `${
                            item?.sent_list?.length > 0
                              ? `${item?.sent_list?.length} contact${
                                  item?.sent_list?.length > 1 ? "s" : ""
                                }`
                              : ""
                          }${
                            socialTotal > 0
                              ? `${
                                  item?.sent_list?.length > 0 ? " | " : ""
                                }${socialTotal} social${
                                  socialTotal > 1 ? "s" : ""
                                }`
                              : ""
                          }`
                        : "1 contact"}
                    </span>
                    <Tippy
                      className="my-tooltip"
                      arrow={true}
                      interactive={true}
                      content={
                        <div className="flex py-4 px-[14px] bg-white rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
                          {type === "sent" ? (
                            <Contacts
                              list={item.sent_list}
                              id={`contacts_${item.user_revealed_answer_id}`}
                              isInstagramShared={isInstagramShared}
                              isSnapshotShared={isSnapshotShared}
                              isImessageShared={isImessageShared}
                              socialTotal={socialTotal}
                            />
                          ) : (
                            <Contacts
                              list={[item.received_from]}
                              id={`contacts_${item.user_revealed_answer_id}`}
                              isInstagramShared={isInstagramShared}
                              isSnapshotShared={isSnapshotShared}
                              isImessageShared={isImessageShared}
                              socialTotal={socialTotal}
                            />
                          )}
                        </div>
                      }
                    >
                      <span className="cursor-pointer mt-[2px]">
                        <div className="w-4 h-4 rounded-full bg-[#03E7A3] flex justify-center items-center">
                          <Subtract className="[&_path]:fill-black" />
                        </div>
                      </span>
                    </Tippy>
                  </div>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          );
        })}
        {isLoading && <LoadingSkeleton />}
        <div ref={observerTarget}></div>
        <div className="h-5"></div>
      </tbody>
    </table>
  );
}
