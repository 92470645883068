import { Loading } from "../Loading/Loading";

type Props = {
  variant?: "primary" | "outline" | "link" | "icon";
  loading?: boolean;
  scaleAnimated?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
export function Button({
  variant = "primary",
  className,
  loading,
  scaleAnimated = false,
  ...props
}: Props) {
  return (
    <button
      className={`relative transition-all duration-200 ${
        scaleAnimated ? "transition duration-75 hover:scale-105" : ""
      } rounded-md text-sm text-black font-medium leading-normal py-3 px-5 flex gap-1.5 items-center justify-center
      ${
        variant === "outline"
          ? "bg-[linear-gradient(125deg,#00FFB3_-2.31%,#F0FF44_97.12%)] !p-[1px]"
          : ""
      }
      ${
        variant === "primary"
          ? "bg-[linear-gradient(104deg,#9DF7D0_0.9%,#B6F998_3.11%,#D5FA6B_15.46%,#F6FFDA_23.84%,#DDF688_33.99%,#90FAB7_52.2%,#FAFCFE_63.79%,#8CFAC1_74.22%,#76FAFA_89.14%)] hover:opacity-80 border border-[#383743]"
          : ""
      }
      ${variant === "link" ? "bg-transparent" : ""}
      ${
        props.disabled
          ? variant === "primary"
            ? "!bg-none !bg-[#8B93AE5C] text-[#FFFFFF80] cursor-not-allowed"
            : "cursor-not-allowed opacity-50"
          : ""
      }
      ${loading ? "opacity-70 cursor-not-allowed" : ""}
      ${className || ""}
    `}
      {...props}
    >
      {variant === "outline" ? (
        <div className="outline-variant bg-white hover:bg-[#EAFEEF] rounded-md w-full py-3 px-5 flex gap-1.5 items-center justify-center">
          {props.children}
          {loading && <Loading />}
        </div>
      ) : (
        <>
          {props.children}
          {loading && <Loading />}
        </>
      )}
    </button>
  );
}
