import { HighlightCard } from "./components/HighlightCard";
import { ReactComponent as Crown } from "../../assets/icons/crown.svg";
import { ReactComponent as RIcon } from "../../assets/icons/R.svg";
import { ReactComponent as RevealsIcon } from "../../assets/icons/reveals-icon.svg";
import { ReactComponent as People } from "../../assets/icons/profile-2user.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/chart-arrow-down.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/Chevrons.svg";

import ChevronDown from "../../assets/icons/chevron-down.svg";

import {
  CartesianGrid,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  XAxis,
  YAxis,
  Area,
} from "recharts";

import { Link } from "react-router-dom";
import { useState } from "react";
import { api } from "../../services/api";
import { useQuery } from "@tanstack/react-query";
import moment, { unitOfTime } from "moment";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { formatNumber } from "../../services/utils";

function CustomizedLabel({ x, y, payload, ...rest }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={6} y={0} dy={16} textAnchor="end" fill="#666">
        {rest.type === "this_week"
          ? moment(payload.value).format("dd").split("")[0]
          : rest.type === "this_month"
          ? moment(payload.value).format("DD MMM")
          : payload.value}
      </text>
    </g>
  );
}

const colorMap = {
  "Below 20": "#6201AE",
  "20-25": "#9B63E3",
  "25-30": "#EBD1FF",
  "30-35": "#00FFB3",
  "35-40": "#00B47E",
  "40 above": "#F0FF44",
};

const titleMap = {
  "Below 20": "Below 20yr",
  "20-25": "20yr - 25yr",
  "25-30": "25yr - 30yr",
  "30-35": "30yr - 35yr",
  "35-40": "35yr - 40yr",
  "40 above": "40yr above",
};

const renderUsersTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="px-5 py-4 bg-white rounded-xl shadow-[0px_16px_40px_0px_rgba(96,0,0,0.11)] flex-col justify-center items-center gap-1 inline-flex">
          <div className="text-black text-base font-normal font-['Helvetica Neue'] leading-tight">
            {payload[0]?.payload?.name}
          </div>
          <div className="text-black text-xl font-bold font-['Helvetica Neue'] leading-normal">
            {formatNumber(Number(payload[0]?.value))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const renderTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="px-5 py-4 bg-white rounded-xl shadow-[0px_16px_40px_0px_rgba(96,0,0,0.11)] flex-col justify-center items-center gap-1 inline-flex">
          <div className="text-black text-base font-normal font-['Helvetica Neue'] leading-tight">
            {titleMap[payload[0]?.name]}
          </div>
          <div className="text-black text-xl font-bold font-['Helvetica Neue'] leading-normal">
            {formatNumber(Number(payload[0]?.value))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

function DashboardSkeleton() {
  return (
    <div>
      <div className="flex gap-5 items-center flex-wrap flex-1">
        <div className="flex-1 w-[350px] h-[112px] rounded-[16px]">
          <div
            className={`relative5 gap-12 items-center px-6 py-5 bg-white rounded-[16px] flex-1 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] flex`}
          >
            <div className="flex gap-5">
              <div className="flex items-center gap-3">
                <div
                  className={`w-16 h-16 rounded-full relative flex items-center justify-center bg-gray-300 animate-pulse transition duration-50`}
                ></div>
              </div>
              <div className="flex flex-col gap-2 justify-center">
                <div className="w-32 h-4 text-black text-base font-medium bg-gray-300 animate-pulse transition duration-50"></div>
                <div className="w-16 h-5 text-black text-[32px] font-bold bg-gray-300 animate-pulse transition duration-50"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 w-[350px] h-[112px] rounded-[16px]">
          <div
            className={`relative5 gap-12 items-center px-6 py-5 bg-white rounded-[16px] flex-1 shadow-[0px_16px_40px_0px_rgba(96,0,0,0.11)] flex`}
          >
            <div className="flex gap-5">
              <div className="flex items-center gap-3">
                <div
                  className={`w-16 h-16 rounded-full relative flex items-center justify-center bg-gray-300 animate-pulse transition duration-50`}
                ></div>
              </div>
              <div className="flex flex-col gap-2 justify-center">
                <div className="w-32 h-4 text-black text-base font-medium bg-gray-300 animate-pulse transition duration-50"></div>
                <div className="w-16 h-5 text-black text-[32px] font-bold bg-gray-300 animate-pulse transition duration-50"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 w-[350px] h-[112px] rounded-[16px]">
          <div
            className={`relative5 gap-12 items-center px-6 py-5 bg-white rounded-[16px] flex-1 shadow-[0px_16px_40px_0px_rgba(96,0,0,0.11)] flex`}
          >
            <div className="flex gap-5">
              <div className="flex items-center gap-3">
                <div
                  className={`w-16 h-16 rounded-full relative flex items-center justify-center bg-gray-300 animate-pulse transition duration-50`}
                ></div>
              </div>
              <div className="flex flex-col gap-2 justify-center">
                <div className="w-32 h-4 text-black text-base font-medium bg-gray-300 animate-pulse transition duration-50"></div>
                <div className="w-16 h-5 text-black text-[32px] font-bold bg-gray-300 animate-pulse transition duration-50"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 flex  gap-5">
        <div className="flex flex-col flex-1 p-6 bg-white rounded-[16px] h-[450px]">
          <div className="flex justify-between items-center mb-4">
            <div className="w-[80px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
            <div className="w-[125px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
          </div>
          <div className="animate-pulse transition duration-50 h-16 rounded-[16px] mb-4 bg-gray-300"></div>
          <div className="flex-1 animate-pulse transition duration-50 h-[402px] bg-gray-300"></div>
        </div>
        <div className="flex flex-col flex-1 p-6 bg-white rounded-[16px] h-[450px]">
          <div className="flex justify-between items-center mb-4">
            <div className="w-[80px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
            <div className="w-[125px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
          </div>
          <div className="animate-pulse transition duration-50 h-16 rounded-[16px] mb-4 bg-gray-300"></div>
          <div className="flex-1 animate-pulse transition duration-50 h-[402px] bg-gray-300"></div>
        </div>
      </div>
    </div>
  );
}

export function Dashboard() {
  const [selectedChart, setSelectedChart] = useState<string>("this_month");
  const { isLoading, data } = useQuery({
    queryKey: ["dashboard"],
    queryFn: api.getDashboard,
  });
  const { isLoading: chartLoading, data: chartData } = useQuery({
    queryKey: ["dashboard-charts", selectedChart],
    queryFn: () => api.getDashboardCharts({ type: selectedChart }),
  });
  const dashboardData = data?.data;

  const chartInfo = dashboardData?.age_chart
    ? Object.keys(dashboardData?.age_chart[0] || {}).map((key) => ({
        name: key,
        value: dashboardData.age_chart[0][key],
        fill: colorMap[key],
      }))
    : [];
  return (
    <LoadAnimatedContainer>
      <div className="bg-background h-[100vh] overflow-auto p-7 pt-6">
        <div className="mb-4">
          <h1 className="text-black text-[32px] font-bold">Dashboard</h1>
        </div>
        <>
          {isLoading ? (
            <DashboardSkeleton />
          ) : (
            <>
              <div className="flex gap-5 items-center flex-wrap flex-1">
                <Link to="/users" className="flex-1">
                  <HighlightCard
                    icon={<People />}
                    label={
                      <div className="flex items-center">
                        <span>Total Users</span>
                        <ChevronRight className="mt-1" />
                      </div>
                    }
                    value={formatNumber(Number(dashboardData?.total_users))}
                    iconClassName="bg-[linear-gradient(125deg,#00FFB3_-2.31%,#F0FF44_97.12%)]"
                    className="cursor-pointer hover:shadow-[rgba(0,71,28,0.12)_0px_7px_16px]"
                  />
                </Link>
                <HighlightCard
                  iconView={<RevealsIcon />}
                  label="Total Reveals"
                  value={formatNumber(Number(dashboardData?.total_posts))}
                  iconClassName="bg-[linear-gradient(125deg,#00FFB3_-2.31%,#F0FF44_97.12%)]"
                />
                <Link to="/users?plusUser=true" className="flex-1">
                  <HighlightCard
                    icon={<Crown className="w-7 h-7 [&_path]:fill-white" />}
                    label={
                      <div className="flex items-center">
                        <span>Total Plus Users</span>
                        <ChevronRight className="mt-1" />
                      </div>
                    }
                    value={formatNumber(Number(dashboardData?.plus_users))}
                    iconClassName="bg-[linear-gradient(90deg,#8B79FC_13.13%,#D929F9_83.84%)]"
                    className="cursor-pointer hover:hover:shadow-[rgba(0,71,28,0.12)_0px_7px_16px]"
                  />
                </Link>
              </div>
              <div className="mt-5 flex gap-5">
                <div className="bg-white p-5 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] rounded-[16px] flex-1">
                  <div className="flex justify-between items-center">
                    <h3 className=" text-xl font-bold">Users</h3>
                    <select
                      onChange={(e) => setSelectedChart(e.target.value)}
                      value={selectedChart}
                      className="bg-[#EEF1EF] rounded-full pl-4 pr-8 py-2 outline-none appearance-none cursor-pointer hover:bg-[#DBE3DC]"
                      style={{
                        backgroundImage: `url(${ChevronDown})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "90% 55%",
                      }}
                    >
                      <option value="this_week">Past Week</option>
                      <option value="this_month">Past Month</option>
                      <option value="this_year">Past Year</option>
                    </select>
                  </div>
                  <div>
                    <div className="w-full mt-6 p-4 rounded-lg border border-gray-200 justify-between items-center inline-flex">
                      <div className="text-black text-base font-normal font-['Helvetica Neue'] leading-tight">
                        {moment()
                          .subtract(1, selectedChart.split("_")[1] as any)
                          .format("MMM D")}{" "}
                        - {moment().format("MMM D")}
                      </div>
                      <div className="py-px justify-end items-center gap-1 flex">
                        <div className="text-black text-lg font-bold font-['Helvetica Neue'] leading-tight">
                          {chartData?.data
                            ? formatNumber(
                                Number(
                                  chartData?.data?.reduce(
                                    (acc, item) => acc + Number(item.count),
                                    0
                                  )
                                )
                              )
                            : ""}
                        </div>
                        {chartData?.data && (
                          <div className="p-1 bg-[#EEF1EF] rounded-3xl justify-end items-center gap-1.5 flex">
                            <ArrowDown className="w-3 h-3" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {chartData?.data && (
                    <div className="h-[402px] mt-12">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={chartData?.data?.map((item, i) => ({
                            name: item.date,
                            count: item.count,
                          }))}
                          // margin={{
                          //   top: 10,
                          //   right: 30,
                          //   left: 0,
                          //   bottom: 0,
                          // }}
                          margin={{
                            top: 0,
                            right: 0,
                            left: 10,
                            bottom: 15,
                          }}
                        >
                          <defs>
                            <linearGradient
                              id="paint0_linear_53_758"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#00FFB3" />
                              <stop
                                offset="1"
                                stop-color="#F0FF44"
                                stop-opacity="0"
                              />
                            </linearGradient>
                          </defs>
                          <CartesianGrid vertical={false} />
                          <XAxis
                            dataKey="name"
                            tick={(props) => (
                              <CustomizedLabel
                                {...props}
                                type={selectedChart}
                              />
                            )}
                          />
                          <YAxis />
                          <Tooltip content={renderUsersTooltip} />
                          <Area
                            type="monotone"
                            dataKey="count"
                            stroke="#00FFB3"
                            strokeWidth="3px"
                            fill="url(#paint0_linear_53_758)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                </div>
                <div className="bg-white p-5 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] rounded-[16px] flex-1">
                  <div className="text-black text-xl font-bold font-['Helvetica Neue'] leading-snug mb-12">
                    Users by age
                  </div>
                  <div className="flex flex-col gap-2 items-center">
                    <div>
                      <div className="gap-3 flex">
                        <div className="pl-2.5 pr-3.5 py-2 rounded-3xl border border-purple-100  items-center gap-1.5 flex">
                          <div className="w-6 h-4 bg-purple-800 rounded-lg" />
                          <div className="text-neutral-700 text-base font-normal font-['Helvetica Neue'] leading-tight">
                            Below 20
                          </div>
                        </div>
                        <div className="pl-2.5 pr-3.5 py-2 rounded-3xl border border-purple-100  items-center gap-1.5 flex">
                          <div className="w-6 h-4 bg-violet-500 rounded-lg" />
                          <div className="text-neutral-700 text-base font-normal font-['Helvetica Neue'] leading-tight">
                            20-25
                          </div>
                        </div>
                        <div className="pl-2.5 pr-3.5 py-2 rounded-3xl border border-purple-100  items-center gap-1.5 flex">
                          <div className="w-6 h-4 bg-purple-200 rounded-lg" />
                          <div className="text-neutral-700 text-base font-normal font-['Helvetica Neue'] leading-tight">
                            25-30
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gap-3 flex">
                      <div className="pl-2.5 pr-3.5 py-2 rounded-3xl border border-purple-100 justify-start items-center gap-1.5 flex">
                        <div className="w-6 h-4 bg-teal-400 rounded-lg" />
                        <div className="text-neutral-700 text-base font-normal font-['Helvetica Neue'] leading-tight">
                          30-35
                        </div>
                      </div>
                      <div className="pl-2.5 pr-3.5 py-2 rounded-3xl border border-purple-100 justify-start items-center gap-1.5 flex">
                        <div className="w-6 h-4 bg-emerald-500 rounded-lg" />
                        <div className="text-neutral-700 text-base font-normal font-['Helvetica Neue'] leading-tight">
                          35-40
                        </div>
                      </div>
                      <div className="pl-2.5 pr-3.5 py-2 rounded-3xl border border-purple-100 justify-start items-center gap-1.5 flex">
                        <div className="w-6 h-4 bg-yellow-300 rounded-lg" />
                        <div className="text-neutral-700 text-base font-normal font-['Helvetica Neue'] leading-tight">
                          40 above
                        </div>
                      </div>
                    </div>
                    {dashboardData?.age_chart && (
                      <PieChart width={362} height={362}>
                        <Pie
                          data={Object.keys(
                            dashboardData?.age_chart[0] || {}
                          ).map((key) => ({
                            name: key,
                            value: Number(dashboardData.age_chart[0][key] || 0),
                            fill: colorMap[key],
                            label: key,
                          }))}
                          // data={data1}
                          dataKey="value"
                          nameKey="name"
                          fill="#82ca9d"
                        />
                        <Tooltip content={renderTooltip} />
                      </PieChart>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </LoadAnimatedContainer>
  );
}
