import { Button } from "../../components/Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/add-circle.svg";
import React from "react";
import { PlusPacks } from "../plus-packs/PlusPacks";
import { Questions } from "./Questions";

const tabs = [
  {
    name: "questions",
    label: "Questions",
  },
  {
    name: "plusPacks",
    label: "Categories",
  },
];

export function QuestionsAndCategories() {
  const [activeTab, setActiveTab] = React.useState<string>("questions");
  const [selectedCategory, setSelectedCategory] = React.useState<any>();
  const [showPlusPackForm, setShowPlusPackForm] =
    React.useState<boolean>(false);
  const [showQuestionForm, setShowQuestionForm] =
    React.useState<boolean>(false);

  const handlePlusPackForm = () => {
    setShowPlusPackForm(true);
  };
  const handleQuestionForm = () => {
    setShowQuestionForm(true);
  };
  const handlePlusPackFormClose = () => {
    setShowPlusPackForm(false);
  };
  const handleQuestionFormClose = () => {
    setShowQuestionForm(false);
  };
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setActiveTab("questions");
  };

  return (
    <div className="bg-background h-[100vh] p-7 pt-6">
      <div className="mb-4 flex justify-between items-center">
        <div className="flex items-center gap-6">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={`${
                activeTab === tab.name
                  ? "border-[#18DEA3] opacity-100"
                  : "border-transparent opacity-40 hover:opacity-60"
              } text-[28px] font-bold text-black border-b-[2px] transition duration-500 ease-in-out focus:outline-none`}
            >
              {tab.label}
            </button>
          ))}
        </div>
        {activeTab === "questions" ? (
          <Button
            className="!rounded-full mb-[6px]"
            onClick={handleQuestionForm}
          >
            <CloseIcon />
            Add questions
          </Button>
        ) : (
          <Button
            className="!rounded-full mb-[6px]"
            onClick={handlePlusPackForm}
          >
            <CloseIcon />
            Add a category
          </Button>
        )}
      </div>
      {activeTab === "plusPacks" && (
        <PlusPacks
          showPlusPackForm={showPlusPackForm}
          onPlusPackFormClose={handlePlusPackFormClose}
          onCategoryClick={handleCategoryClick}
        />
      )}
      {activeTab === "questions" && (
        <Questions
          showQuestionForm={showQuestionForm}
          onQuestionFormClose={handleQuestionFormClose}
          category={selectedCategory}
          clearCategory={() => setSelectedCategory(null)}
        />
      )}
    </div>
  );
}
