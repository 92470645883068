import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { ReactComponent as Ellipse } from "../../../assets/icons/ellipse.svg";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="h-[100vh] flex flex-col bg-[#26282F]">
      <div className="absolute top-8 left-28">
        <div className="justify-center items-center flex relative">
          <Logo className="w-32 h-10" />
          <Ellipse className="absolute -left-[18px] right-0 h-[70px] w-[165px]" />
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">{children}</div>
    </div>
  );
}
