import { Button } from "../../../components/Button";
import { Loading } from "../../../components/Loading/Loading";
import { Modal } from "../../../components/Modals/Modal";

export function DeleteQuestionConfirm({
  open,
  onClose,
  onConfirm,
  loading,
  question,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
  question: string;
}) {
  return (
    <Modal
      title={"Are you sure you want to delete this question?"}
      open={open}
      onClose={onClose}
      showCloseButton={false}
      contentClassName="[&_h1]:text-xl [&_.close]:w-5 [&_.close]:h-5 !max-w-[400px] !p-7"
    >
      <div className="rounded p-4 rounded-xl bg-[#F2F2F2] flex flex-col gap-2.5 mb-4">
        <span className="text-sm font-bold text-[#33363F]">Question:</span>
        <span className="text-sm text-[#33363F]">{question}</span>
      </div>
      <div className="flex gap-3">
        <Button
          variant="outline"
          className="!text-base !font-bold !rounded-full [&_.outline-variant]:!rounded-full flex-1"
          onClick={onClose}
        >
          Go Back
        </Button>

        <Button
          onClick={onConfirm}
          disabled={loading}
          className="!text-base !font-bold !rounded-full [&_.outline-variant]:!rounded-full flex-1"
        >
          Yes
          {loading && <Loading />}
        </Button>
      </div>
    </Modal>
  );
}
