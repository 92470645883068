import { useEffect, useMemo, useState } from "react";
import { Modal } from "../../../components/Modals/Modal";
import { Button } from "../../../components/Button";
import { api } from "../../../services/api";
import { ShowToast } from "../../../components/Toast";
import { DatePicker } from "../../../components/DatePicker/DatePicker";

export function BoostQuestion({ question, open, onClose, refresh }) {
  const [form, setForm] = useState<any>({
    question,
    boost_until: "",
    until_unboost_check: false,
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const payload = {
      question_id: question.question_id,
      is_boost: 1,
      boost_until: form.boost_until || "",
    };
    try {
      setLoading(true);
      const response = await api.boostCategoryOrQuestion(payload);
      setLoading(false);
      if (response.status === 200) {
        onClose();
        refresh();
        ShowToast({
          type: "success",
          message: "Question boosted!",
        });
      } else {
        ShowToast({
          type: "error",
          message: "Failed to boost question",
        });
      }
    } catch (error) {
      setLoading(false);
      ShowToast({
        type: "error",
        message: "Failed to boost question",
      });
    }
  };

  useEffect(() => {
    if (open && question) {
      setForm({
        question_id: question.question_id,
        boost_until: question.boost_until,
        until_unboost_check: question.is_boosted && !question.boost_until,
      });
    }
  }, [open, question]);

  const disabled = () => {
    if (!!form.until_unboost_check || !!form.boost_until) {
      const isChecked = question.is_boosted && !question.boost_until;
      if (
        question.boost_until === form.boost_until &&
        isChecked === form.until_unboost_check
      ) {
        return true;
      }
      return false;
    }
    return true;
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Boost question"
      contentClassName="!max-w-[484px]"
    >
      <div className="flex flex-col gap-5 max-h-[70vh] overflow-auto">
        <div className="p-5 flex flex-col gap-1 text-base text-[#202020] bg-[#F3F3F3] rounded-md">
          <span>{question.question_text}</span>
        </div>
        <div className="flex flex-col gap-1.5">
          <label className="text-sm opacity-80">End of boost</label>
          <DatePicker
            value={form.boost_until}
            minDate={new Date()}
            onChange={(date) => setForm({ ...form, boost_until: date })}
            disabled={form.until_unboost_check}
            className="category-question-date-picker"
          />
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="isChecked"
            id="isChecked"
            checked={form.until_unboost_check}
            onChange={(e) =>
              setForm({
                ...form,
                until_unboost_check: e.target.checked,
                boost_until: "",
              })
            }
            className="w-[22px] h-[22px] accent-[#00C88C] cursor-pointer"
          />
          <label htmlFor="isChecked" className="ml-2 cursor-pointer">
            Until I unboost it
          </label>
        </div>
        <div className="flex justify-end">
          <Button
            className="!rounded-full !font-bold !py-4 !px-10"
            variant="primary"
            disabled={disabled() || loading}
            loading={loading}
            onClick={handleSubmit}
          >
            Boost
          </Button>
        </div>
      </div>
    </Modal>
  );
}
