import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-2.svg";
import { Button } from "../../../components/Button";
import React from "react";
import { TextInput } from "../../../components/TextInput";
import { DeleteQuestionConfirm } from "./DeleteQuestionConfirm";
import { api } from "../../../services/api";
import { Loading } from "../../../components/Loading/Loading";
import toast from "react-hot-toast";
import { ShowToast } from "../../../components/Toast";

export function QuestionView({
  question,
  serialNumber,
  refresh,
}: {
  question: any;
  serialNumber: number;
  refresh: (refetchId?: string, isDeleted?: boolean) => void;
}) {
  const [deleting, setDeleting] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [questionForm, setQuestionForm] = React.useState<any>(null);
  const [deleteQuestionConfirm, setDeleteQuestionConfirm] =
    React.useState(false);

  const handleChange = (e: any) => {
    setQuestionForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEdit = () => {
    setQuestionForm(question);
  };

  const handleCancel = () => {
    setQuestionForm(null);
  };

  const handleDelete = () => {
    setDeleteQuestionConfirm(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setDeleting(true);
      const response = await api.deleteQuestion(question.question_id);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refresh(question.question_id, true);
          setDeleting(false);
          setDeleteQuestionConfirm(false);
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleSave = async () => {
    if (!questionForm.question_text) return;
    let payload: any = {
      question_id: questionForm.question_id,
      question_text: questionForm.question_text,
    };
    try {
      setSaving(true);
      // update
      const response = await api.updateQuestion(payload);
      setSaving(false);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          setQuestionForm(null);
          refresh(questionForm.question_id);
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (error) {
      setSaving(false);

      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };
  return (
    <div className="border border-[#C7D8C4] rounded-[16px] p-5 flex gap-6 items-center justify-between mb-4 h-20 flex-wrap">
      <div className="flex items-center gap-3.5 flex-1">
        <div className="bg-[#E7F9E7] min-w-[40px] h-10 rounded-full text-base text-[#38B23D] flex items-center justify-center font-bold">
          {serialNumber}
        </div>
        {questionForm ? (
          <TextInput
            value={questionForm.question_text}
            name="question_text"
            onChange={handleChange}
            className="!p-3"
          />
        ) : (
          <span>{question.question_text}</span>
        )}
      </div>
      <div className="flex gap-3 items-center">
        {!questionForm ? (
          <>
            <div className="mr-3 text-base text-[#33363F]">
              {question.answer_count}{" "}
              {Number(question.answer_count) > 1 ? "times" : "time"} used
            </div>
            <Button variant="icon" className="!p-0" onClick={handleEdit}>
              <EditIcon className="w-5 h-5" />
            </Button>
            <Button variant="icon" className="!p-0" onClick={handleDelete}>
              <TrashIcon className="w-5 h-5" />
            </Button>
          </>
        ) : (
          <>
            {saving ? (
              <Loading />
            ) : (
              <div className="flex items-center gap-3">
                <Button
                  variant="icon"
                  className="!p-0 text-base text-[#00C88C]"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="icon"
                  className="!p-0 text-base !text-[#00C88C]"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <DeleteQuestionConfirm
        open={deleteQuestionConfirm}
        onClose={() => setDeleteQuestionConfirm(false)}
        onConfirm={handleDeleteConfirm}
        question={question.question_text}
        loading={deleting}
      />
    </div>
  );
}
