import { useState } from "react";
import { ReactComponent as Lock } from "../../assets/icons/lock.svg";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import { api } from "../../services/api";

export function AuthScreen({ onAuth }: { onAuth: () => void }) {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    console.log("login");
    e.preventDefault();
    try {
      setLoading(true);
      const response = await api.reportLogin(password);
      console.log(response, response?.data?.data?.isValid);
      if (
        [200, 201].includes(response.status) &&
        response?.data?.data?.isValid
      ) {
        onAuth();
      } else {
        setError(response?.data?.message || "Invalid password");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    return false;
  };

  return (
    <div className="flex-1 flex flex-col justify-center items-center">
      <form onSubmit={handleLogin} className="flex flex-col gap-4 items-center">
        <div className="flex border-[2px]  border-black justify-center items-center rounded-full shadow-[0px_3.55px_11.82px_0px_#00000073] w-[52px] h-[52px] bg-[linear-gradient(104deg,#9DF7D0_0.9%,#B6F998_3.11%,#D5FA6B_15.46%,#F6FFDA_23.84%,#DDF688_33.99%,#90FAB7_52.2%,#FAFCFE_63.79%,#8CFAC1_74.22%,#76FAFA_89.14%)]">
          <Lock />
        </div>
        <div className="text-black text-xl font-bold">Enter password</div>
        <div>
          <TextInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="!w-[275px] text-center"
          />
          {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
        </div>
        <Button
          variant="primary"
          className={` w-full !rounded-full !text-base !font-bold ${
            !password ? "!border-0 !bg-[rgba(0,0,0,0.07)] !text-black/40" : ""
          }`}
          disabled={!password}
          loading={loading}
          type="submit"
        >
          See report
        </Button>
      </form>
    </div>
  );
}
