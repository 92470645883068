import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { useDebounce } from "../../services/useDebounce";
export const LIMIT = 10;

function useQuetions(category_id: string) {
  const [state, setState] = useState<any>({
    page: 1,
    keyword: "",
    // status: "active",
    limit: LIMIT,
    sort_by: "",
    sort_order: "",
    category_id,
    start_date: "",
    end_date: "",
    type: "all",
    timeType: "",
  });
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const debouncedFilters = useDebounce(state, 500);

  const fetchQuestions = async (
    params,
    refetchId?: string,
    isDeleted?: boolean
  ) => {
    setIsLoading(true);
    const { data, total } = await api.getQuestions(params);
    setIsLoading(false);
    // filter deleted question
    if (isDeleted && refetchId) {
      setData((prev) => prev.filter((item) => item.question_id !== refetchId));
    } else if (refetchId) {
      setData((prev) => [
        ...prev.filter((item) => item.question_id !== refetchId),
        ...data,
      ]);
    } else {
      const newIds = data.map((item) => item.question_id);
      // TODO: check if we have duplicate ids
      setData((prev) => [...prev, ...data]);
    }
    setTotal(total);
  };

  const refetch = (refetchId?: string, isDeleted?: boolean) => {
    fetchQuestions(debouncedFilters, refetchId, isDeleted);
  };

  const loadMore = () => {
    // update page only we have data
    if (data.length > 0) {
      setState((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    setData([]);
    setState((prev) => ({ ...prev, ...updates, page: 1 }));
  };

  useEffect(() => {
    fetchQuestions(debouncedFilters);
  }, [debouncedFilters]);

  useEffect(() => {
    setState((prev) => ({ ...prev, category_id }));
  }, [category_id]);

  const questions: any[] = data || [];
  return {
    questions,
    totalPages: Math.ceil(total / LIMIT),
    total: total,
    isLoading: isLoading,
    isFetching,
    filters: state,
    updateFilters: (updates) => handleFilters(updates),
    refetch,
    loadMore,
    reset: () => {
      setData([]);
      setState({
        page: 1,
        keyword: "",
        // status: "active",
        limit: LIMIT,
        sort_by: "",
        sort_order: "",
        category_id,
        start_date: "",
        end_date: "",
        type: "all",
        timeType: "",
      });
    },
  };
}

export default useQuetions;
