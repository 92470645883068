import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Layout } from "./components/Layout";
import React from "react";
import { setToken, setUser as setUserInfo } from "../../services/utils";
import { api } from "../../services/api";
import { ReactComponent as AlertIcon } from "../../assets/icons/Alert.svg";
import { Password } from "./components/Password";

export function Login() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    setLoading(true);
    try {
      const response = await api.login({
        email_id: data.email_id,
        password: data.password,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      if (response.status === 401) {
        setError("Invalid credentials");
        setLoading(false);
        return;
      }
      if (response.data.status === false) {
        setError(response?.data?.message);
        setLoading(false);
        return;
      }
      const result = response.data;
      if (result?.data?.token) {
        setToken(result?.data?.token);
        setUserInfo({ ...result?.data?.profile });
        navigate("/dashboard");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError("Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (data.email_id && data.password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  if (token && userData?.admin_id) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[438px] ">
        <div className="rounded-lg p-6 !pt-5 bg-[rgba(255,255,255,0.11)] flex flex-col mx-3">
          <div className="text-white text-2xl font-semibold">Admin Sign in</div>
          <form
            onSubmit={handleSubmit}
            className="flex-1"
            onChange={handleChange}
          >
            {error && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                <AlertIcon /> {error}
              </div>
            )}
            <div className="my-6 flex flex-col gap-5">
              <div className="flex flex-col gap-1.5">
                <span className="text-white text-xs font-medium">Email</span>
                <TextInput
                  placeholder="Your email"
                  name="email_id"
                  className="w-full !p-[14px] !bg-[rgba(255,255,255,0.11)] text-white"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-white text-xs font-medium">Password</span>
                <Password
                  placeholder="Your password"
                  name="password"
                  className="!p-[14px] !bg-[rgba(255,255,255,0.11)] text-white"
                  type="password"
                />
              </div>
              {/* <Link to="/forgot-password" className="w-fit self-end">
                <div className="text-black border-b pb-1 border-dashed border-primary font-medium text-right">
                  Forgot password?
                </div>
              </Link> */}
            </div>
            <Button
              loading={loading}
              disabled={disabled}
              className="w-full !rounded-full"
              variant="primary"
            >
              Sign in
            </Button>
          </form>
        </div>
        {/* <div className="flex text-sm text-dark2 gap-1.5 mt-4 justify-center">
          Don’t have an account?
          <Link
            to="/register"
            className="text-black border-b pb-1 border-dashed border-primary font-medium"
          >
            Sign up
          </Link>
        </div> */}
      </div>
    </Layout>
  );
}
