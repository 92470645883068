import { Sketch } from "@uiw/react-color";
import { useEffect, useRef, useState } from "react";
import { RGBAToHexA } from "../../../services/utils";

export function ColorInput({ onChange, color }) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const value = RGBAToHexA(color);
  // implement close on outside click
  useEffect(() => {
    // detect click outside
    document.addEventListener("click", (e) => {
      //@ts-expect-error
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    });
  }, []);

  return (
    <div className="relative" ref={ref}>
      <button onClick={() => setOpen(true)}>
        <div style={{ backgroundColor: color }} className="h-8 w-24"></div>
      </button>
      {open && (
        <div className="absolute z-[1]">
          <Sketch color={value} onChange={onChange} />
        </div>
      )}
    </div>
  );
}
