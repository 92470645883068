import Tippy from "@tippyjs/react";

export function UserIdWithTooltip({
  userId,
  contentClassName = "",
}: {
  userId: string;
  contentClassName?: string;
}) {
  return (
    <Tippy
      className="my-tooltip"
      arrow={true}
      interactive={true}
      hideOnClick={true}
      content={
        <div className="text-white text-[13px] p-2 bg-black/70 rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
          {userId}
        </div>
      }
    >
      <div
        className={`group relative text-[#757575] text-[13px] max-w-[140px] overflow-hidden whitespace-nowrap text-ellipsis ${contentClassName}`}
      >
        {userId}
      </div>
    </Tippy>
  );
}
