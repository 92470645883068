import { useState } from "react";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-2.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { api } from "../../../services/api";
import { Button } from "../../../components/Button";

export function SettingInput({ icon, data, name, label, onUpdate, isLoading }) {
  const [onEdit, setOnEdit] = useState<{
    value: string;
    isOpen: boolean;
  }>({ value: "", isOpen: false });
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string>("");

  const handleSave = async () => {
    if (!onEdit.value || Number(onEdit.value) <= 0) {
      setError("Please enter a valid number");
      return;
    }
    setError("");
    try {
      setSaving(true);
      const response = await api.setAdminSetting({
        ...data,
        [name]: onEdit.value,
      });
      setSaving(false);
      setOnEdit({ value: "", isOpen: false });
      onUpdate();
    } catch (e) {
      console.error(e);
      setSaving(false);
    }
  };

  const handleClose = () => {
    setOnEdit({ value: "", isOpen: false });
  };

  return (
    <div className="flex items-center gap-4">
      <div className={`text-[54px] ${onEdit.isOpen ? "pt-3" : ""}`}>{icon}</div>
      <div>
        {!onEdit.isOpen ? (
          <div className="flex items-center gap-4">
            <span className="flex items-center text-[#33363F] text-2xl font-bold">
              {isLoading ? (
                <div className="animate-pulse inline-flex h-6 w-8 bg-gray-300 mr-2" />
              ) : (
                <>{data[name]}</>
              )}{" "}
              {label}
            </span>

            <Button
              className="bg-[linear-gradient(125deg,#00FFB3_-2.31%,#F0FF44_97.12%)] !p-[10px] !rounded-full"
              onClick={() =>
                setOnEdit({ value: data[name] || "0", isOpen: true })
              }
            >
              <EditIcon className="[&>path]:fill-black w-5 h-5" />
            </Button>
          </div>
        ) : (
          <>
            <div className="flex items-center gap-4">
              <div className="flex flex-col gap-[6px]">
                <label className="text-sm opacity-80 text-black">
                  Enter number
                </label>
                <input
                  type="number"
                  value={onEdit.value}
                  onChange={(e) =>
                    setOnEdit((prev) => ({ ...prev, value: e.target.value }))
                  }
                  className="border border-[#8D8E92] rounded-[8px] p-[18px] opacity-80"
                />
              </div>
              <div className="flex items-center gap-4 mt-4">
                <Button
                  onClick={handleSave}
                  loading={saving}
                  className={`bg-[linear-gradient(125deg,#00FFB3_-2.31%,#F0FF44_97.12%)] !p-[10px] !rounded-full`}
                >
                  <CheckIcon className="[&>path]:stroke-black w-5 h-5" />
                </Button>
                <Button
                  onClick={handleClose}
                  disabled={saving}
                  className={`bg-[linear-gradient(125deg,#00FFB3_-2.31%,#F0FF44_97.12%)] !p-[10px] !rounded-full`}
                >
                  <CloseIcon className="[&>path]:stroke-black w-5 h-5" />
                </Button>
              </div>
            </div>
            {error && <span className="text-red-500">{error}</span>}
          </>
        )}
      </div>
    </div>
  );
}
