import { Button } from "../../components/Button";
import { ReactComponent as People } from "../../assets/icons/people.svg";
import React from "react";
import { api } from "../../services/api";

export function ReportList() {
  const [downloading, setDownloading] = React.useState(false);
  const handleDownload = async () => {
    try {
      setDownloading(true);
      const response = await api.getReports();
      if ([200, 201].includes(response.status)) {
        console.log("Downloaded", response);
        // Try to find out the filename from the content disposition `filename` value
        var disposition = response.headers["content-disposition"];
        var matches = /"([^"]*)"/.exec(disposition);
        var filename =
          matches != null && matches[1]
            ? matches[1]
            : `user-${new Date().toISOString()}.csv`;

        // The actual download
        var blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } else {
        console.log("Error");
      }
      setDownloading(false);
    } catch (error) {
      setDownloading(false);
      console.log(error);
    }
  };
  return (
    <div className="flex">
      <div className="p-8 w-[262px] bg-white flex flex-col items-center rounded-[12px] shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)]">
        <div className="bg-[#EFF8F1] rounded-full w-16 h-16 flex justify-center items-center">
          <People />
        </div>
        <div className="mt-2.5 mb-6 text-2xl text-black font-bold">
          All User Data
        </div>
        <Button
          onClick={handleDownload}
          loading={downloading}
          className="!rounded-full !text-base !font-bold"
        >
          Download
        </Button>
      </div>
    </div>
  );
}
