import { SideModal } from "../../../components/SideModal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { TextInput } from "../../../components/TextInput";
import { Button } from "../../../components/Button";
import { useMemo } from "react";

function NumberFilter({
  onChange,
  value,
  list,
  isCustom = false,
  name,
  label,
  listClassName = "",
  customValue,
}) {
  return (
    <div>
      <div className="text-black/80 text-sm font-normal leading-[17.92px]">
        {label}
      </div>
      <div className="mt-2 border border-[#D9D9E7]/80 p-[18px] flex flex-col gap-2.5 rounded-md">
        <div className={`grid grid-cols-2 gap-2.5 ${listClassName || ""}`}>
          {list.map((item) => (
            <label className="flex items-center gap-2 cursor-pointer">
              <input
                type="radio"
                name={name}
                value={item.value}
                className="h-[22px] w-[22px] cursor-pointer accent-[#0EDB9E]"
                onClick={() => {
                  const isChecked = value == item.value;
                  if (isChecked) {
                    onChange({
                      target: {
                        name,
                        value: null,
                      },
                    });
                  } else {
                    onChange({
                      target: {
                        name,
                        value: item.value,
                      },
                    });
                  }
                }}
                checked={value == item.value}
              />
              <span>{item.label}</span>
            </label>
          ))}
        </div>
        {isCustom && (
          <TextInput
            type="number"
            name={`${name}_custom`}
            value={customValue}
            onChange={(e) => {
              if (Number(e.target.value) >= 0) {
                onChange(e);
              }
            }}
            min={0}
          />
        )}
      </div>
    </div>
  );
}

export function UserListFilters({
  onClose,
  filter = {},
  customFilters = {},
  custom = {},
  handleChange,
  handleCustomChange,
  onApply,
  onClear,
}: {
  onClose: () => void;
  filter: any;
  customFilters: any;
  custom: any;
  handleChange: (e: any) => void;
  handleCustomChange: (key: string, value: any) => void;
  onApply: () => void;
  onClear: () => void;
}) {
  const disabled = useMemo(() => {
    let flag = false;
    // If filter is not matching with custom filters, then enable the apply button
    [
      "reveal_sent_count",
      "people_sent_to",
      "people_received_from",
      "plan_type",
      "reveal_sent_count_custom",
      "people_sent_to_custom",
      "people_received_from_custom",
    ].forEach((key) => {
      if (filter[key] !== customFilters[key]) {
        flag = true;
      }
    });
    ["date_joined_start", "date_joined_end"].forEach((key) => {
      if (filter[key] !== custom[key]) {
        flag = true;
      }
    });

    if (flag) {
      return flag;
    }
    return flag;
    // console.log("flag", flag);
    // Check if any of the custom filters are enabled
    // return (
    //   Object.values(customFilters).some((value) => value) ||
    //   Object.values(custom).every((value) => value)
    // );
  }, [customFilters, custom, filter]);
  // console.log("disabled", disabled, customFilters, custom, filter);
  return (
    <SideModal onClose={onClose}>
      <div className="h-full w-full lg:w-[450px] relative bg-white flex flex-col">
        <div className="w-full px-5 py-[18px] border-b border-neutral-200 justify-between items-center flex">
          <div className="text-neutral-800 text-xl font-bold  leading-snug">
            Filters
          </div>
          <button type="button" onClick={onClose}>
            <CloseIcon className="w-6 h-6 relative" />
          </button>
        </div>
        <div className="p-5 flex flex-col gap-[22px] overflow-auto">
          <div>
            <div className="text-black/80 text-sm font-normal leading-[17.92px]">
              Date joined
            </div>
            <div className="flex items-center gap-2 mt-2">
              <label className="flex items-center gap-2 flex-1 [&_.date-picker]:flex-1 [&_.react-datepicker-wrapper]:w-full">
                <DatePicker
                  onChange={(value) =>
                    handleCustomChange("date_joined_start", value)
                  }
                  value={custom.date_joined_start}
                  maxDate={custom.date_joined_end || undefined}
                />
              </label>
              <span className="text-[#8D8E92] text-base">to</span>
              <label className="flex items-center gap-2 flex-1 [&_.date-picker]:flex-1 [&_.react-datepicker-wrapper]:w-full">
                <DatePicker
                  onChange={(value) =>
                    handleCustomChange("date_joined_end", value)
                  }
                  value={custom.date_joined_end}
                  minDate={custom.date_joined_start || undefined}
                />
              </label>
            </div>
          </div>
          <NumberFilter
            value={customFilters.reveal_sent_count}
            onChange={handleChange}
            name="reveal_sent_count"
            label="Filter by # of reveals sent"
            customValue={customFilters.reveal_sent_count_custom}
            isCustom={["custom-min", "custom-exact"].includes(
              customFilters.reveal_sent_count
            )}
            list={[
              {
                label: "50+",
                value: 50,
              },
              {
                label: "100+",
                value: 100,
              },
              {
                label: "500+",
                value: 500,
              },
              {
                label: "1000+",
                value: 1000,
              },
              {
                label: "Custom (min)",
                value: "custom-min",
              },
              {
                label: "Custom (exact)",
                value: "custom-exact",
              },
            ]}
          />
          <NumberFilter
            value={customFilters.people_sent_to}
            onChange={handleChange}
            name="people_sent_to"
            customValue={customFilters.people_sent_to_custom}
            isCustom={["custom-min", "custom-exact"].includes(
              customFilters.people_sent_to
            )}
            label="Filter by # of people sent to"
            list={[
              {
                label: "5+",
                value: 5,
              },
              {
                label: "10+",
                value: 10,
              },
              {
                label: "20+",
                value: 20,
              },
              {
                label: "50+",
                value: 50,
              },
              {
                label: "Custom (min)",
                value: "custom-min",
              },
              {
                label: "Custom (exact)",
                value: "custom-exact",
              },
            ]}
          />
          <NumberFilter
            value={customFilters.people_received_from}
            onChange={handleChange}
            name="people_received_from"
            customValue={customFilters.people_received_from_custom}
            isCustom={["custom-min", "custom-exact"].includes(
              customFilters.people_received_from
            )}
            label="Filter by # of people received from"
            list={[
              {
                label: "5+",
                value: 5,
              },
              {
                label: "10+",
                value: 10,
              },
              {
                label: "20+",
                value: 20,
              },
              {
                label: "50+",
                value: 50,
              },
              {
                label: "Custom (min)",
                value: "custom-min",
              },
              {
                label: "Custom (exact)",
                value: "custom-exact",
              },
            ]}
          />
          <NumberFilter
            value={customFilters.plan_type}
            onChange={handleChange}
            name="plan_type"
            isCustom={false}
            customValue={false}
            label="Plan type"
            listClassName="grid-cols-4"
            list={[
              {
                label: "All",
                value: "all",
              },
              {
                label: "Free",
                value: "free",
              },
              {
                label: "Plus",
                value: "plus",
              },
              {
                label: "Promo",
                value: "promo_only",
              },
            ]}
          />
        </div>
        <div className="px-5 py-3 border-t border-neutral-200 flex justify-end">
          <Button
            variant="link"
            className="!text-[#00C88C] hover:text-[#00C88C]/80 !text-base !font-medium"
            onClick={onClear}
          >
            Clear
          </Button>

          <Button
            disabled={!disabled}
            className="!px-10 !py-3 !rounded-full"
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
      </div>
    </SideModal>
  );
}
