import { Modal } from "../../../components/Modals/Modal";
import { useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import { api } from "../../../services/api";
import { TextArea } from "../../../components/TextArea";
import { Loading } from "../../../components/Loading/Loading";
import toast from "react-hot-toast";
import { ShowToast } from "../../../components/Toast";

export function EditQuestionForm({
  open,
  onClose,
  question,
}: {
  open: boolean;
  onClose: (refetch?: boolean) => void;
  question?: any;
}) {
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({
    question_text: "",
  });

  const handleChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (!form.question_text) return;
    let payload: any = {
      question_id: question.question_id,
      question_text: form.question_text,
    };
    setSaving(true);
    // add
    try {
      const response = await api.updateQuestion(payload);
      setSaving(false);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          onClose(true);
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network error, please try again later.",
      });
    }
  };

  useEffect(() => {
    if (open && question) {
      setSaving(false);
      setForm({ ...question });
    }
  }, [open, question]);

  if (!open) return null;

  return (
    <Modal
      title="Edit question"
      open={open}
      onClose={onClose}
      contentClassName="[&_h1]:text-lg [&_.close]:w-5 [&_.close]:h-5 !max-w-[400px] !p-6"
    >
      <div className="flex flex-col gap-4 max-h-[70vh] overflow-auto">
        <div className="flex flex-col gap-1.5">
          <TextArea
            name="question_text"
            value={form.question_text}
            onChange={handleChange}
            placeholder="Enter question"
          />
        </div>
        <div className="flex justify-end">
          <Button
            className="!rounded-full !px-10 !py-4 !text-base !font-bold"
            onClick={handleSubmit}
            disabled={!form.question_text || saving}
          >
            Save
            {saving && <Loading />}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
