import React, { useEffect, useMemo } from "react";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modals/Modal";
import { api } from "../../../services/api";
import { UploadCsv } from "../../../components/Uploads/UploadCsv";
import { fileUpload } from "../../../services/file-upload";
import { Loading } from "../../../components/Loading/Loading";
import { ReactComponent as ArrowDown } from "../../../assets/icons/download-csv.svg";
import { type } from "@testing-library/user-event/dist/type";
import { ShowToast } from "../../../components/Toast";
import { downloadFile } from "../../../services/utils";
import { SelectInput } from "../../../components/SelectInput";

/** @function This will convert the csv file into an array of strings
 * @param csv - The csv file content
 */
export const csvFileToArray = (csvText) => {
  // const csvHeader = csvText.slice(0, csvText.indexOf("\n")).split(",");
  const csvRows = csvText.slice(csvText.indexOf("\n") + 1).split("\n");
  const array = csvRows
    .map((i) => {
      if (i.startsWith('"')) {
        // remove the first and last double quotes
        const values = i
          .trim()
          .substring(1, i.length - 2)
          .replace(/""/g, '"')
          .split('","');
        if (!values[0] || !values[0]?.trim()) return undefined;
        return {
          question_text: values[0],
        };
      } else {
        const values = i.split(",");
        if (!values[0] || !values[0]?.trim()) return undefined;
        return {
          question_text: values[0],
        };
      }
    })
    .filter((i) => i);
  return array;
};

export function UploadCsvDialog({
  onClose,
  open,
  categoryId,
  refetchQuestions,
  showCategory,
}: {
  onClose: (refresh?: boolean) => void;
  open: boolean;
  categoryId?: string;
  refetchQuestions: () => void;
  showCategory?: boolean;
}) {
  const [saving, setSaving] = React.useState(false);
  const [file, setFile] = React.useState<any>(null);
  const [questions, setQuestions] = React.useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>();
  const [categories, setCategories] = React.useState<any[]>([]);

  const handleSubmit = async () => {
    try {
      setSaving(true);
      // upload file to aws
      await fileUpload(file, "questions_csv");
      const response = await api.addQuestion({
        category_id: showCategory ? selectedCategory : categoryId,
        questions,
      });
      setSaving(false);
      if (response.status === 200) {
        if (response?.data?.status) {
          refetchQuestions();
          onClose(true);

          ShowToast({
            type: "success",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleUpload = (file: any) => {
    setFile(file);
    if (file) {
      const fileReader = new FileReader();
      if (file) {
        fileReader.onload = function (event: any) {
          const csvOutput = event.target.result;
          const list = csvFileToArray(csvOutput);
          setQuestions(list);
        };

        fileReader.readAsText(file);
      }
    }
  };

  const handleDownload = () => {
    // download aws csv file
    const url =
      "https://reveal-stage-data.s3.us-west-1.amazonaws.com/general/1703598069-question_bulk_sample.csv";
    downloadFile(url, "template.csv");
  };

  useEffect(() => {
    if (showCategory && open) {
      api.getCategories({ page: 1, limit: 10000 }).then((res) => {
        setCategories(res.data);
      });
    }
  }, [showCategory, open]);

  useEffect(() => {
    if (open) {
      setFile(null);
      setQuestions([]);
    }
  }, [open]);

  const disabled = useMemo(() => {
    if (showCategory && !selectedCategory) return true;
    if (!file) return true;
    if (questions.length === 0) return true;
    return false;
  }, [file, questions, selectedCategory, showCategory]);

  return (
    <Modal
      title="Upload csv file"
      open={open}
      onClose={onClose}
      contentClassName="[&_h1]:text-lg [&_.close]:w-5 [&_.close]:h-5 !max-w-[400px]"
    >
      <UploadCsv onChange={handleUpload} name={file?.name} />

      {showCategory && (
        <div className="mt-4">
          <label className="text-sm opacity-80">Category</label>
          <SelectInput
            onChange={(e) => setSelectedCategory(e.target.value)}
            data={categories.map((i) => ({
              label: i.category_name,
              value: i.question_category_id,
            }))}
          />
        </div>
      )}

      <div className="flex justify-between items-center mt-4">
        <Button variant="link" className="!px-0" onClick={handleDownload}>
          <ArrowDown />
          <span className="text-sm hover:text-[#00C88C]">Download format</span>
        </Button>
        <Button
          className="!rounded-full !px-10 !py-4 !text-base !font-bold"
          onClick={handleSubmit}
          disabled={disabled || saving}
        >
          Upload
          {saving && <Loading />}
        </Button>
      </div>
    </Modal>
  );
}
