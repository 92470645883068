import React, { useEffect, useRef } from "react";
import { ReactComponent as Play } from "../../../../assets/icons/play.svg";
import { ReactComponent as Pause } from "../../../../assets/icons/pause.svg";

export function AudioView({ url }) {
  // audio play button with play icon & audio url
  // play audio on click of play button & pause on click of pause button
  const audio = useRef<HTMLAudioElement | null>();
  const [playing, setPlaying] = React.useState(false);

  const playAudio = () => {
    audio.current?.play();
    setPlaying(true);
  };

  const pauseAudio = () => {
    audio.current?.pause();
    setPlaying(false);
  };

  useEffect(() => {
    audio.current?.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.current?.pause();
    };
  }, [audio]);

  return (
    <div>
      <audio
        src={url}
        ref={(e) => (audio.current = e)}
        className="hidden"
        preload="none"
      ></audio>
      {playing ? (
        <button onClick={pauseAudio}>
          <Pause />
        </button>
      ) : (
        <button onClick={playAudio}>
          <Play />
        </button>
      )}
    </div>
  );
}
