import Tippy from "@tippyjs/react";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";
import { ReactComponent as Promo } from "../../../assets/icons/promo.svg";

export function CrownTooltip({ className = "" }: { className?: string }) {
  return (
    <Tippy
      className="my-tooltip"
      arrow={true}
      interactive={true}
      hideOnClick={true}
      content={
        <div className="text-white text-[13px] p-2 bg-black/70 rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
          Plus user
        </div>
      }
    >
      <Crown className={`w-4 h-4 ${className}`} />
    </Tippy>
  );
}

export function PromoTooltip({ className = "" }: { className?: string }) {
  return (
    <Tippy
      className="my-tooltip"
      arrow={true}
      interactive={true}
      hideOnClick={true}
      content={
        <div className="text-white text-[13px] p-2 bg-black/70 rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
          Promo user
        </div>
      }
    >
      <Promo className={`w-4 h-4 ${className}`} />
    </Tippy>
  );
}
