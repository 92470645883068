import apiClient from "./axios";

export const api = {
  login: (data: any) => {
    return apiClient.post("/admin/auth/login", data);
  },
  getAdminDetails: () => {
    return apiClient.get("/admin/me");
  },
  getDashboard: () => {
    return apiClient.post("/admin/dashboard").then((res) => res.data);
  },
  getDashboardCharts: ({ type }) => {
    return apiClient
      .get(`/admin/dashboard-chart?type=${type}`)
      .then((res) => res.data);
  },
  getUsersV2: (data, signal?: any) => {
    const url = "/admin/users-v2";
    const payload: any = {
      page: data.page,
      limit: data.limit,
      type: data.type,
    };
    if (data.keyword) {
      payload.search = data.keyword;
    }
    if (data.sort_order && data.sort_by) {
      payload.sort_order = data.sort_order;
      payload.sort_by = data.sort_by;
    }
    // if (data.is_plus_user) {
    //   payload.is_plus_user = true;
    // }
    if (data.filter) {
      const filter: any = {};
      if (data?.filter?.date_joined_start && data?.filter?.date_joined_end) {
        filter.date_joined_start = data.filter.date_joined_start;
        filter.date_joined_end = data.filter.date_joined_end;
      }
      if (data?.filter?.reveal_sent_count) {
        if (`${data?.filter?.reveal_sent_count}`.includes("custom")) {
          filter.reveal_sent_count_type =
            data.filter.reveal_sent_count.split("-")[1];
          filter.reveal_sent_count = Number(
            data.filter.reveal_sent_count_custom
          );
        } else {
          filter.reveal_sent_count = Number(data.filter.reveal_sent_count);
        }
      }
      if (data?.filter?.people_sent_to) {
        if (`${data?.filter?.people_sent_to}`.includes("custom")) {
          filter.people_sent_to_type = data.filter.people_sent_to.split("-")[1];
          filter.people_sent_to = Number(data.filter.people_sent_to_custom);
        } else {
          filter.people_sent_to = Number(data.filter.people_sent_to);
        }
      }
      if (data?.filter?.people_received_from) {
        if (`${data?.filter?.people_received_from}`.includes("custom")) {
          filter.people_received_from_type =
            data.filter.people_received_from.split("-")[1];
          filter.people_received_from = Number(
            data.filter.people_received_from_custom
          );
        } else {
          filter.people_received_from = Number(
            data.filter.people_received_from
          );
        }
      }
      if (data?.filter?.plan_type) {
        filter.plan_type = data.filter.plan_type;
      }
      payload.filter = filter;
    }

    return apiClient.post(url, payload, { signal }).then((res) => res.data);
  },
  getUsers: (data: any) => {
    let url = `/admin/users?page=${data.page}&limit=${data.limit}&type=${data.type}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    if (data.is_plus_user) {
      url += `&is_plus_user=true`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getBlockedUsersByAdmin: (data: any) => {
    let url = `/admin/user/blocked-by-admin?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  downloadUsers: (data) => {
    const url = "/admin/users/download-csv";
    const payload: any = {
      page: data.page,
      limit: data.limit,
      user_id: data.user_id,
      type: data.type,
    };
    if (data.start_date && data.end_date) {
      payload.start_date = data.start_date;
      payload.end_date = data.end_date;
    }
    if (data.sort_by && data.sort_order) {
      payload.sort_by = data.sort_by;
      payload.sort_order = data.sort_order;
    }

    if (data.filter) {
      const filter: any = {};
      if (data?.filter?.date_joined_start && data?.filter?.date_joined_end) {
        filter.date_joined_start = data.filter.date_joined_start;
        filter.date_joined_end = data.filter.date_joined_end;
      }

      if (data?.filter?.reveal_sent_count) {
        if (`${data?.filter?.reveal_sent_count}`.includes("custom")) {
          filter.reveal_sent_count_type =
            data.filter.reveal_sent_count.split("-")[1];
          filter.reveal_sent_count = Number(
            data.filter.reveal_sent_count_custom
          );
        } else {
          filter.reveal_sent_count = Number(data.filter.reveal_sent_count);
        }
      }
      if (data?.filter?.people_sent_to) {
        if (`${data?.filter?.people_sent_to}`.includes("custom")) {
          filter.people_sent_to_type = data.filter.people_sent_to.split("-")[1];
          filter.people_sent_to = Number(data.filter.people_sent_to_custom);
        } else {
          filter.people_sent_to = Number(data.filter.people_sent_to);
        }
      }
      if (data?.filter?.people_received_from) {
        if (`${data?.filter?.people_received_from}`.includes("custom")) {
          filter.people_received_from_type =
            data.filter.people_received_from.split("-")[1];
          filter.people_received_from = Number(
            data.filter.people_received_from_custom
          );
        } else {
          filter.people_received_from = Number(
            data.filter.people_received_from
          );
        }
      }
      if (data?.filter?.plan_type) {
        filter.plan_type = data.filter.plan_type;
      }
      payload.filter = filter;
    }
    return apiClient.post(url, payload, {
      responseType: "blob",
    });
  },
  getUniqueUsers: (data: any, signal?: any) => {
    const url = "/admin/reveals/unique-sent-received-user";
    const payload: any = {
      page: data.page,
      limit: data.limit,
      user_id: data.user_id,
      type: data.type,
    };
    if (data.start_date && data.end_date) {
      payload.start_date = data.start_date;
      payload.end_date = data.end_date;
    }
    if (data.sort_by && data.sort_order) {
      payload.sort_by = data.sort_by;
      payload.sort_order = data.sort_order;
    }
    return apiClient
      .post(url, payload, {
        signal,
      })
      .then((res) => res.data);
  },
  updateUser: (data: any) => {
    ///admin/user/profile/update
    return apiClient.put("/admin/user/profile/update", data);
  },
  blockUser: (data: { user_id: string }) => {
    return apiClient.post("/admin/block-user", data);
  },

  unblockUser: (data: { user_id: string }) => {
    return apiClient.post("/admin/unblock-user", data);
  },
  deleteUser: (data: { user_id: string }) => {
    return apiClient.post("/admin/delete-user", data);
  },
  getUserInfo: (userId: string) => {
    return apiClient
      .get(`/admin/user-info?id=${userId}`)
      .then((res) => res.data);
  },
  getReveals: (params: any, signal?: any) => {
    let url = `/admin/user/reveals?page=${params.page || 1}&limit=${
      params.limit || 25
    }&id=${params.id}&type=${params.type}`;
    return apiClient
      .get(url, {
        signal,
      })
      .then((res) => res.data);
  },
  getEmptyStates: (params: any) => {
    return apiClient
      .get(
        `/admin/reveals/empty-states?page=${params.page}&limit=${params.limit}`
      )
      .then((res) => res.data);
  },
  addEmptyState: (data: any) => {
    return apiClient.post("/admin/reveals/empty-states", data);
  },
  updateEmptyState: (data: any) => {
    return apiClient.put("/admin/reveals/empty-states", data);
  },
  deleteEmptyState: (id: string) => {
    return apiClient.delete(`/admin/reveals/empty-states/${id}`);
  },
  getRevealDetails: (id: string) => {
    return apiClient.get(`/admin/reveals/${id}`).then((res) => res.data);
  },
  getCommentDetails: (commentId: string) => {
    return apiClient.get(`/admin/comment/${commentId}`).then((res) => res.data);
  },
  getCategories: (params: any, signal?: any) => {
    let url = `/admin/reveals/categories?page=${params.page}&limit=${params.limit}`;
    if (params.keyword) {
      url += `&search=${params.keyword}`;
    }
    if (params.is_boosted) {
      url += `&is_boosted=${params.is_boosted ? 1 : 0}`;
    }
    return apiClient
      .get(url, {
        signal,
      })
      .then((res) => res.data);
  },
  getQuestions: (params: any) => {
    let url = `/admin/reveals/categories/question?page=${params.page}&limit=${params.limit}&category_id=${params.category_id}`;
    if (params.keyword) {
      url += `&search=${params.keyword}`;
    }
    if (params.start_date) {
      url += `&start_date=${params.start_date}`;
    }
    if (params.end_date) {
      url += `&end_date=${params.end_date}`;
    }
    if (params.type) {
      url += `&type=${params.type}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getAllQuestions: (params: any, signal?: any) => {
    let url = `/admin/reveals/questions`;
    const payload = {
      page: params.page,
      limit: params.limit,
      categories: params.categories.map((c) => c.question_category_id),
      is_boosted: params.is_boosted ? 1 : 0,
      search: params.keyword,
      start_date: params.start_date,
      end_date: params.end_date,
      sort_by: {
        [params.sort_by]: params.sort_order,
      },
    };
    return apiClient.post(url, payload, { signal }).then((res) => res.data);
  },
  boostCategoryOrQuestion: (data: any) => {
    return apiClient.post("/admin/reveals/boost-categories-question", data);
  },
  addCategory: (data: any) => {
    return apiClient.post("/admin/reveals/categories", data);
  },
  updateCategory: (data: any) => {
    return apiClient.put("/admin/reveals/categories", data);
  },
  deleteCategory: (id: string) => {
    return apiClient.delete(`/admin/reveals/categories/${id}`);
  },
  addQuestion: (data: any) => {
    return apiClient.post("/admin/reveals/categories/question", data);
  },
  updateQuestion: (data: any) => {
    return apiClient.put("/admin/reveals/categories/question", data);
  },
  deleteQuestion: (id: string) => {
    return apiClient.delete(`/admin/reveals/categories/question/${id}`);
  },
  getAdminSettings: () => {
    return apiClient.get("/admin/settings").then((res) => res.data);
  },
  setAdminSetting: (data: any) => {
    return apiClient.put("/admin/setting", data);
  },
  getImageUpload: (data: {
    folder: string;
    file_name: string;
    content_type: string;
  }) => {
    return apiClient
      .post("/general/image/upload", data)
      .then((res) => res.data);
  },
  getImageAuth: () => {
    const url = "/general/image/upload-v2";
    return apiClient.get(url).then((res) => res.data);
  },
  reportLogin: (password: string) => {
    return apiClient.post("/admin/validate-report-screen-password", {
      password,
    });
  },
  getReports: () => {
    return apiClient.get("/admin/users/report/download-csv");

    // return apiClient.post(url, payload, {
    //   responseType: "blob",
    // });
  },
};
