import React, { useEffect } from "react";
import { RevealTable } from "./RevealTable";
import { api } from "../../../../services/api";

export function RevealTableWrapper({
  open,
  user,
}: {
  open: boolean;
  user: any;
}) {
  const [sentReveals, setSentReveals] = React.useState<any>([]);
  const [revealCounts, setRevealCounts] = React.useState<any>({});
  const [receivedReveals, setReceivedReveals] = React.useState<any>([]);
  const [revealLoading, setRevealLoading] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<"sent" | "received">(
    "sent"
  );

  const fetchSentReveals = async (userId: string) => {
    setRevealLoading(true);
    const response = await api.getReveals({ id: userId, type: "sent" });
    setRevealLoading(false);
    setRevealCounts({
      receivedCount: response?.data?.receivedCount,
      sentCount: response?.data?.sentCount,
    });
    setSentReveals(response?.data?.data || []);
  };

  const fetchReceivedReveals = async (userId: string) => {
    setRevealLoading(true);
    const response = await api.getReveals({ id: userId, type: "received" });
    setRevealLoading(false);
    setRevealCounts({
      receivedCount: response?.data?.receivedCount,
      sentCount: response?.data?.sentCount,
    });
    setReceivedReveals(response?.data?.data || []);
  };

  useEffect(() => {
    if (user && open) {
      if (selectedTab === "sent") {
        fetchSentReveals(user.user_id);
      } else {
        fetchReceivedReveals(user.user_id);
      }
    }
  }, [user, open]);

  const handleSelectedTab = (tab: "sent" | "received") => {
    setSelectedTab(tab);
    if (tab === "sent") {
      fetchSentReveals(user.user_id);
    } else {
      fetchReceivedReveals(user.user_id);
    }
  };

  if (!open) return null;

  return (
    <div className="bg-white rounded-[16px] p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-[#33363F] text-2xl font-bold">Reveals</h1>
        <div className="bg-[#EEF1EF] rounded-full p-1 flex items-center gap-1">
          <button
            onClick={() => handleSelectedTab("sent")}
            className={`${
              selectedTab === "sent"
                ? "bg-white"
                : "bg-transparent hover:bg-white/60"
            } rounded-full px-4 py-2 text-base text-[#33363F]`}
          >
            Reveals Sent ({revealCounts.sentCount})
          </button>
          <button
            onClick={() => handleSelectedTab("received")}
            className={`${
              selectedTab === "received"
                ? "bg-white"
                : "bg-transparent hover:bg-white/60"
            } rounded-full px-4 py-2 text-base text-[#33363F]`}
          >
            Reveals Received ({revealCounts.receivedCount})
          </button>
        </div>
      </div>
      <div className="max-h-[calc(100vh-425px)] overflow-auto">
        {selectedTab === "sent" ? (
          <RevealTable
            key={selectedTab}
            type={selectedTab}
            userId={user.user_id}
          />
        ) : (
          <RevealTable
            key={selectedTab}
            type={selectedTab}
            userId={user.user_id}
          />
        )}
      </div>
    </div>
  );
}
