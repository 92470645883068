import InfiniteScroll from "react-infinite-scroll-component";
import { QuestionView } from "./components/QuestionView";
import { EmptyTableView } from "../../components/EmptyTableView";
import { ReactComponent as EmptyIcon } from "../../assets/icons/empty.svg";

// display skeleton loader
// animate
function LoadingSkeleton() {
  return (
    <>
      {Array.from(Array(5).keys()).map((_, index) => (
        <div
          key={index}
          className="border border-[#C7D8C4] rounded-[16px] w-full flex items-center gap-3 justify-between p-5 mb-4"
        >
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-full bg-gray-300 animate-pulse transition duration-50"></div>
            <div className="flex flex-col">
              <div className="flex items-center gap-[2px]">
                <div className="w-48 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
              </div>
            </div>
          </div>
          <div className="flex gap-5">
            <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
            <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </div>
        </div>
      ))}
    </>
  );
}
export function QuestionListView({
  questions,
  refresh,
  fetchMoreData,
  hasMore,
  loading,
}: {
  questions: any[];
  refresh: (refetchId?: string, isDeleted?: boolean) => void;
  fetchMoreData: () => void;
  hasMore: boolean;
  loading?: boolean;
}) {
  return (
    <div
      id="scrollableDiv"
      className="flex flex-col gap-4 h-[calc(100vh-480px)] overflow-auto"
    >
      {!loading && !questions?.length ? (
        <div className="flex flex-col justify-center items-center text-base gap-4 flex-1">
          <EmptyIcon />
          No question found
        </div>
      ) : (
        <InfiniteScroll
          dataLength={questions.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div className="w-full flex flex-col">
              {/** skeleton */}
              <LoadingSkeleton />
            </div>
          }
          scrollableTarget="scrollableDiv"
          className={`${hasMore ? "pb-24" : ""}`}
        >
          {questions?.map((question: any, index: number) => (
            <QuestionView
              question={question}
              serialNumber={index + 1}
              key={question.id}
              refresh={refresh}
            />
          ))}
          {loading && <LoadingSkeleton />}
        </InfiniteScroll>
      )}
    </div>
  );
}
