import { Modal } from "../../components/Modals/Modal";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { TextInput } from "../../components/TextInput";
import { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { UploadCsv } from "../../components/Uploads/UploadCsv";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import { UploadPicture } from "../../components/Uploads/UploadPicture";
import { downloadFile, getImage } from "../../services/utils";
import { fileUpload } from "../../services/file-upload";
import { api } from "../../services/api";
import { Loading } from "../../components/Loading/Loading";
import toast from "react-hot-toast";
import { ShowToast } from "../../components/Toast";
import { ReactComponent as ArrowDown } from "../../assets/icons/download-csv.svg";
import { csvFileToArray } from "./components/UploadCsvDialog";
import { IMAGEKIT_ENDPOINT, imagekit_key } from "../../env";
import ImageKit from "imagekit-javascript";

export function PlusPackForm({
  open,
  onClose,
  pack,
}: {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  pack?: any;
}) {
  const [saving, setSaving] = useState(false);
  const [packForm, setPackForm] = useState<any>({});
  const [plusFile, setPlusFile] = useState<any>(null);
  const [csvFile, setCsvFile] = useState<any>(null);
  const [questions, setQuestions] = useState<any[]>([]);

  const handleChange = (e: any) => {
    setPackForm({
      ...packForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleScheduleCheck = (e: any) => {
    setPackForm({
      ...packForm,
      [e.target.name]: e.target.checked ? 1 : 0,
    });
  };

  const handleDateChange = (name: string, value: any) => {
    setPackForm({
      ...packForm,
      [name]: value,
    });
  };

  const authenticateImageKit = async () => {
    try {
      const authResponse = await api.getImageAuth();
      console.log(authResponse);
      return authResponse;
    } catch (e: any) {
      console.log(e);
    }
  };

  const savePack = async (payload: any) => {
    try {
      if (packForm.question_category_id) {
        // update
        payload.category_id = packForm.question_category_id;
        const response = await api.updateCategory(payload);
        setSaving(false);
        if (response.status === 200) {
          if (response.data.status) {
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            onClose(true);
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        }
      } else {
        // add
        const response = await api.addCategory(payload);
        setSaving(false);
        if (response.status === 200) {
          if (response.data.status) {
            // upload csv
            const category_id = response.data?.data;
            if (category_id && questions.length) {
              await fileUpload(csvFile, "questions_csv");
              const questionResponse = await api.addQuestion({
                category_id,
                questions,
              });
              if (!questionResponse.data.status) {
                ShowToast({
                  type: "error",
                  message:
                    questionResponse?.data?.message ||
                    "Failed to create questions",
                });
              }
            }
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            onClose(true);
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message,
            });
          }
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleSubmit = async () => {
    //first upload category image
    let payload: any = {
      category_name: packForm.category_name,
      is_scheduled: packForm.publish_on && packForm.expire_on ? 1 : 0,
      text: packForm.text,
    };
    if (packForm.isChecked && packForm.publish_on) {
      payload.publish_on = packForm.publish_on;
    }
    if (packForm.isChecked && packForm.expire_on) {
      if (packForm.publish_on > packForm.expire_on) {
        ShowToast({
          type: "error",
          message: "Publish date must be less than expire date",
        });
        return;
      } else {
        payload.expire_on = packForm.expire_on;
      }
    }
    try {
      setSaving(true);
      // upload file
      if (plusFile) {
        // Imagekit implementation
        // const imagekit = new ImageKit({
        //   publicKey: imagekit_key,
        //   urlEndpoint: IMAGEKIT_ENDPOINT,
        // });
        // const authResponse = await authenticateImageKit();
        // if (authResponse?.uploadURL) {
        //   const authData = authResponse.uploadURL;
        //   console.log(authData);
        //   imagekit.upload(
        //     {
        //       file: plusFile,
        //       fileName: plusFile.name,
        //       token: authData.token,
        //       signature: authData.signature,
        //       expire: authData.expire,
        //     },
        //     async function (err, result) {
        //       console.log(result);
        //       payload.category_image = result?.url;
        //       await savePack(payload);
        //     }
        //   );
        // }
        const imageResponse = await fileUpload(plusFile, "category");
        if (imageResponse.response.status === 200 && imageResponse?.url) {
          payload.category_image = imageResponse?.url;
        }
      } else {
        await savePack(payload);
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  // const handleSubmit = async () => {
  //   //first upload category image
  //   let payload: any = {
  //     category_name: packForm.category_name,
  //     is_scheduled: packForm.publish_on && packForm.expire_on ? 1 : 0,
  //     text: packForm.text,
  //   };
  //   if (packForm.isChecked && packForm.publish_on) {
  //     payload.publish_on = packForm.publish_on;
  //   }
  //   if (packForm.isChecked && packForm.expire_on) {
  //     if (packForm.publish_on > packForm.expire_on) {
  //       ShowToast({
  //         type: "error",
  //         message: "Publish date must be less than expire date",
  //       });
  //       return;
  //     } else {
  //       payload.expire_on = packForm.expire_on;
  //     }
  //   }
  //   try {
  //     setSaving(true);
  //     // upload file
  //     if (plusFile) {
  //       const imagekit = new ImageKit({
  //         publicKey: imagekit_key,
  //         urlEndpoint: IMAGEKIT_ENDPOINT,
  //       });
  //       const authResponse = await authenticateImageKit();
  //       if (authResponse?.uploadURL) {
  //         const authData = authResponse.uploadURL;
  //         console.log(authData);
  //         imagekit.upload(
  //           {
  //             file: plusFile,
  //             fileName: plusFile.name,
  //             token: authData.token,
  //             signature: authData.signature,
  //             expire: authData.expire,
  //           },
  //           async function (err, result) {
  //             console.log(result);
  //             payload.category_image = result?.url;
  //             await savePack(payload);
  //           }
  //         );
  //       }
  //       // const imageResponse = await fileUpload(plusFile, "category");
  //       // if (imageResponse.response.status === 200 && imageResponse?.url) {
  //       //   payload.category_image = imageResponse?.url;
  //       // }
  //     } else {
  //       await savePack(payload);
  //     }
  //   } catch (e) {
  //     ShowToast({
  //       type: "error",
  //       message: "Network request failed",
  //     });
  //   }
  // };

  const handleDownload = () => {
    // download aws csv file
    const url =
      "https://reveal-stage-data.s3.us-west-1.amazonaws.com/general/1703598069-question_bulk_sample.csv";
    downloadFile(url, "template.csv");
  };

  const handleUpload = (file: any) => {
    setCsvFile(file);
    if (file) {
      const fileReader = new FileReader();
      if (file) {
        fileReader.onload = function (event: any) {
          const csvOutput = event.target.result;
          const list = csvFileToArray(csvOutput);
          setQuestions(list);
        };

        fileReader.readAsText(file);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setSaving(false);
      setCsvFile(null);
      setPlusFile(null);
      setQuestions([]);
      if (pack) {
        setPackForm({
          ...pack,
          isChecked: !!pack.publish_on,
          is_scheduled: pack.publish_on && pack.expire_on ? 1 : 0,
        });
      } else {
        setPackForm({
          category_name: "",
          text: "",
          is_scheduled: 0,
          isChecked: 0,
          publish_on: null,
          expire_on: null,
        });
      }
    }
  }, [open, pack]);

  const isEdit = !!pack?.question_category_id;

  if (!open) return null;
  return (
    <Modal
      title={`${isEdit ? "Edit" : "Add"} Plus Pack`}
      open={open}
      onClose={onClose}
      contentClassName="[&_h1]:text-lg [&_.close]:w-5 [&_.close]:h-5 plus-pack-form"
    >
      <div className="flex flex-col gap-5 max-h-[70vh] overflow-auto">
        <UploadPicture
          onChange={(file) => setPlusFile(file)}
          image={getImage(packForm.category_image, plusFile)}
        />
        <div className="flex flex-col gap-1.5">
          <label className="text-sm opacity-80">Pack Name</label>
          <TextInput
            name="category_name"
            value={packForm.category_name}
            onChange={handleChange}
            placeholder="Enter pack name"
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <label className="text-sm opacity-80">Subtext</label>
          <TextInput
            name="text"
            value={packForm.text}
            onChange={handleChange}
            placeholder="Enter subtext"
          />
        </div>
        {!isEdit && (
          <div className="flex flex-col gap-1.5">
            <label className="text-sm opacity-80">Upload csv (optional)</label>
            <UploadCsv
              onChange={(file: any) => handleUpload(file)}
              name={csvFile?.name}
            />
            <div>
              <Button variant="link" className="!px-0" onClick={handleDownload}>
                <ArrowDown />
                <span className="text-sm hover:text-[#00C88C]">
                  Download format
                </span>
              </Button>
            </div>
          </div>
        )}
        <div className="flex items-center">
          <input
            type="checkbox"
            name="isChecked"
            id="isChecked"
            checked={!!packForm.isChecked}
            onChange={handleScheduleCheck}
            className="w-[22px] h-[22px] accent-[#00C88C] cursor-pointer"
          />
          <label htmlFor="isChecked" className="ml-2 cursor-pointer">
            Schedule
          </label>
        </div>

        {!!packForm.isChecked && (
          <div className="flex items-center gap-5">
            <div className="flex flex-col gap-1.5 flex-1">
              <label className="text-sm opacity-80">Publish on</label>
              <DatePicker
                value={packForm.publish_on}
                onChange={(value) => handleDateChange("publish_on", value)}
              />
            </div>

            <div className="flex flex-col gap-1.5 flex-1">
              <label className="text-sm opacity-80">Expire on (Optional)</label>
              <DatePicker
                value={packForm.expire_on}
                onChange={(value) => handleDateChange("expire_on", value)}
              />
            </div>
          </div>
        )}
        <div className="flex justify-end p-2">
          <Button
            className="!rounded-full !px-10 !py-4 !text-base !font-bold"
            onClick={handleSubmit}
            disabled={
              !packForm.category_name ||
              !packForm.text ||
              (!plusFile && !packForm.category_image)
            }
            loading={saving}
          >
            {isEdit ? "Save" : "Add"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
