import axios, { AxiosError, AxiosResponse } from "axios";
import { BACKEND_URL } from "../env";
const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return `Bearer ${token}`;
  }
  return null;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  if (error.response?.status === 401) {
    localStorage.removeItem("token");
    // toast.error('Session expired. Please login again.');
    if (window.location.pathname !== "/login") {
      window.location.replace("/login");
    }
  }
  return Promise.reject(error);
};

const apiClient = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

apiClient.interceptors.request.use(
  (config: any) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(onResponse, onResponseError);

export default apiClient;
