import { useEffect, useState } from "react";
import { useDebounce } from "../../services/useDebounce";
import { api } from "../../services/api";
const LIMIT = 10;

function useEmptyStates() {
  const [state, setState] = useState<any>({
    page: 1,
    keyword: "",
    // status: "active",
    limit: LIMIT,
    sort_by: "",
    sort_order: "",
  });

  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedFilters = useDebounce(state, 500);

  const fetchUsers = async (
    params,
    refetchId?: string,
    isDeleted?: boolean
  ) => {
    setIsLoading(true);
    const { data, total } = await api.getEmptyStates({
      ...params,
    });
    setIsLoading(false);
    console.log("data", data, isDeleted, refetchId);
    // filter deleted question
    if (isDeleted && refetchId) {
      setData((prev) =>
        prev.filter((item) => item.empty_state_id !== refetchId)
      );
    } else if (refetchId) {
      setData((prev) => [
        ...prev.filter((item) => item.empty_state_id !== refetchId),
        ...data,
      ]);
    } else {
      if (params.page === 1) {
        setData([...data]);
      } else {
        setData((prev) => [...prev, ...data]);
      }
    }
    setTotal(total);
  };

  const refetch = (refetchId?: string, isDeleted?: boolean) => {
    fetchUsers(debouncedFilters, refetchId, isDeleted);
  };

  const loadMore = () => {
    console.log("loadMore", data);
    // update page only we have data
    if (data?.length > 0 && !isLoading) {
      setState((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    console.log("updates", updates);
    setIsLoading(true);
    setTimeout(() => {
      setData([]);
    }, 0);
    setState((prev) => ({ ...prev, ...updates, page: 1 }));
  };

  useEffect(() => {
    console.log("debouncedFilters", debouncedFilters);
    fetchUsers(debouncedFilters);
  }, [debouncedFilters]);

  const emptyStates: any[] = data || [];
  return {
    emptyStates,
    totalPages: Math.ceil(total / LIMIT),
    total,
    isLoading: isLoading,
    isFetching,
    filters: state,
    loadMore,
    refetch,
    updateFilters: (updates) => handleFilters(updates),
    reset: () => {
      setData([]);
      setState({
        page: 1,
        keyword: "",
        // status: "active",
        limit: LIMIT,
        sort_by: "",
        sort_order: "",
      });
    },
  };
}

export default useEmptyStates;
