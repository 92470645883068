import React from "react";
import { ReportedContentModal } from "./ReportedContentModal";
import moment from "moment";

export function BlockedByView({ list, onBlockedContent }: any) {
  return (
    <div className="flex flex-col w-[284px] gap-5 p-4 bg-white rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
      {list
        ?.filter((e) => e)
        .map((item, index) => (
          <div className="gap-2 flex" key={item.user_id}>
            <img
              className="w-[26px] h-[26px] rounded-full"
              src={item?.user_image}
              alt="user"
            />
            <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-[#202020] text-sm">
                  {item.first_name} {item.last_name}
                </div>
                <div className="text-[#9A9A9A] text-xs">
                  {moment(item.blocked_on).fromNow()}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
