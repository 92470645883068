import moment from "moment";

export function getDates(filter: string) {
  // "", "this_week", "this_month", "this_year", "one_day", this_three_month
  if (filter === "one_day") {
    const start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
    const end_date = moment().format("YYYY-MM-DD");
    return { start_date, end_date };
  }
  if (filter === "this_three_month") {
    const start_date = moment().subtract(90, "days").format("YYYY-MM-DD");
    const end_date = moment().format("YYYY-MM-DD");
    return { start_date, end_date };
  }
  if (filter === "this_week") {
    const start_date = moment().subtract(7, "days").format("YYYY-MM-DD");
    const end_date = moment().format("YYYY-MM-DD");
    return { start_date, end_date };
  }
  if (filter === "this_month") {
    const start_date = moment().subtract(30, "days").format("YYYY-MM-DD");
    const end_date = moment().format("YYYY-MM-DD");
    return { start_date, end_date };
  }
  if (filter === "this_year") {
    const start_date = moment().subtract(365, "days").format("YYYY-MM-DD");
    const end_date = moment().format("YYYY-MM-DD");
    return { start_date, end_date };
  }
  return { start_date: "", end_date: "" };
}
