import { Modal } from "../../components/Modals/Modal";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { TextInput } from "../../components/TextInput";
import { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { UploadCsv } from "../../components/Uploads/UploadCsv";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import { UploadPicture } from "../../components/Uploads/UploadPicture";
import { State } from "./EmptyStates";
import { api } from "../../services/api";
import { fileUpload } from "../../services/file-upload";
import { Loading } from "../../components/Loading/Loading";
import toast from "react-hot-toast";
import { ShowToast } from "../../components/Toast";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ColorInput } from "./components/ColorInput";
import { rgbaObjectToText } from "../../services/utils";

export const getImage = (image: string | undefined, file: File | null) => {
  if (file) {
    return URL.createObjectURL(file);
  }
  if (image) {
    return image;
  }
};

export function EmptyStateForm({
  open,
  onClose,
  state,
}: {
  open: boolean;
  onClose: (refetch?: boolean) => void;
  state?: any;
}) {
  const [saving, setSaving] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [stateForm, setStateForm] = useState<State>({
    reveal_to: "",
    text: "",
    shadow_color_code: "rgba(0,0,0,0.5)",
  });

  const handleChange = (e: any) => {
    setStateForm({
      ...stateForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleScheduleCheck = (e: any) => {
    setStateForm({
      ...stateForm,
      [e.target.name]: e.target.checked,
    });
  };

  const handleDateChange = (name: string, value: any) => {
    setStateForm({
      ...stateForm,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    let payload: any = {
      reveal_to: stateForm.reveal_to,
      text: stateForm.text,
      shadow_color_code: stateForm.shadow_color_code,
    };
    try {
      setSaving(true);
      // upload file
      if (file) {
        const imageResponse = await fileUpload(file, "empty_state");
        if (imageResponse.response.status === 200 && imageResponse?.url) {
          payload.image = imageResponse?.url;
        }
      }
      if (stateForm.empty_state_id) {
        // update
        payload.empty_state_id = stateForm.empty_state_id;
        const response = await api.updateEmptyState(payload);
        setSaving(false);
        if (response?.status === 200) {
          if (response?.data?.status) {
            onClose(true);
            ShowToast({ type: "success", message: response?.data?.message });
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        }
      } else {
        // add
        setSaving(true);
        const response = await api.addEmptyState(payload);
        setSaving(false);
        if (response?.status === 200) {
          if (response?.data?.status) {
            onClose(true);
            ShowToast({ type: "success", message: response?.data?.message });
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        }
      }
    } catch (e) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  useEffect(() => {
    if (open) {
      setStateForm({
        ...state,
        shadow_color_code: state?.shadow_color_code || "rgba(0,0,0,0.5)",
      });
      setFile(null);
    }
  }, [open, state]);

  const isEdit = !!state?.empty_state_id;

  if (!open) return null;

  return (
    <Modal
      title={`${isEdit ? "Edit" : "Add"} empty state`}
      open={open}
      onClose={onClose}
      contentClassName="[&_h1]:text-lg [&_.close]:w-5 [&_.close]:h-5"
    >
      <div className="flex flex-col gap-5 max-h-[70vh] overflow-auto">
        <UploadPicture
          onChange={(file) => setFile(file)}
          image={getImage(state.image, file)}
        />
        <div className="flex items-center gap-1.5">
          <label className="text-sm opacity-80">Shadow Color</label>
          <ColorInput
            onChange={(e) =>
              setStateForm((form) => ({
                ...form,
                shadow_color_code: rgbaObjectToText(e.rgba),
              }))
            }
            color={stateForm.shadow_color_code}
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <label className="text-sm opacity-80">Reveal to your</label>
          <TextInput
            name="reveal_to"
            value={stateForm.reveal_to}
            onChange={handleChange}
            placeholder="Ex. partner, best friend..."
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <label className="text-sm opacity-80">Sub-text</label>
          <TextInput
            name="text"
            value={stateForm.text}
            onChange={handleChange}
            placeholder="Enter sub-text"
          />
        </div>
        <div className="flex justify-end p-2">
          <Button
            className="!rounded-full !px-10 !py-4 !text-base !font-bold"
            onClick={handleSubmit}
            disabled={
              !stateForm.reveal_to || !stateForm.text || (!file && !state.image)
            }
            loading={saving}
          >
            {isEdit ? "Save" : "Add"}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
