import { Button } from "../../../components/Button";
import { ReactComponent as BoostedIcon } from "../../../assets/icons/boost.svg";

export function BoostViewButton({ count, onClick }) {
  return (
    <Button
      className="!bg-none !bg-transparent hover:!bg-lightGray/10 border border-[#D9D9E7]"
      onClick={onClick}
    >
      <BoostedIcon />
      Boosted ({count})
    </Button>
  );
}
