import Tippy from "@tippyjs/react";
import { ReactComponent as BoostIcon } from "../../../assets/icons/boost.svg";

export function BoostedCategoryTooltip({
  className = "",
  categoryName,
}: {
  className?: string;
  categoryName: string;
}) {
  return (
    <Tippy
      className="my-tooltip"
      arrow={true}
      interactive={true}
      hideOnClick={true}
      content={
        <div className="text-white text-[13px] p-2 bg-black/70 rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
          Whole ‘{categoryName}’ category is boosted
        </div>
      }
    >
      <BoostIcon className={`w-4 h-4 ${className}`} />
    </Tippy>
  );
}
