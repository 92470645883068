import React, { useEffect } from "react";
import { FullModal } from "../../components/Modals/FullModal";
import { Button } from "../../components/Button";
import { ReactComponent as Add } from "../../assets/icons/add-circle.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as UploadCSV } from "../../assets/icons/document-upload.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";

import { QuestionView } from "./components/QuestionView";
import { CategoryView } from "./components/CategoryView";
import { QuestionListView } from "./QuestionListView";
import useQuetions, { LIMIT } from "./useCategoryQuestions";
import { UploadCsvDialog } from "./components/UploadCsvDialog";
import { AddQuestionForm } from "./components/AddQuestionForm";
import { DeleteCategoryConfirm } from "./components/DeleteCategoryConfirm";
import { api } from "../../services/api";
import { QuestionFilter } from "./components/QuestionFilter";
import moment from "moment";
import toast from "react-hot-toast";
import { ShowToast } from "../../components/Toast";

export function PlusPackView({
  open,
  onClose,
  pack,
  refetch,
}: {
  open: boolean;
  onClose: () => void;
  pack: any;
  refetch: () => void;
}) {
  const [selectedTab, setSelectedTab] = React.useState<
    "all" | "most" | "least"
  >("most");
  const [deleting, setDeleting] = React.useState(false);
  const [packForm, setPackForm] = React.useState<any>(null);
  const [uploadCsvDialog, setUploadCsvDialog] = React.useState(false);
  const [addQuestionDialog, setAddQuestionDialog] = React.useState(false);
  const [deleteCategoryConfirm, setDeleteCategoryConfirm] =
    React.useState(false);
  const {
    total,
    questions,
    totalPages,
    filters,
    updateFilters,
    refetch: refetchQuestions,
    loadMore,
    reset,
    isLoading,
    isFetching,
  } = useQuetions(packForm?.question_category_id);

  useEffect(() => {
    if (pack && open) {
      setPackForm({
        ...pack,
      });
    }
  }, [pack, open]);

  const handlePageChange = (page: number) => {
    updateFilters({ page });
  };

  const handleClose = () => {
    onClose();
    setPackForm(null);
    reset();
  };

  const openUploadCsvDialog = () => {
    setUploadCsvDialog(true);
  };

  const openAddQuestionDialog = () => {
    setAddQuestionDialog(true);
  };

  const handleDeleteCategory = () => {
    setDeleteCategoryConfirm(true);
  };

  const handleDeleteCategoryConfirm = () => {
    setDeleting(true);
    // delete category
    api
      .deleteCategory(packForm?.question_category_id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            ShowToast({
              type: "success",
              message: response?.data?.message,
            });
            handleClose();
            refetch();
            setDeleteCategoryConfirm(false);
          } else {
            ShowToast({
              type: "error",
              message: response?.data?.message || "Something went wrong",
            });
          }
        }
      })
      .catch((e) => {
        ShowToast({
          type: "error",
          message: "Network request failed",
        });
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const handleClearFilter = () => {
    updateFilters({ start_date: "", end_date: "", timeType: "" });
  };

  if (!open) return null;
  return (
    <FullModal open={open} onClose={handleClose}>
      <div className="w-[900px] mx-auto flex flex-col">
        <div className="mb-5">
          <div className="flex justify-end mb-5">
            <Button
              variant="icon"
              className="!p-0 text-black hover:text-[#F54343]"
              onClick={handleDeleteCategory}
            >
              <TrashIcon />
              Delete Category
            </Button>
          </div>
          <CategoryView category={packForm} refetch={refetch} />
        </div>
        <div>
          <div className="flex items-center justify-between gap-4">
            <span className="text-lg font-bold text-[#33363F]">
              Questions({total})
            </span>
            <div className="flex gap-3 items-center">
              <Button
                variant="icon"
                className="!p-0 text-base text-black hover:text-[#00C88C]"
                onClick={openUploadCsvDialog}
              >
                <UploadCSV className="w-5 h-5" />
                Upload CSV
              </Button>
              <div className="border-l border-[#B0C8D5] h-[10px]" />
              <Button
                variant="icon"
                className="!p-0 text-base text-black hover:text-[#00C88C]"
                onClick={openAddQuestionDialog}
              >
                <Add className="w-5 h-5 [&_path]:fill-[#0EDB9E]" />
                Add Question
              </Button>
            </div>
          </div>
          <div className="rounded-[10px] bg-[#D8DED7] p-1 w-fit mt-5 flex items-center gap-1">
            <button
              onClick={() => updateFilters({ type: "all" })}
              className={`${
                filters.type === "all"
                  ? "bg-white"
                  : "bg-transparent hover:bg-white/60"
              } rounded-md px-4 py-2 text-base text-[#33363F]`}
            >
              All Questions
            </button>
            <button
              onClick={() => updateFilters({ type: "most_popular" })}
              className={`${
                filters.type === "most_popular"
                  ? "bg-white"
                  : "bg-transparent hover:bg-white/60"
              } rounded-md px-4 py-2 text-base text-[#33363F]`}
            >
              Most popular
            </button>
            <button
              onClick={() => updateFilters({ type: "least_popular" })}
              className={`${
                filters.type === "least_popular"
                  ? "bg-white"
                  : "bg-transparent hover:bg-white/60"
              } rounded-md px-4 py-2 text-base text-[#33363F]`}
            >
              Least popular
            </button>
          </div>
        </div>

        <div className="mt-5 bg-white rounded-[20px] p-6">
          <div className="flex items-center gap-3 mb-5">
            <div className="py-4 px-6 flex items-center gap-1.5 border border-[#C7D8C4] focus-within:border-lightGray rounded-lg">
              <SearchIcon className="w-5 h-5" />
              <input
                type="text"
                placeholder="Search"
                className="bg-transparent !outline-none"
                onChange={(e) => updateFilters({ keyword: e.target.value })}
              />
            </div>
            <QuestionFilter
              selectedFilters={{ ...filters }}
              onChange={(type, customDates) =>
                updateFilters({ timeType: type, ...customDates })
              }
            />
            {filters.timeType && (
              <div className="text-[#33363F] text-xs flex items-center gap-2 bg-[#E0E8DE] px-3 py-1.5 rounded-full">
                <span>
                  {moment.utc(filters.start_date).local().format("DD MMM YYYY")}{" "}
                  - {moment.utc(filters.end_date).local().format("DD MMM YYYY")}
                </span>
                <button onClick={handleClearFilter}>
                  <CloseIcon className="w-3 h-3 [&_path]:stroke-[#2A2B2F] [&_path]:stroke-[1.5px]" />
                </button>
              </div>
            )}
            {isLoading || isFetching ? (
              <div className="w-32 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
            ) : (
              <span>{total} questions found</span>
            )}
          </div>
          <QuestionListView
            questions={questions}
            refresh={refetchQuestions}
            fetchMoreData={loadMore}
            hasMore={filters.page < totalPages}
            loading={isLoading || isFetching}
          />
        </div>
      </div>
      <UploadCsvDialog
        open={uploadCsvDialog}
        onClose={() => {
          setUploadCsvDialog(false);
          refetch();
        }}
        refetchQuestions={refetchQuestions}
        categoryId={packForm?.question_category_id}
      />
      <AddQuestionForm
        open={addQuestionDialog}
        onClose={(refresh?: boolean) => {
          if (refresh === true) {
            console.log("came here");
            refetchQuestions();
            refetch();
          }
          setAddQuestionDialog(false);
        }}
        category_id={packForm?.question_category_id}
      />
      <DeleteCategoryConfirm
        open={deleteCategoryConfirm}
        onClose={() => {
          setDeleteCategoryConfirm(false);
        }}
        onConfirm={handleDeleteCategoryConfirm}
        title={`Are you sure you want to delete the category ‘${packForm?.category_name}’?`}
        loading={deleting}
      />
    </FullModal>
  );
}
